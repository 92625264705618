import * as React from "react";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {HTMLContent} from "./html-content/html-content";

export interface DonationMessageProps {
	config: PublicTicketAppConfig;
}

/**
 * TODO - This is the main panel for the "Donation" route. Do we even need this?
 * Personally, I think we should just render the TicketOrder component in the main panel,
 * and if there IS a donation message, render it in the side panel.
 *
 * @param {DonationMessageProps & {children?: React.ReactNode}} props
 * @returns {any}
 */
export const DonationMessage: React.SFC<DonationMessageProps> = (props) => {
	return <HTMLContent rawHtml={props.config.donationCTABody ? props.config.donationCTABody : ''}/>;
};