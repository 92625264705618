import * as _ from "lodash";
import * as moment from "moment/min/moment-with-locales";
import * as React from "react";
import {addLocaleData, IntlProvider} from "react-intl";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import momentLocalizer from "react-widgets-moment";
import {Col, Container, Row} from "reactstrap";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AnalyticsActions} from "../actions/analytics-actions";
import {ApiActions} from "../actions/api-actions";
import {CartActions} from "../actions/cart-actions";
import {AlertOptions, PublicTicketAppActions} from "../actions/public-ticket-app-actions";
import {CheckoutNavWithRoute} from "../components/checkout-nav/checkout-nav";
import {ErrorBoundary} from "../components/error-boundary";
import {Footer} from "../components/footer/footer";
import {Header} from "../components/header/header";
import {LoginFormWithIntl as LoginForm} from "../components/login-form";
import {PublicTicketAppRoutes} from "../components/public-ticket-app-routes";
import {ResetPasswordFormWithIntl as ResetPasswordForm} from "../components/reset-password-form";
import {ActionTypes} from "../enums/action-types";
import {Paths} from "../enums/paths";
import {isCheckoutRoute} from "../helpers/routing";
import {
	canCheckout,
	canFulfill,
	formatNavigationPath,
	isPendingRenewal,
	isPortalUser,
	setScrollPosition,
	showDiscountPage,
	showDonationPage
} from "../helpers/utilities";
import {InjectedInventoryServiceProps, injectInventoryService} from "../hoc/inject-inventory-service";
import localeData from "../locales/data.json";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart, Cart as CartModel} from "../models/cart";
import {CartItem} from "../models/cart-item";
import {EventDescriptorCache} from "../models/event-descriptor-cache/event-descriptor-cache";
import {LinkItem} from "../models/link-item";
import {Portal} from "../models/portal/portal";
import {PublicTicketApp as PTApp} from "../models/public-ticket-app/public-ticket-app";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {VfRemoteResponse} from "../models/vf-remote-response";
import {VfRemoteSuccessResponse} from "../models/vf-remote-success-response";
import {RootState} from "../reducers";
import ApplicationMessages from "./application-messages/application-messages";
import ModalAction from "./modal-action";
import {ExpiredPasswordConnected} from "./portal/expired-password";

interface PublicTicketAppProps extends InjectedInventoryServiceProps, PublicTicketAppStateToProps, PublicTicketAppDispatchToProps, RouteComponentProps<any>{}

interface PublicTicketAppState {
	defaultCalendarDate: string;
	drift: number;
	language: string;
	localizedMessagesFetched: boolean;
	messages: BasicStringKeyedMap<BasicStringKeyedMap<string>>;
	siteConfigured: boolean;
}

interface PublicTicketAppStateToProps {
	ptApp: PTApp;
	cart: CartModel;
	portal: Portal;
	eventDescriptorCache: EventDescriptorCache;
}

interface PublicTicketAppDispatchToProps {
	fetchGlobalConfig: (draftTheme: boolean) => Promise<any>;
	fetchLocalizedMessages: () => Promise<any>;
	ensureCart: () => Promise<any>;
	showAlert: (alertOptions: AlertOptions) => void;
	clearAllMessages: () => void;
	appMount: () => void;
	showLoginForm: () => void;
	hideLoginForm: () => void;
	showResetPasswordForm: () => void;
	hideResetPasswordForm: () => void;
	updateCartTimeRemaining: (cartExpiration?: number) => void;
}

export const ConfigContext = React.createContext<PublicTicketAppConfig>(new PublicTicketAppConfig());

export class PublicTicketApp extends React.Component<PublicTicketAppProps, PublicTicketAppState> {
	public readonly state: PublicTicketAppState = {
		defaultCalendarDate: new Date().toDateString(),
		drift: 0,
		language: "en",
		messages: {"en": {}},
		localizedMessagesFetched: false,
		siteConfigured: false,
	};
	
	private unlisten: () => void;
	private debounceScrollEvents: any;
	private scrollData: BasicStringKeyedMap<number> = {};
	
	constructor(props: PublicTicketAppProps){
		super(props);
		// The locale codes from Salesforce look like "en-US", but moment and react-intl only want the "en" part.
		const languageWithoutRegionCode = window.PublicTicketApp.locale.toLowerCase().split(/[_-]+/)[0] || "en";
		// Load the language pack for react-intl
		addLocaleData(require(`react-intl/locale-data/${languageWithoutRegionCode}`));
		// Initialize the moment localizer for custom date fields
		moment.locale(languageWithoutRegionCode);
		momentLocalizer();

		// Try full locale, try locale without region code, fallback to 'en'
		const localeMessages = localeData[window.PublicTicketApp.locale] || localeData[languageWithoutRegionCode] || localeData.en;
		// Merge in locale specific messages
		this.state.messages = Object.assign(this.state.messages, localeMessages);
		// Debounce the scroll event handler function
		this.debounceScrollEvents = _.debounce(this.storeScrollPosition, 200);
	}

	public componentDidMount() {
		this.props.fetchGlobalConfig(!!window.PublicTicketApp.draft)
			.then((result)=> {
				if (result.type === ActionTypes.API_SUCCESS) {
					this.setState({siteConfigured: true});
					const promptForLogin : boolean = this.props.history.location.search.toLowerCase().includes('promptforlogin');
					if (promptForLogin && !isPortalUser(this.props.ptApp.config.userProfile)){
						this.props.showLoginForm();
					}
				}
			});
		
		this.props.fetchLocalizedMessages()
			.then((result) => {
				if (result.type === ActionTypes.API_SUCCESS) {
					const messages = Object.assign(this.state.messages, result.data);
					this.setState({messages, localizedMessagesFetched: true});
				}
			});
		
		if (!isPendingRenewal(this.props.location.pathname)) {
			this.props.ensureCart()
				.then((response: VfRemoteResponse) => {
					if (response.type === ActionTypes.API_SUCCESS) {
						// PMGR-10131 - After successfully fetching the cart identify any fixed or CYO Subscription CartItems and fetch
						// their EventDescriptors. This is necessary so that we can identify Subscription EIs that are PYOS-enabled.
						const successResponse =  response as  VfRemoteSuccessResponse<Cart>;
						const cart: Cart = successResponse.data;
						if (canFulfill(cart)) {
							cart.cartItems.forEach(cartItem => {
								// Fetch the EventDescriptor if it isn't already in the EventDescriptorCache
								if ((CartItem.isFixedSubscription(cartItem) || CartItem.isChooseYourOwnSubscription(cartItem))
										&& !(cartItem.eiId in this.props.eventDescriptorCache.descriptors)) {
									this.props.inventoryService.fetchEventDescriptor(cartItem.eiId);
								}
							});
						}
					}
				});
		}
		
		// Register a listener that will clear messages and alerts on route changes
		this.unlisten = this.props.history.listen((location, action) => {
			this.props.clearAllMessages();
		});
		
		this.registerEventListeners();

		// Signal the app mounted to any analytics that are interested
		this.props.appMount();
	}
	
	public componentDidUpdate(prevProps: PublicTicketAppProps) {
		const {history: {action}, location: {pathname}, ptApp: {config}} = this.props;
		
		// PMGR-8686 - Manage scroll position / scroll restoration
		if (pathname !== prevProps.location.pathname) {
			if ((action === "POP") && !!this.scrollData[pathname]) {
				// Ths browser's "back" and "forward" buttons and history.back() calls will generate "POP" actions.
				// If we updated because of a path change due to a POP action and we have stored scroll data for the current path, then scroll the window.
				setScrollPosition(this.scrollData[pathname]);
			} else {
				// If the path change is NOT due to a POP action then it is due to normal navigation via history.push or history.replace.
				// In this case scroll to the top of the page.
				setScrollPosition(0);
			}
		}
		
		if (config.countdownTimerEnabled && !prevProps.ptApp.config.countdownTimerEnabled) {
			// If the countdown timer is enabled, then start a 1 second interval timer to update the time remaining
			setInterval(() => this.updateCartTimeRemaining(), 1000);
		}
	}
	
	public componentWillUnmount() {
		this.unregisterEventListeners();
	}
	
	public render() {
		const {headerImagePath, mobileHeaderImagePath, logoURL, orgName} = window.PublicTicketApp;
		const {cart, location, portal, ptApp} = this.props;
		const {appMessages, blockingActions, config} = ptApp;
		const {dbaName, physicalAddress, privacyPolicyUrl, termsAndConditions, termsAndConditionsUrl} = config;
		const workingCart: CartModel = isPendingRenewal(location.pathname) ? portal.pendingRenewal.cart : cart;
		const allowCheckout: boolean = isCheckoutRoute(location.pathname) && canCheckout(workingCart);
		const checkoutNavLinks = this.createCheckoutNavigationLinks(config,location.pathname,workingCart.id);
		
		const today = new Date();
		
		const passwordExpired = (config.userProfile) ? config.userProfile.passwordExpired : false;
		const isAppReady = this.isApplicationReady();
		
		const appBody = (
			<Container className="my-5">
				{appMessages.size > 0 && (
					<Row>
						<Col>
							<ApplicationMessages/>
						</Col>
					</Row>
				)}
				
				<PublicTicketAppRoutes />
				
				<LoginForm
					cartId={cart.cartId}
					location={location}
					loginPageMessage={ptApp.config.portalLoginPageMessage}
					isOpen={ptApp.showLoginForm}
					onClose={this.props.hideLoginForm}
					onForgotPasswordClick={this.handleForgotPasswordClick}
				/>
				<ResetPasswordForm
					isOpen={ptApp.showResetPasswordForm}
					onClose={this.props.hideResetPasswordForm}
					showAlert={this.props.showAlert}
				/>
			</Container>
		);

		return (
			<IntlProvider locale={window.PublicTicketApp.locale} messages={this.state.messages}>
				<ErrorBoundary>
					<ConfigContext.Provider value={config}>
						<div className="wrapper d-flex flex-column">
							{isAppReady && (
								<Header
									cart={cart}
									cartTimeRemaining={ptApp.cartTimeRemaining}
									config={config}
									headerImagePath={headerImagePath}
									mobileHeaderImagePath={mobileHeaderImagePath}
									logoOnly={passwordExpired}
									logoURL={logoURL}
									onClickLogin={this.props.showLoginForm}
									orgName={orgName}
								/>
							)}
							<div className="flex-fill">
								{passwordExpired ? (
									<Container className="mt-3">
										<ExpiredPasswordConnected
											blockingActions={blockingActions}
											history={this.props.history}
										/>
									</Container>
								) : (
									<>
										{allowCheckout && <CheckoutNavWithRoute links={checkoutNavLinks} currentPath={location.pathname} cart={cart}/>}
										{isAppReady ? appBody : <ApplicationMessages/>}
									</>
								)}
							</div>
							{isAppReady && (
								<Footer
									copyrightYear={today.getFullYear()}
									dbaName={dbaName}
									orgName={orgName}
									physicalAddress={physicalAddress}
									privacyPolicyUrl={privacyPolicyUrl}
									showComplianceFooter={true}
									termsAndConditions={termsAndConditions}
									termsAndConditionsUrl={termsAndConditionsUrl}
								/>
							)}
							<ModalAction />
						</div>
					</ConfigContext.Provider>
				</ErrorBoundary>
			</IntlProvider>
		);
	}
	
	private registerEventListeners = () => {
		// Register a listener that will clear messages and alerts on route changes
		// Save a reference to the function returned by history.listen so we can unregister.
		this.unlisten = this.props.history.listen((location, action) => {
			this.props.clearAllMessages();
		});
		
		window.addEventListener("scroll", this.debounceScrollEvents);
	}
	
	private unregisterEventListeners = () => {
		// Call the method that unregisters the history listener
		this.unlisten();
		
		window.removeEventListener("scroll", this.debounceScrollEvents);
	}
	
	// This function is debounced and bound to the "scroll" event
	private storeScrollPosition = (evt: React.MouseEvent) => {
		// PMGR-8686 - Save the window scroll position for the current pathname
		const {location: {pathname}} = this.props;
		this.scrollData[pathname] = window.pageYOffset;
	}
	
	
	private isApplicationReady = (): boolean  => {
		return this.state.siteConfigured && this.state.localizedMessagesFetched;
	}
	
	private updateCartTimeRemaining = () => {
		this.props.updateCartTimeRemaining(this.props.cart.cartExpiration);
	}
	
	// Forgot password link was clicked on the login form.  Close login and open reset password.
	private handleForgotPasswordClick = () => {
		this.props.hideLoginForm();
		this.props.showResetPasswordForm();
	}
	
	// Deals with displaying the proper cart navigation tabs and formatting the links.
	private createCheckoutNavigationLinks = (config: PublicTicketAppConfig, currentPath: string, toId: string): LinkItem[] => {
		
		//each path is different depending on if it's a Pending Renewal or not.
		const deliveryPath: string = isPendingRenewal(currentPath) ? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DELIVERY,toId) : Paths.CART__DELIVERY;
		const donationPath: string = isPendingRenewal(currentPath) ? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DONATION,toId) : Paths.CART__DONATION;
		const discountPath: string = isPendingRenewal(currentPath) ? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__DISCOUNT,toId) : Paths.CART__DISCOUNT;
		const contactPath: string = isPendingRenewal(currentPath) ? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__CONTACT,toId) : Paths.CART__CONTACT;
		const paymentPath: string = isPendingRenewal(currentPath) ? formatNavigationPath(Paths.PORTAL__PENDING_RENEWAL__PAYMENT,toId) : Paths.CART__PAYMENT;
		
		const navConfig: LinkItem[] = [{label: 'lbl_nav_Delivery', path: deliveryPath}];
		
		if (showDonationPage(config)){
			navConfig.push({label: 'lbl_nav_Donation', path: donationPath});
		}
		if (showDiscountPage(config)){
			navConfig.push({label: 'lbl_nav_Discount', path: discountPath});
		}
		
		navConfig.push(
			{label: 'lbl_nav_Contact', path: contactPath},
			{label: 'lbl_nav_Payment', path: paymentPath}
		);
		
		return navConfig;
	}
}

function mapStateToProps(state: RootState): PublicTicketAppStateToProps {
	return {
		ptApp: state.ptApp,
		cart: state.cart,
		portal: state.portal,
		eventDescriptorCache: state.eventDescriptorCache
	};
}

function mapDispatchToProps(dispatch: ThunkDispatch<RootState, void, AnyAction>): PublicTicketAppDispatchToProps {
	return {
		fetchGlobalConfig: (draftTheme: boolean = false): Promise<any> => {
			return dispatch(ApiActions.fetchGlobalConfig(draftTheme));
		},
		fetchLocalizedMessages: (): Promise<any> => {
			return dispatch(ApiActions.fetchLocalizedMessages());
		},
		ensureCart: (): Promise<any> => {
			return dispatch(CartActions.ensureCart(false));
		},
		showAlert: (alertOptions: AlertOptions): void => {
			dispatch(PublicTicketAppActions.showAlert(alertOptions));
		},
		clearAllMessages: (): void => {
			dispatch(PublicTicketAppActions.clearAllMessages());
		},
		appMount: (): void => {
			dispatch(AnalyticsActions.appMount());
		},
		showLoginForm: (): void => {
			dispatch(PublicTicketAppActions.showLoginForm());
		},
		hideLoginForm: (): void => {
			dispatch(PublicTicketAppActions.hideLoginForm());
		},
		showResetPasswordForm: (): void => {
			dispatch(PublicTicketAppActions.showResetPasswordForm());
		},
		hideResetPasswordForm: (): void => {
			dispatch(PublicTicketAppActions.hideResetPasswordForm());
		},
		updateCartTimeRemaining: (cartExpiration?: number, ): void => {
			dispatch(PublicTicketAppActions.updateCartTimeRemaining(cartExpiration));
		}
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectInventoryService(PublicTicketApp)));
