import * as React from "react";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {HTMLContent} from "../html-content/html-content";

interface CompleteMainProps {
	config: PublicTicketAppConfig;
}

/**
 * CompleteMain
 * This is the "main" content on the Completion screen.
 * It contains a completion message and the user's completed ticket order.
 */
export const CompleteMain: React.SFC<CompleteMainProps> = (props: CompleteMainProps) => {
	return (
		<div>
			{!!props.config.orderConfirmationCopy && <HTMLContent rawHtml={props.config.orderConfirmationCopy}/>}
		</div>
	);
};
