import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {FormattedMessage, InjectedIntl} from "react-intl";
import {RouterProps} from "react-router";
import {AnyAction} from "redux";
import {ActionTypes} from "../../enums/action-types";
import {Severities} from "../../enums/severities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {UserProfile} from "../../models/public-ticket-app/user-profile";
import {FieldGroup, FieldGroupTypes} from "../field-group";
import {SecurePaymentIcon} from "../icons/icons";
import {PanelNav} from "../panel-nav";
import {MessageTypes, WaitingMessage} from "../waiting-message";

export interface GiftCardInputFormProps extends RouterProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	fetchGiftCardBalance: (gcNumber: string, captchaToken: string) => Promise<any>;
	fetchGiftCardBalanceByPaymentMethodId: (paymentMethodId: string) => Promise<any>;
	intl: InjectedIntl;
	prevPagePath: string;
	saveProps: (props: any) => void;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	userProfile: UserProfile;
}

interface PropsFromPaymentForm {
	onGiftCardInputCancel: () => void;
	showGiftCardApply: () => void;
}

interface GiftCardInputFormState {
	errors: Partial<GiftCardInputFormValues>;
}

interface GiftCardInputFormValues {
	gcNumber?: string;
	selectedGiftCardPM?: string;
}

export class GiftCardInputForm extends React.Component<GiftCardInputFormProps & PropsFromPaymentForm, GiftCardInputFormState> {
	public readonly state: GiftCardInputFormState = {
		errors: {}
	};
	
	private recaptchaRef: any;

	public componentDidMount() {
		this.recaptchaRef = React.createRef();
	}

	public render() {
		const {blockingActions, cart, config, onGiftCardInputCancel, intl} = this.props;
		const {errors} = this.state;
		const isBusy: boolean = Object.keys(blockingActions).length > 0;
		const storedPaymentSelectOptions = this.getStoredPaymentSelectOptions();
		const hasStoredPayments = config.userGiftCardPaymentMethods && config.userGiftCardPaymentMethods.length > 0;
		
		return (
			<div style={{minHeight: '300px'}} className="d-flex flex-column">
				<div className="text-center pb-3 small">
					<SecurePaymentIcon/>
					<span className="align-middle"><FormattedMessage id="lbl_SecurePayment"/></span>
				</div>

				{
					hasStoredPayments &&
					<FieldGroup
						id="selectedGiftCardPM"
						name="selectedGiftCardPM"
						label={intl.formatMessage({id: 'lbl_SelectGiftCardOnFile'})}
						type={FieldGroupTypes.SELECT}
						value={cart.selectedGiftCardPM || ""}
						selectionOptions={storedPaymentSelectOptions}
						onChange={this.handleGiftCardSelected}
						invalid={!!errors.selectedGiftCardPM}
						feedbackMessage={errors.selectedGiftCardPM}
						required={false}
					/>
				}

				<div>
					<FieldGroup
						id="gcNumber"
						name="gcNumber"
						type={FieldGroupTypes.TEXT}
						label={intl.formatMessage({id: "lbl_GiftCardNumber"})}
						value={cart.gcNumber || ""}
						onChange={this.handleNewGiftCardNumberEntered}
						invalid={!!errors.gcNumber}
						feedbackMessage={errors.gcNumber}
						disabled={isBusy}
					/>
					<ReCAPTCHA
						ref={this.recaptchaRef}
						sitekey={config.captchaSiteKey}
						size="invisible"
						onChange={this.onCaptchaChange}
					/>
				</div>
				
				<div className="mt-auto">
					<WaitingMessage isOpen={isBusy} type={MessageTypes.CHECKING_BALANCE}/>
					<PanelNav
						next={{
							label: intl.formatMessage({id: "lbl_CheckBalance"}), 
							handleClick: this.checkBalance, 
							isDisabled: isBusy}}
						back={{
							label: intl.formatMessage({id: "lbl_button_Cancel"}), 
							handleClick: onGiftCardInputCancel, 
							isDisabled: isBusy}}
					/>
				</div>
			</div>
		);
	}

	private checkBalance = () => {
		const {cart} = this.props;

		if (cart.gcNumber) {
			this.checkCaptcha();
		}

		if (cart.selectedGiftCardPM) {
			this.fetchGiftCardBalanceForPaymentMethod(cart.selectedGiftCardPM);
		}
	}
	
	private onCaptchaChange = (value: string) => {
		const {cart} = this.props;
		if (!!cart.gcNumber && !!value) {
			this.fetchGiftCardBalance(cart.gcNumber, value);
		}
	}

	private fetchGiftCardBalance = (gcNumber: string, gcHash: string) => {
		const {fetchGiftCardBalance, showGiftCardApply} = this.props;
		fetchGiftCardBalance(gcNumber, gcHash)
		.then(result => {

			// This resets the captcha so it will fire every time someone hits the 'Check Balance'.
			// Otherwise it would only fire the first time or after it timed out.
			this.recaptchaRef.current.reset();

			if (result.type === ActionTypes.API_SUCCESS) {
				if (result.data.gcBalance != null) {
					this.props.saveProps({gcBalance: result.data.gcBalance, gcHash: result.data.gcHash});
					showGiftCardApply();
				}
			}
		});
	}

	private fetchGiftCardBalanceForPaymentMethod = (paymentMethodId: string) => {
		const {fetchGiftCardBalanceByPaymentMethodId, showGiftCardApply} = this.props;
		fetchGiftCardBalanceByPaymentMethodId(paymentMethodId)
			.then(result => {
				if (result.type === ActionTypes.API_SUCCESS) {
					if (result.data.gcBalance != null) {
						this.props.saveProps({gcBalance: result.data.gcBalance});
						showGiftCardApply();
					}
				}
			});
	}

	private checkCaptcha = () => {
		const {cart, clearAllMessages, intl} = this.props;

		// Clear errors first
		clearAllMessages();
		const errors: BasicStringKeyedMap<string> = {};

		// This shouldn't happen because we're locking the "Check Balance" button if gcNumber is empty but let's be sure
		if (!cart.gcNumber) {
			errors.gsNumber = intl.formatMessage({id: "msg_required_field"});
			this.setState({errors});
			return;
		}

		// This fires the recaptcha and then onCaptchaChange
		this.recaptchaRef.current.execute();
	}

	private handleGiftCardSelected = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.props.saveProps({gcNumber: null});
		this.handleChange(evt);
	}

	private handleNewGiftCardNumberEntered = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.props.saveProps({selectedGiftCardPM: null});
		this.handleChange(evt);
	}

	private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.props.saveProps({[evt.target.name]: evt.target.value});
	}

	/**
	 * Creates list of stored payment options available to this user
	 */
	 private getStoredPaymentSelectOptions = (): JSX.Element[] => {
		const {config, intl, userProfile} = this.props;

		let storedPaymentSelectOptions: JSX.Element[] = [];

		if (userProfile != null && config.userGiftCardPaymentMethods && config.userGiftCardPaymentMethods.length > 0)  {
			storedPaymentSelectOptions = config.userGiftCardPaymentMethods.map(paymentMethod => {

				const localizedLabel = intl.formatMessage(
					{id: 'lbl_StoredGiftCardDetails'},
					{
						lastFourDigits: paymentMethod.lastFourDigits
					});

				return <option key={paymentMethod.id} value={paymentMethod.id}>{localizedLabel}</option>;
			});

			storedPaymentSelectOptions = [
				<option key='0' value=''>
					{intl.formatMessage({id: 'lbl_Select'})}
				</option>,
				...storedPaymentSelectOptions];
		}
		return storedPaymentSelectOptions;
	}
}
