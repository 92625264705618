import * as React from "react";
import {FormattedMessage, InjectedIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {Benefit as BenefitModel} from "../../models/portal/benefit";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {MainContentHeader} from "../main-content-header";
import {Benefit} from "./benefit";

interface BenefitListProps {
	intl: InjectedIntl;
	pageView: (title: string, url: string) => void;
	renewableBenefits: BenefitModel[];
	config: PublicTicketAppConfig;
}

export class BenefitList extends React.Component<BenefitListProps> {
	public componentDidMount() {
		const {intl, pageView} = this.props;
		pageView(intl.formatMessage({id: "lbl_title_portal_renewal_benefits"}), window.location.href);
	}

	public render(){
		const {intl, renewableBenefits, config} = this.props;

		const renewalsList: JSX.Element[] = renewableBenefits.map(({benefitLevelName, daysToExpiration, id}) => (
			<div key={`alert-${id}`} className="mb-2">
				<p className="font-weight-bold">
					{daysToExpiration >= 0
						? <FormattedMessage id="msg_your_benefit_will_expire" values={{benefitLevelName, daysToExpiration}} />
						: <FormattedMessage id="msg_your_benefit_expired" values={{benefitLevelName, daysSinceExpiration: Math.abs(daysToExpiration)}} />
					}
				</p>
			</div>
		));

		const renewalNote: JSX.Element = (
			<div className="mb-5">
				{renewalsList}
				<p>
					<FormattedMessage
						id="msg_benefit_renewal_instructions"
						values={{renewNow: intl.formatMessage({id: "lbl_BenefitRenewNow"}), contactUs: intl.formatMessage({id: "lbl_BenefitContactUs"})}}
					/>
				</p>
			</div>
		);

		const benefits: JSX.Element[] = renewableBenefits.map(benefit => <Benefit key={`benefit-${benefit.id}`} benefit={benefit} config={config}/>);
		const hasBenefitsUpForRenewal = renewalsList.length > 0;
		return (
			<Row>
				<Col md="6">
					<MainContentHeader intlId="lbl_BenefitRenewal" />
					{hasBenefitsUpForRenewal && renewalNote}
					{benefits}
				</Col>
			</Row>
		);
	}
}
