import {KeyboardEvent} from "react";
import * as React from 'react';
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {AlertOptions} from "../actions/public-ticket-app-actions";
import VfRemote from "../api/vf-remote";
import ApplicationAlert from "../containers/application-messages/application-alert";
import {Severities} from "../enums/severities";
import {formatApplicationMessages} from "../helpers/utilities";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {FieldGroup, FieldGroupTypes} from "./field-group";

interface ResetPasswordFormProps {
	isOpen: boolean;
	onClose: () => void;
	showAlert: (alertOptions: AlertOptions) => void;
}

interface ResetPasswordFormState {
	errors: BasicStringKeyedMap<string>;
	formValues: BasicStringKeyedMap<string>;
	isBusy: boolean;
	modalMessage: ApplicationMessage | null;
}

export class ResetPasswordForm extends React.Component<ResetPasswordFormProps & InjectedIntlProps, ResetPasswordFormState> {
	public readonly state: ResetPasswordFormState = {
		errors: {},
		formValues: {
			userName: ''
		},
		isBusy: false,
		modalMessage: null
	};
	
	public componentDidUpdate(prevProps: ResetPasswordFormProps){
		// modal just closed.  Clear state
		if (prevProps.isOpen && !this.props.isOpen) {
			this.clearState();
		}
	}
	
	public render() {
		const {errors, isBusy, modalMessage} = this.state;
		const {intl, isOpen, onClose} = this.props;
		
		// Disable the button if isBusy or user name is blank
		const disableReset = isBusy || !this.state.formValues.userName;
		
		return (
			<Modal size="lg" isOpen={isOpen} className="text-dark" keyboard={true} toggle={onClose} autoFocus={false} aria-modal={true} aria-labelledby="ResetPWLabel">
				<ModalHeader toggle={onClose}>
					<div id="ResetPWLabel"><FormattedMessage id="lbl_ResetYourPassword" /></div>
				</ModalHeader>
				<ModalBody>
					
					{modalMessage && (<ApplicationAlert message={modalMessage} onClose={this.clearMessages} />)}
					
					<Row className="justify-content-center my-4">
						<Col lg={6}>
							<div>
								<FieldGroup
									id="userName"
									name="userName"
									type={FieldGroupTypes.TEXT}
									label={intl.formatMessage({id:'lbl_Username'})}
									value={this.state.formValues.userName || ""}
									onChange={this.handlePropertyChange}
									onKeyPress={this.onKeyPress}
									invalid={!!errors.userName}
									feedbackMessage={errors.userName}
									autoFocus={true}
								/>
							</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button id="ResetPasswordButton" color="primary" onClick={this.handleResetPassword} disabled={disableReset}>
						<FormattedMessage id="lbl_button_ResetPassword" />
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	
	private onKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
		if(evt.which === 13) {
			this.handleResetPassword();
		}
	}

	private handlePropertyChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const {formValues} = this.state;
		formValues[evt.target.name] = evt.target.value;
		this.setState({formValues});
	}
	
	private handleResetPassword = () => {
		if (this.validateForm()) {
			this.setState({isBusy:true});
			const {userName} = this.state.formValues;
			VfRemote.vfForgotPassword({userName})
				.then((result) => {
					this.setState({isBusy:false});
					// Display success message and close the modal so they can see the message
					this.props.showAlert({alertId: "msg_portal_password_reset", alertSeverity: Severities.SUCCESS});
					this.props.onClose();
				})
				.catch((errors) => {
					const modalMessages: ApplicationMessage[] = formatApplicationMessages(errors);
					//there should only be 1 message
					this.setState({modalMessage: modalMessages[0],isBusy:false});
				});
		}
	}
	
	private validateForm = () => {
		const {intl} = this.props;
		
		const errors: BasicStringKeyedMap<string> = {};
		if (!this.state.formValues.userName) {
			errors.userName = intl.formatMessage({id: "msg_required_field"});
		} else if (!this.state.formValues.userName.includes('@')){
			errors.userName = intl.formatMessage({id: "msg_username_must_be_in_the_format_of_an_email_address"});
		}
		this.setState({errors});
		
		return (Object.keys(errors).length === 0);
	}
	
	private clearState = () => {
		this.setState({
			formValues: {...this.state.formValues, userName: ''},
			errors: {},
			modalMessage: null
		});
	}
	
	private clearMessages = () => {
		this.setState({modalMessage: null});
	}
}
export const ResetPasswordFormWithIntl = injectIntl(ResetPasswordForm);
