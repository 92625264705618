import {useEffect, useState} from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {ApiActions} from "../../actions/api-actions";
import {ActionTypes} from "../../enums/action-types";
import {Paths} from "../../enums/paths";
import {useEventDescriptor} from "../../hooks";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {SubscriptionBuyerSelectionLinkDescriptor} from "../../models/subscription-buyer-selection-link-descriptor";
import {RootState} from "../../reducers";
import {FulfillmentLanding} from "./fulfillment-landing";
import {FulfillmentSeatSelection} from "./fulfillment-seat-selection";
import {SubscriptionFulfillmentGroup} from "../../models/subscription-fulfillment-group";

interface FulfillmentProps extends RouteComponentProps<any>, InjectedIntlProps {}

const Fulfillment = (props: FulfillmentProps) => {
	const {match} = props;

	const cart = useSelector((state: any): Cart => state.cart);
	const eventDescriptor = useEventDescriptor(match.params.eventInstanceId);
	const cartTimeRemaining = useSelector((state: any): number => state.ptApp.cartTimeRemaining);
	
	const [subscriptionFulfillmentGroups, setSubscriptionFulfillmentGroups] = useState<SubscriptionFulfillmentGroup[]>([]);

	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
	
	useEffect(() => {
		// Retrieve the cart items for just the currently selected subscription allocation
		const selectedCartItems = cart.cartItems.filter((ci: CartItem) => ci.allocId === match.params.allocationId);

		if(selectedCartItems.length > 0) {
			const sbslIds: string[] = [];
			selectedCartItems.forEach((ci: CartItem) => {
				// Retrieve the SBSLs for this item
				if (!!ci.sbsls) {
					ci.sbsls.forEach((sbsl: SubscriptionBuyerSelectionLinkDescriptor) => sbslIds.push(String(sbsl.id)));
				}
			});

			dispatch(ApiActions.fetchSubscriptionFulfillmentGroups(cart.cartId, sbslIds))
				.then((response) => {
					if (response.type === ActionTypes.API_SUCCESS) {
						setSubscriptionFulfillmentGroups(Object.values(response.data));
					}
				});
		}
	},[cart.cartId, cart.cartItems, dispatch, match.params.allocationId])
	
	return(
		<div>
			{!!eventDescriptor && (
				<div className="mb-5">
					<h1>{eventDescriptor.teName}</h1>
					<h2>{eventDescriptor.name}</h2>
				</div>
			)}
			
			<Switch>
				<Route
					path={[Paths.FULFILLMENT_VENUE, Paths.FULFILLMENT_SECTION, Paths.FULFILLMENT_INSTANCE, Paths.FULFILLMENT_INSTANCE_SECTION]}
					exact={true} 
					render={(routeProps) => {
						return (
							<FulfillmentSeatSelection
								{...props}
								cart={cart}
								fulfillmentGroups={subscriptionFulfillmentGroups}
								cartTimeRemaining={cartTimeRemaining}
								{...routeProps}
							/>
						)}
					}
				/>
				
				<Route path={Paths.FULFILLMENT} exact={true} render={(routeProps) => {
					return (
						<FulfillmentLanding
							{...props}
							cart={cart}
							fulfillmentGroups={subscriptionFulfillmentGroups}
							instanceDetail={eventDescriptor?.eiDetail}
							{...routeProps}
						/>
					)}
				} />
			</Switch>
		</div>
	)
}
export default injectIntl(Fulfillment);