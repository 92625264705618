import * as React from "react";
import {Component} from "react";
import {FormattedMessage, FormattedNumber, InjectedIntlProps} from "react-intl";
import {Button, FormGroup, Input, Row, Col} from "reactstrap";
import {getPriceWithFeesIncluded} from "../../helpers/utilities";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {AddToCartFeeDisplay} from "../add-to-cart/add-to-cart-fee-display";
import {LoadingIcon, RemoveItemIcon} from "../icons/icons";

interface PYOSCartItemProps extends InjectedIntlProps {
	cart: Cart;
	cartItem: CartItem;
	priceLevels: LevelDescriptor[];
	pending: boolean;
	currencyCode: string;
	currencySym: string;
	updateCartItems: (cartId: string, cartItems: CartItem[], nonBlocking: boolean) => void;
	deleteCartItems: (cartId: string, cartItems: CartItem[], nonBlocking: boolean) => void;
	includeFeesInPrice: boolean;
	showPricingDetails: boolean;
	deleteStagedSeat: (seatId: string) => void;
	updateStagedSeatPriceLevel: (seatId: string, priceLevelId: string, pwywPrice?: number) => void;
	stagedSeat: boolean;
}

interface PYOSCartItemState {
	levelId: string;
	pwywPrice?: number;
	isEditing: boolean;
}

export class PYOSCartItem extends Component<PYOSCartItemProps, PYOSCartItemState> {
	public static defaultProps: Partial<PYOSCartItemProps> = {
		pending: false
	};

	public readonly state: PYOSCartItemState = {
		levelId: this.props.cartItem.levelId,
		pwywPrice: this.props.cartItem.unitPrice ? this.props.cartItem.unitPrice : undefined,
		isEditing: false,
	};

	public handleRemove = () => {
		const { cart, cartItem, stagedSeat } = this.props;
		stagedSeat
			? this.props.deleteStagedSeat(cartItem.seatId!)
			: this.props.deleteCartItems(cart.cartId, [cartItem], true);
	}

	public handlePriceChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { cart, cartItem, stagedSeat, updateStagedSeatPriceLevel } = this.props;
		const newLevelId = evt.target.value;
		if(stagedSeat) {
			updateStagedSeatPriceLevel(cartItem.seatId!, newLevelId);
		} else {
			const updatedCartItem = { ...cartItem, levelId: newLevelId };
			this.props.updateCartItems(cart.cartId, [updatedCartItem], false);
		}
		
		this.setState({ 
			levelId: newLevelId,
			pwywPrice: undefined,
			isEditing: false,
		 });
	}

	public handlePWYWUpdateCart = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { cart, cartItem, stagedSeat, updateStagedSeatPriceLevel } = this.props;
		if (this.state.pwywPrice) {
			if (stagedSeat) {
				updateStagedSeatPriceLevel(cartItem.seatId!, this.state.levelId, this.state.pwywPrice)
			} else {
				const updatedCartItem = { ...cartItem, unitPrice: this.state.pwywPrice };
				this.props.updateCartItems(cart.cartId, [updatedCartItem], false);
			}
		}

		this.setState({
			isEditing: false
		});
	}

	public handlePWYWPriceChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const pwywPrice = evt.target.value;
		this.setState({ 
			pwywPrice: pwywPrice ? Number(pwywPrice) : undefined
		});
	}

	public toggleEditMode = () => {
		this.setState({ isEditing: !this.state.isEditing });
	}

	public render() {
		const { 
			cartItem: {
				levelName,
				seatAssign,
				subtotal = 0,
				itemTotal = 0,
			},
			priceLevels,
			currencyCode,
			currencySym,
			pending,
			includeFeesInPrice,
			intl,
			showPricingDetails,
			stagedSeat
		} = this.props;
		const {isEditing, levelId, pwywPrice} = this.state;
		const multiplePriceLevels: boolean = priceLevels.length > 1 && !pending;

		// Get select options for each price level
		const priceLevelOpts = priceLevels.map((priceLevel) => {
			const price = includeFeesInPrice && !priceLevel.pwyw ? getPriceWithFeesIncluded(priceLevel) : priceLevel.price;
			const formattedPrice = intl.formatNumber(price, { style: 'currency', currency: currencyCode });
			return (
				<option key={`level-${priceLevel.id}`} value={priceLevel.id}>
					{`${priceLevel.name} (${formattedPrice}${priceLevel.pwyw ? ' ' + intl.formatMessage({id: "lbl_MinimumAmount"}) : ''})`}
				</option>);
		});

		const currentLevel = priceLevels.find((priceLevel) => {
			return priceLevel.id === levelId;
		})
		
		// TODO: PMGR-7832 - "seatAssign" is not present on PYOS subs, making this component not yet ready for subs. Handle PYOS subs case.
		return (
			<div className="border-top py-2">
				<div className="d-flex justify-content-between align-items-center">
					<div className="mr-2">
						<small>{seatAssign}</small>
					</div>
					<div>
						<small>
							<span className="mr-2">
								<FormattedNumber
									value={includeFeesInPrice ? itemTotal : subtotal}
									style="currency"
									currency={currencyCode}
									currencyDisplay="symbol" />
							</span>
							{pending ? (
								<Button color="link">
									<LoadingIcon />
								</Button>
							) : (
								<Button color="link" onClick={this.handleRemove}>
									<RemoveItemIcon />
								</Button>
							)}
						</small>
					</div>
				</div>
				{isEditing || (stagedSeat && multiplePriceLevels) ? (
					<FormGroup>
						<Row noGutters={true}>
							<Col lg={currentLevel?.pwyw ? "8" : "12"} md="12" xs={currentLevel?.pwyw ? "8" : "12"}>
								<Input type="select" aria-label={intl.formatMessage({id: "lbl_PriceLevelLabel"})} onChange={this.handlePriceChange} value={levelId}>
									{priceLevelOpts}
								</Input>
							</Col>
							{currentLevel?.pwyw && <Col lg="4" md="12" xs="4" className="mt-lg-0 mt-md-1">
								<div className="d-flex">
									<span className="ml-2 mr-1 mt-auto mb-auto">{currencySym}</span>
									<Input
										type="number"
										aria-label={currentLevel.name + intl.formatMessage({id: "lbl_PWYWPriceSuffix"})}
										min={currentLevel.price}
										id={currentLevel.id + '-pwyw-price'}
										name={currentLevel.id + '-pwyw-price'}
										value={pwywPrice === undefined ? '' : pwywPrice}
										onBlur={this.handlePWYWUpdateCart}
										onChange={this.handlePWYWPriceChange}
									/>
								</div>
							</Col>}
						</Row>
					</FormGroup>
				) : (
					!(pending && !stagedSeat) && (
						<Row className="mb-3" noGutters={true}>
							<Col className="d-flex" lg={currentLevel?.pwyw ? "8" : "12"} md="12" xs={currentLevel?.pwyw ? "8" : "12"}>
								<small className="mt-auto mb-auto">
									<span className="mr-1">
										<FormattedMessage id="lbl_PriceLevel" />
									</span>
									<span>{levelName}</span>
								</small>
							</Col>
							{currentLevel?.pwyw && <Col lg="4" md="12" xs="4" className="mt-lg-0 mt-md-1">
									<div className="d-flex">
										<span className="ml-lg-2 ml-xs-2 mr-1 mt-auto mb-auto">{currencySym}</span>
										<Input
											type="number"
											aria-label={currentLevel.name + intl.formatMessage({id: "lbl_PWYWPriceSuffix"})}
											min={currentLevel.price}
											id={currentLevel.id + '-pwyw-price'}
											name={currentLevel.id + '-pwyw-price'}
											value={pwywPrice === undefined ? '' : pwywPrice}
											onBlur={this.handlePWYWUpdateCart}
											onChange={this.handlePWYWPriceChange}
										/>
									</div>
								</Col>}
							{multiplePriceLevels && <Col>
								<Button
									className="pl-0 pr-0 pt-0 mb-1"
									color="link"
									onClick={this.toggleEditMode}>
									<small>
										<FormattedMessage id="lbl_Change" />
									</small>
								</Button>
							</Col>}
						</Row>
					)
				)}
				{(includeFeesInPrice || currentLevel?.pwyw) && (!isEditing || stagedSeat) && <AddToCartFeeDisplay isOpen={showPricingDetails} priceLevel={currentLevel} cartItem={this.props.cartItem} intl={intl} />} 
			</div>
		);
	}
}


