import momentTZ from "moment-timezone";
import * as moment from "moment/min/moment-with-locales";
import * as React from "react";
import {InjectedIntl} from "react-intl";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import Collapse from "reactstrap/lib/Collapse";
import {AnyAction} from "redux";
import {getMobileTicketPath} from "../../helpers/routing";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PortalOrder} from "../../models/portal/portal-order";
import {PortalOrderDetails} from "../../models/portal/portal-order-details";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {DetailToggleButton} from "../detail-toggle-button";
import {TicketOrderWithIntl} from "../ticket-order/ticket-order";
import {OrderHeader} from "./order-header";

interface PortalOrderListProps extends RouteComponentProps<any> {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	config: PublicTicketAppConfig;
	fetchPortalOrderDetails: (orderId: string) => Promise<any>;
	portalOrders: PortalOrder[];
	intl: InjectedIntl;
	portalOrderCache: BasicStringKeyedMap<PortalOrderDetails>;
}

interface PortalOrderListState {
	expandedOrderId: string;
	portalOrdersGroupedByDate: BasicStringKeyedMap<PortalOrder[]>;
}

export class OrderList extends React.Component<PortalOrderListProps, PortalOrderListState> {
	public readonly state: PortalOrderListState = {
		expandedOrderId: '',
		portalOrdersGroupedByDate: {}
	};
	
	public componentDidMount() {
		const {portalOrders} = this.props;
		// Sort orders by createdDate (a DateTime), then group them by formatted date string
		if (portalOrders.length > 0) {
			this.setState({portalOrdersGroupedByDate: this.groupPortalOrdersByDate()});
		}
	}
	
	public componentDidUpdate(prevProps: PortalOrderListProps, prevState: PortalOrderListState) {
		const {fetchPortalOrderDetails, portalOrderCache, portalOrders} = this.props;
		const {expandedOrderId} = this.state;
		// Whenever the user expands a different order, fetch it if it is not already in the cache
		if (!!expandedOrderId && (expandedOrderId !== prevState.expandedOrderId) && !(expandedOrderId in portalOrderCache)) {
			fetchPortalOrderDetails(expandedOrderId);
		}
		// Sort orders by createdDate (a DateTime), then group them by formatted date string
		if (portalOrders.length > prevProps.portalOrders.length) {
			this.setState({portalOrdersGroupedByDate: this.groupPortalOrdersByDate()});
		}
	}

	public render() {
		const {blockingActions, config, portalOrderCache, intl} = this.props;
		const {expandedOrderId, portalOrdersGroupedByDate} = this.state;
		const portalOrderDetails = portalOrderCache[expandedOrderId];
		return (
			<>
				{Object.keys(portalOrdersGroupedByDate).map((dateString: string) => {
					const portalOrdersForDate: PortalOrder[] = portalOrdersGroupedByDate[dateString];
					return (
						<div key={dateString} className="mb-4">
							<p className="m-0 text-uppercase text-muted border-bottom small">
								{dateString}
							</p>
							{portalOrdersForDate.map(portalOrder => {
								const isOpen = portalOrder.id === expandedOrderId;
								return (
									<div key={portalOrder.id} className="mb-2">
										<OrderHeader portalOrder={portalOrder} currencyCode={config.currencyCode} isOpen={isOpen} intl={intl}/>
										<DetailToggleButton onClick={() => this.setVisibleOrderId(portalOrder.id)} detailsVisible={isOpen} />
										<Collapse isOpen={isOpen}>
											{!!portalOrderDetails && (
												<>
													{!!portalOrderDetails.mobileTicketToken && (
														<div className='pt-2 pb-2'>
															<Link
																className='btn btn-primary btn-sm'
																to={getMobileTicketPath(portalOrderDetails.cart.id, portalOrderDetails.mobileTicketToken)}
															>
																{intl.formatMessage({id: "lbl_ViewYourTickets"})}
															</Link>
														</div> 
													)}
													<TicketOrderWithIntl
														allowEdit={false}
														blockingActions={blockingActions}
														cart={portalOrderDetails.cart}
														config={config}
														detailsExpanded={true}
														itemFeeData={portalOrderDetails.itemFeeData}
														orderFeeData={portalOrderDetails.orderFeeData}
													/>
												</>
											)}
										</Collapse>
									</div>
								);
							})}
						</div>
					);
				})}
			</>
		);
	}
	
	private groupPortalOrdersByDate = (): BasicStringKeyedMap<PortalOrder[]> => {
		const {config, portalOrders} = this.props;
		
		// moment and moment-timezone don't play so well together. You need to explicitly load the locale config from moment into moment-timezone
		// Reference: https://github.com/moment/moment-timezone/issues/647#issuecomment-439600573
		momentTZ.updateLocale(moment.locale(), moment.localeData()._config);
		momentTZ.locale(moment.locale());
		
		// Group the portal orders into buckets by creation date. They are already sorted server side by CreatedDate DESC by the server
		return portalOrders.reduce((prev, portalOrder) => {
				const dateString: string = momentTZ(portalOrder.createdDate).tz(config.timezone).format("LL");
				let portalOrdersForDate: PortalOrder[] = prev[dateString];
				if (!portalOrdersForDate) {
					portalOrdersForDate = new Array<PortalOrder>();
					prev[dateString] = portalOrdersForDate;
				}
				portalOrdersForDate.push(portalOrder);
				return prev;
			}, {});
	}
	
	private setVisibleOrderId = (id: string) => {
		const visibleOrderId = id !== this.state.expandedOrderId ? id : '';
		this.setState({expandedOrderId: visibleOrderId});
	}
}
