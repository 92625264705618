import * as H from 'history';
import * as React from "react";
import {InjectedIntlProps} from "react-intl";
import {RouteComponentProps} from "react-router";
import {AnyAction} from "redux";
import {AlertOptions} from "../../actions/public-ticket-app-actions";
import {ActionTypes} from "../../enums/action-types";
import {Paths} from "../../enums/paths";
import {SeatingTypes} from "../../enums/seating-types";
import {Severities} from "../../enums/severities";
import {getPWYWPriceWithFeesIncluded, getPriceWithFeesIncluded} from "../../helpers/utilities";
import {Layout} from "../../hoc/layout";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {ButtonColors, ButtonProps} from "../../models/public-ticket-app/button-props";
import {ModalProps} from "../../models/public-ticket-app/modal-props";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {GAQuantityInput} from "./ga-item-quantity-selector";

interface GAItemSelectionProps extends RouteComponentProps<any>, InjectedIntlProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	config: PublicTicketAppConfig;
	cart: Cart;
	clearAllMessages: () => void;
	eventDescriptor: EventDescriptor;
	insertCartItems: (cartId: string, cartItems: CartItem[], nonBlocking?: boolean) => Promise<any>;
	deleteCartItems: (cartId: string, cartItems: CartItem[], nonBlocking?: boolean) => Promise<any>;
	pendingSeatRequests: BasicStringKeyedMap<string>;
	showAlert: (alertOptions: AlertOptions) => void;
	showModalAction: (modalProps: ModalProps) => void;
	layout: Layout<QuantityBasedEventInfo, QuantityBasedCartFormProps>;
}

interface GAItemSelectionState {
	isFormValid: boolean;
	gaQuantityInputs: BasicStringKeyedMap<GAQuantityInput>;
}

/**
 * Common interface for any quantity based add items to cart form.
 *
 * Examples: General Admission and Membership Forms are both quantity based.
 */
export interface QuantityBasedCartFormProps extends InjectedIntlProps {
	cart: Cart;
	cartTimeRemaining?: number;
	config: PublicTicketAppConfig;
	currencyCode: string;
	deleteFromCart: (gaQtyInput: GAQuantityInput) => void;
	eventDescriptor: EventDescriptor;
	history: H.History;
	quantityInputs: BasicStringKeyedMap<GAQuantityInput>;
	handleQuantityChange: (gaQtyInput: GAQuantityInput) => void;
	handleNext: () => void;
}

/**
 * Common interface for the event info view
 */
export interface QuantityBasedEventInfo {
	eventDescriptor: EventDescriptor;
}

export class GAItemSelection extends React.Component<GAItemSelectionProps, GAItemSelectionState> {

	public readonly state: GAItemSelectionState = {
		isFormValid: false,
		gaQuantityInputs: {}
	};

	private GAItemSelectionLayout: Layout<QuantityBasedEventInfo, QuantityBasedCartFormProps>;

	constructor(props: GAItemSelectionProps){
		super(props);

		this.GAItemSelectionLayout = props.layout;
	}

	public componentDidMount() {
		this.updateGAInputMapState();
		if (this.props.location.search) {
			this.initializeState();
		}
	}

	public componentDidUpdate(prevProps: GAItemSelectionProps, prevState: GAItemSelectionState ) {
		// We need to update the GAInputMapState when
		//  - Once all pending seat requests have completed so the isBusy flag is reset properly
		//  - Someone enters a passcode that unlocks a private allocation that was previously unavailable
		if ((Object.keys(prevProps.pendingSeatRequests).length > 0 && !(Object.keys(this.props.pendingSeatRequests).length > 0)) ||
			prevProps.eventDescriptor.allocList.length !== this.props.eventDescriptor.allocList.length) {
			this.updateGAInputMapState(prevState);
		}
	}

	public render() {
		const {cart, config, eventDescriptor, history, intl} = this.props;

		if (!eventDescriptor || eventDescriptor.seatingType !== SeatingTypes.GA) {
			return null;
		}

		//props specific to the ga-cart only
		const gaCartProps: QuantityBasedCartFormProps = {
			cart,
			config,
			currencyCode: config.currencyCode,
			deleteFromCart: this.deleteFromCart,
			eventDescriptor,
			history,
			intl,
			quantityInputs: this.state.gaQuantityInputs,
			handleQuantityChange: this.handleQtyChange,
			handleNext: this.handleNext
		};

		return <this.GAItemSelectionLayout {...this.props} {...gaCartProps} />;
	}

	private initializeState = () => {
		const {location} = this.props;
		// See PMGR-8083 - This allows you to pass a param on the URL to auto-fill the GA quantities.
		// NOTE: You MUST use the 18 character ID of the price level for this to work
		const urlParams = new URLSearchParams(location.search);
		const gaQuantityInputs = this.getGAInputMapState();
		urlParams.forEach((value: string, key: string) => {
			if (key.startsWith("quantity_")) {
				const plId = key.replace("quantity_", "");
				const plQty = value;
				const gaInput = gaQuantityInputs[plId];
				if (!!gaInput) {
					gaInput.inputQty = plQty;
					gaInput.isValid = this.isQtyValid(plQty);
					gaQuantityInputs[plId] = gaInput;
				}
			}
		});
		this.setState({gaQuantityInputs}, this.validate);
	}


	private updateGAInputMapState = (prevState?: GAItemSelectionState) => {
		const gaQuantityInputs = this.getGAInputMapState(prevState);
		this.setState({gaQuantityInputs}, this.validate);
	}

	private getGAInputMapState = (prevState?: GAItemSelectionState) => {
		const {cart, eventDescriptor, pendingSeatRequests} = this.props;

		//Map of price levels and corresponding quantities keys by price level id
		const gaQuantityInputs = eventDescriptor.levelList.reduce((prev: BasicStringKeyedMap<GAQuantityInput>, level: LevelDescriptor) => {
			const alloc: AllocationDescriptor | undefined = eventDescriptor.allocList.find(ta => ta.id === level.allocId);
			const noteAck = (!!alloc && alloc.noteAck) ? alloc.noteAck : '';
			
			const prevPL: GAQuantityInput | undefined = prevState && prevState.gaQuantityInputs[level.id];
			const cartItemsQty = cart.cartItems.filter(cartItem => cartItem.levelId === level.id).length;
			const cartItem = cart.cartItems.find(cartItem => cartItem.levelId === level.id);
			const cartItemsPrice = cartItem ? cartItem.unitPrice : undefined;
			prev[level.id] = {
				level,
				cartQty: cartItemsQty,
				eiId: eventDescriptor.id,
				inputQty: !!prevPL && cartItemsQty === 0 ? prevPL.inputQty : '',
				isBusy: pendingSeatRequests[level.id] !== undefined ? true : false,
				isValid: !!prevPL && cartItemsQty === 0 ? prevPL.isValid : false,
				noteAck,
				pwywPrice: cartItemsPrice
			};
			return prev;
		}, {});
		
		return gaQuantityInputs;
	}
	
	private validate = () => {
		const gaQuantityInputs = this.state.gaQuantityInputs;
		const invalidQuantities = Object.keys(gaQuantityInputs).filter(priceLevelId => {
			return !this.isQtyValid(gaQuantityInputs[priceLevelId].inputQty);
		});
		const isFormValid = invalidQuantities.length === 0;
		this.setState({isFormValid});
	}

	private isQtyValid = (quantity: string) => {
		const qty: number = Number(quantity);
		return Number.isInteger(qty) && qty > 0;
	}

	private handleQtyChange = (gaQtyInput: GAQuantityInput) => {
		gaQtyInput.isValid = this.isQtyValid(gaQtyInput.inputQty);  // validate qty
		
		this.setState((prevState) => {
			const gaQuantityInputs = {...prevState.gaQuantityInputs};
			gaQuantityInputs[gaQtyInput.level.id] = gaQtyInput;
			return {gaQuantityInputs};
		}, this.validate);
	}

	private deleteFromCart = (gaQtyInput: GAQuantityInput) => {
		const {cart} = this.props;
		
		// update the 'isBusy' flag for the PL being deleted so the user can't double-click
		this.setState((prevState) => {
			const gaQuantityInputs = {...prevState.gaQuantityInputs};
			gaQuantityInputs[gaQtyInput.level.id].isBusy = true;
			return {gaQuantityInputs}
		})
		const plCartItems = cart.cartItems.reduce((prev: CartItem[], cartItem: CartItem) => {
			if (gaQtyInput.level.id === cartItem.levelId) {
				prev.push(cartItem);
			}
			return prev;
		}, []);

		this.props.deleteCartItems(cart.cartId, plCartItems, false);

	}

	public handleNext = () => {
		const {clearAllMessages, intl, showModalAction} = this.props;
		const {gaQuantityInputs} = this.state;

		// Clear errors at the start of the operation
		clearAllMessages();
		
		const gaInputsToInsert = Object.values(gaQuantityInputs).filter(gaInput => Number(gaInput.inputQty) > 0);
		if(gaInputsToInsert.length === 0) {
			// if there are no items to insert, head straight to the cart
			this.props.history.push(Paths.CART);
		} else {
			// see if any of the price levels being inserted require acknowledgement
			const acknolwedgementMap: BasicStringKeyedMap<JSX.Element> = {};
			gaInputsToInsert.forEach((gaInput) => {
				if (gaInput.noteAck && !(gaInput.level.allocId in acknolwedgementMap)) {
					acknolwedgementMap[gaInput.level.allocId] = (
						<div key={gaInput.level.allocName} className="mb-3">
							<h5>{gaInput.level.allocName}</h5>
							<span>{gaInput.noteAck}</span>
						</div>
					)
				}
			});
			if(!!Object.keys(acknolwedgementMap).length) {
				new Promise<void>((resolve, reject) => {
					showModalAction(new ModalProps(
						[
							// The callback on the "OK" button resolves the Promise
							new ButtonProps(ButtonColors.SECONDARY, intl.formatMessage({id: "lbl_button_Decline"}), true, () => reject()),
							new ButtonProps(ButtonColors.PRIMARY, intl.formatMessage({id: "lbl_button_OK"}), false, () => resolve())
						],
						Object.values(acknolwedgementMap),
						intl.formatMessage({id: "lbl_ConfirmSelection"}),
						true,
						() => reject()
					));
				}).then(() => {
					// When the Promise is resolved, complete the process of adding the new CartItems and mark the seats as acknowledged
					this.insertCartItemsForGA().then(response => this.handleInsertCartItemsResponse(response));
				});
			} else {
				// No acknowledgment required, add the new CartItems
				this.insertCartItemsForGA().then(response => this.handleInsertCartItemsResponse(response));
			}
		}
	}
	
	private handleInsertCartItemsResponse = (response: any) => {
		if(response.type === ActionTypes.API_SUCCESS) {
			this.props.history.push(Paths.CART);
		}
	}
	
	private insertCartItemsForGA = (): Promise<any> =>  {
		const {cart, eventDescriptor, insertCartItems, location, showAlert} = this.props;
		const {gaQuantityInputs} = this.state;
		
		// PMGR-7844 If landing on the page with the benefitId param on in the search string, we came here as the result
		// of renewing a benefit. Save the benefit ID here so we can apply it to the CartItems begin added below.
		const urlParams = new URLSearchParams(location.search);
		const benefitId = urlParams.get("benefitId");
		
		const cartItems: CartItem[] = [];
		Object.values(gaQuantityInputs).forEach((gaInput) => {
			const quantity: number = Number(gaInput.inputQty);
			for (let i = 0; i < quantity; i++) {
				const cartItem: CartItem = new CartItem();
				cartItem.qty = 1;
				cartItem.toId = cart.id;
				cartItem.levelId = gaInput.level.id;
				cartItem.eiId = gaInput.eiId;
				cartItem.passcode = eventDescriptor.appliedPasscode;
				cartItem.unitPrice = gaInput.pwywPrice ? gaInput.pwywPrice : cartItem.unitPrice;
				if (!!benefitId) {
					cartItem.benefitId = benefitId;
				}
				cartItems.push(cartItem);
			}
		})
		
		if (cartItems.length === 0) {

			const alertOptions: AlertOptions  = {
				alertId: "msg_insufficient_quantity_specified",
				alertSeverity:  Severities.ERROR
			};

			showAlert(alertOptions);

			return Promise.resolve();
		}
		
		// update the 'isBusy' flag for the PL being added so the user can't double-click
		this.setState((state) => {
			const updatedGAQuantityInputs = {...state.gaQuantityInputs};
			Object.values(gaQuantityInputs).forEach(gaInput => {
				if(Number(gaInput.inputQty) > 0) {
					updatedGAQuantityInputs[gaInput.level.id].isBusy = true	
				}
			});
			return {gaQuantityInputs: updatedGAQuantityInputs};
		});
		
		return insertCartItems(cart.cartId, cartItems);
	}
}

export const getGAQuantityInputsTotalPrice = (gaQuantityInputs: GAQuantityInput[], includeFees: boolean) => {
	return gaQuantityInputs
		.filter(gaInput => Number(gaInput.inputQty) > 0)
		.reduce((prevTotal, gaInput) => {
			let itemPrice;
			if (gaInput.level.pwyw) {
				itemPrice = includeFees ? getPWYWPriceWithFeesIncluded(gaInput.pwywPrice ? gaInput.pwywPrice : 0, gaInput.level) : Number(gaInput.pwywPrice ? gaInput.pwywPrice : 0);
			} else {
				itemPrice = includeFees ? getPriceWithFeesIncluded(gaInput.level) : Number(gaInput.level.price);
			}
			return prevTotal + (itemPrice * Number(gaInput.inputQty));
		},0);
}
