import {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AnalyticsActions} from "../actions/analytics-actions";
import {ApiActions} from "../actions/api-actions";
import {PublicTicketAppActions} from "../actions/public-ticket-app-actions";
import {layout} from "../hoc/layout";
import {Analytics} from "../models/analytics";
import {PublicTicketApp} from "../models/public-ticket-app/public-ticket-app";
import {RootState} from "../reducers";
import {RouteProps} from "../types/route-props";
import {ErrorMessage} from "./error/error-message";
import {EventDetail} from "./event-detail/event-detail";
import {EventHero} from "./event-detail/event-hero";

export interface EventProps extends InjectedIntlProps, RouteProps {}

export const Event: FunctionComponent<EventProps> = (props: EventProps) => {
	const {intl, match} = props;
	
	const analytics = useSelector((state: any): Analytics => state.analytics);
	const {blockingActions, cartTimeRemaining, eventList, fetchingEvents, selectedEvent} = useSelector((state: any): PublicTicketApp => state.ptApp);
	
	const [noPerformancesAvailable, setNoPerformancesAvailable] = useState<boolean>(false);
	
	const getTitle = useCallback((): string => {
		return intl.formatMessage({id: 'lbl_title_ticketable_event'}, {ticketableEventName: (!!selectedEvent) ? selectedEvent.name : ''});
	}, [intl, selectedEvent]);
	
	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
	
	// This effect tries to find the TicketableEvent in the eventList, and fetches it from the server if it is not found
	useEffect(() => {
		const {ticketableEventId} = match.params;
		let event;
		if (!!eventList) {
			// Try and retrieve the event from the eventList
			event = eventList.find(e => e.id === ticketableEventId);
			// If the eventList is populated, and event isn't in it, set noPerformancesAvailable 
			setNoPerformancesAvailable(!event);
		}
		
		if (!!event) {
			// If found, and it doesn't match the selectedEvent, then set the selectedEvent
			if (event !== selectedEvent) {
				dispatch(PublicTicketAppActions.setSelectedEvent(event));
			}
		} else {
			// If not found in the eventList, then fetch it from the server. Guard against infinite fetch loop.
			if (!fetchingEvents && selectedEvent?.id !== ticketableEventId && !noPerformancesAvailable) {
				dispatch(ApiActions.fetchEvent(ticketableEventId))
					.then((result: any) => {
						if (!result.data) {
							// If the event is not returned set noPerformancesAvailable 
							setNoPerformancesAvailable(true);
						}
					});
			}
		}
	}, [dispatch, eventList, fetchingEvents, match.params, noPerformancesAvailable, selectedEvent]);
	
	useEffect(() => {
		const {ticketableEventId} = match.params;
		if (selectedEvent?.id === ticketableEventId) {
			// Log the page view
			if (analytics.url !== window.location.href) {
				dispatch(AnalyticsActions.pageView(getTitle(), window.location.href));
			}
		}
	}, [analytics.url, dispatch, getTitle, match.params, selectedEvent]);
	
	const EventDetailComponent = useMemo(() => {
		let edComponent;
		if (!!selectedEvent) {
			edComponent = layout({Main: EventHero, Panel: EventDetail}, null, null, {primary: selectedEvent.name});
		}
		return edComponent;
	}, [selectedEvent]);
	
	const clearAllMessages = useCallback(() => {
		dispatch(PublicTicketAppActions.clearAllMessages());
	}, [dispatch]);
	
	const fetchEvents = useCallback(() => {
		dispatch(ApiActions.fetchEvents());
	}, [dispatch]);
	
	const validatePasscode = useCallback((passcode: string, eventInstanceId: string) => {
		return dispatch(ApiActions.validatePasscode(passcode, eventInstanceId));
	}, [dispatch]);
	
	if (noPerformancesAvailable){
		const ErrPage = layout<typeof ErrorMessage>({Main: ErrorMessage}, intl.formatMessage({id: "msg_not_on_sale"}));
		return <ErrPage message={intl.formatMessage({id: "msg_item_not_available_for_purchase"})} />;
	}
	
	if (!!EventDetailComponent) {
		return (
			<EventDetailComponent
				blockingActions={blockingActions}
				cartTimeRemaining={cartTimeRemaining}
				clearAllMessages={clearAllMessages}
				event={selectedEvent}
				fetchEvents={fetchEvents}
				validatePasscode={validatePasscode}
			/>
		);
	}
	return null;
}
export default injectIntl(Event);