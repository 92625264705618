import * as React from 'react';
import {Component} from 'react';
import {FormattedMessage, InjectedIntlProps} from 'react-intl';
import {RouteComponentProps} from "react-router";
import {Button, Card, CardBody, CardTitle, Input} from 'reactstrap';
import {SeatingTypes} from "../../enums/seating-types";
import {TicketableEventTypes} from "../../enums/ticketable-event-types";
import {InventoryService} from "../../helpers/inventory-service";
import {getRouteToItem} from "../../helpers/routing";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {Ticket} from "../../models/ticket";
import {FormElementLabel} from "../form-element-label/form-element-label";
import {TicketItemDiscountRow} from './ticket-item-discount-row';
import {TicketItemRow} from "./ticket-item-row";
import './ticket-order.css';

export interface TicketItemProps extends InjectedIntlProps, RouteComponentProps<any> {
	allowEdit: boolean;
	changeItemPriceLevel?: (cartItemId: string, priceLevelId: string) => void;
	currencyCode: string;
	eventDescriptor: EventDescriptor | null;
	isBusy?: boolean;
	removeItem?: (cartItemId: string) => void;
	ticket: Ticket;
}

interface TicketItemState {
	isEditMode: boolean;
}

/**
 * Display a single ticket in the ticket table
 */
export class TicketItem extends Component<TicketItemProps, TicketItemState> {
	public static defaultProps: Partial<TicketItemProps> = {
		isBusy: false
	};

	public readonly state: TicketItemState = {
		isEditMode: false
	};
	
	public edit = () => {
		this.setState({isEditMode: true});
	}
	
	public toggleEditMode = () => {
		this.setState({isEditMode: !this.state.isEditMode});
	}
	
	public handleChangeSeatClick = () => {
		const seatChangeRoute = getRouteToItem(this.props.ticket.cartItem);
		this.props.history.push(seatChangeRoute);
	}
	
	public handlePriceLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {changeItemPriceLevel} = this.props;
		const newLevelId = event.target.value;
		if (!!changeItemPriceLevel) {
			changeItemPriceLevel(this.props.ticket.cartItem.id, newLevelId);
			this.toggleEditMode();
		}
	}
	
	public handleRemoveItem = () => {
		const {removeItem, ticket: { cartItem }} = this.props;
		if (!!removeItem) {
			removeItem(cartItem.id);
		}
	}
	
	public render() {
		const {allowEdit, currencyCode, eventDescriptor, intl, isBusy, ticket} = this.props;
		const {cartItem, fees} = ticket;

		const {
			allocId,
			allocName,
			disName = '',
			disTotal,
			levelId,
			levelName,
			seatAssign,
			seatingType,
			seatNote,
			unitPrice
		} = cartItem;
		
		const {isEditMode} = this.state;
		
		// PMGR-8324 - For Membership items, don't show the Ticket Allocation name
		let itemName = levelName;
		if (ticket.cartItem.ticketType !== TicketableEventTypes.MEMBERSHIP) {
			itemName = (seatingType === SeatingTypes.PYOS) ? `${seatAssign} - ${itemName}` : `${allocName} - ${itemName}`;
		}
		
		const itemDetails = (
			<div>
				{/* Price */}
				<TicketItemRow intlId="lbl_Price" priceInfo={{minPrice: unitPrice}} currencyCode={currencyCode} intl={intl}/>

				{/* Fees */}
				{fees.map(fee => (
					<TicketItemRow key={fee.label} userDefinedLabel={fee.label} priceInfo={{minPrice: fee.value}} currencyCode={currencyCode} intl={intl}/>
				))}

				{!!disTotal && (
					<TicketItemDiscountRow
						discountName={disName}
						value={disTotal}
						currencyCode={currencyCode}
					/>
				)}
			</div>
		);
		
		let editForm: JSX.Element | null = null;
		const editActions: JSX.Element[] = [];
		const currencyOpts = {style: "currency", currency: currencyCode.toString(), currencyDisplay: "symbol"};
		
		if (allowEdit) {
			// If the currently selected EventDescriptor is for this ticket item then get the price levels
			const priceLevels: LevelDescriptor[] = (!!eventDescriptor && !!allocId) ? InventoryService.getLevelsByAllocationId(eventDescriptor, allocId) : [];
			
			if (isEditMode) {
				editForm = (
					<div>
						{/* If there aren't multiple price levels, then there's no reason to render the select list to let the user change price levels */}
						{priceLevels.length > 1 && (
							<div>
								<FormElementLabel
									intlLabelId="lbl_ChangePriceLevel"
									forInput="priceLevelSelect"
								/>
								<div>
									<Input type="select" value={levelId} onChange={this.handlePriceLevelChange} disabled={isBusy}>
										{
											priceLevels.map((priceLevel: LevelDescriptor) => {
												return (
													<option
														key={`level-${priceLevel.id}`}
														value={priceLevel.id}>{`${priceLevel.name} (${intl.formatNumber(priceLevel.price, currencyOpts)})`}
													</option>
												);
											})
										}
									</Input>
								</div>
							</div>
						)}
						{/* Change Seat only makes sense for PYOS items */}
						{seatingType === SeatingTypes.PYOS && (
							<div>
								<Button className="pl-0" color="link" onClick={this.handleChangeSeatClick} disabled={isBusy}>
									<span className="font-weight-bold">
										<FormattedMessage id={"lbl_ChangeSeat"}/>
									</span>
								</Button>
							</div>
						)}
					</div>
				);
			}
			
			editActions.push(
				<Button key="remove" className="pl-0" color="link" size="sm" onClick={this.handleRemoveItem} disabled={isBusy}>
					<FormattedMessage id={"lbl_Remove"} />
				</Button>
			);
			// Don't show the "Edit" link for GA items when there's only one price level
			if (seatingType === SeatingTypes.PYOS || priceLevels.length > 1) {
				editActions.push(
					<Button key="edit" className="pl-0" color="link" size="sm" onClick={this.edit} disabled={isBusy}>
						<FormattedMessage id={"lbl_Edit"}/>
					</Button>
				);
			}
		}
		
		const note = <p className="card-text small border-bottom">{seatNote}</p>;
		const ticketInfo = (
			<div>
				{!!seatNote && note}
				{itemDetails}
				<div className={isBusy ? 'invisible' : 'visible'}>{editActions}</div>
			</div>
		);
		return (
			<Card className="pts-ticket-item mb-2">
				{isEditMode ? (
					<CardBody className="pt-3">
						<CardTitle>
							<Button close={true} onClick={this.toggleEditMode} disabled={isBusy}/>
						</CardTitle>
						<CardTitle>
							<p className="font-weight-bold">{itemName}</p>
						</CardTitle>
						<div>
							{editForm}
						</div>
					</CardBody>
				) : (
					<CardBody className="pt-3">
						<CardTitle className="invisible"> {/* This is here to maintain spacing between modes. */}
							<Button close={true}/>
						</CardTitle>
						<CardTitle>
							<p className="font-weight-bold">{itemName}</p>
							
							{/* Virtual event link */}
							{!!ticket.cartItem.liveStreamUrl && (
								<a href={ticket.cartItem.liveStreamUrl} className="btn btn-primary btn-sm" target="_blank" rel="noreferrer">Click to view</a>
							)}
						</CardTitle>
						<div>
							{ticketInfo}
						</div>
					</CardBody>
				)}
			</Card>
		);
	}
};
