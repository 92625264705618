import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect, RouteComponentProps, Switch} from "react-router";
import {Route} from "react-router-dom";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AnalyticsActions} from "../actions/analytics-actions";
import {ApiActions} from "../actions/api-actions";
import {CartActions} from "../actions/cart-actions";
import {AlertOptions, PublicTicketAppActions} from "../actions/public-ticket-app-actions";
import {VenueDetail} from "../components/add-to-cart/venue/venue-detail";
import {VenueSeatingChart} from "../components/add-to-cart/venue/venue-seating-chart";
import {ErrorMessage} from "../components/error/error-message";
import {GAEventInfo} from "../components/ga-selection/ga-event-info";
import {GAItemSelection} from "../components/ga-selection/ga-item-selection";
import {GASeatForm} from "../components/ga-selection/ga-seat-form";
import {MembershipForm} from "../components/membership/membership-form";
import {MembershipInfo} from "../components/membership/membership-info";
import {PYOSCart} from "../components/pyos-seat-selection/pyos-cart";
import {PYOSSeatSelection} from "../components/pyos-seat-selection/pyos-seat-selection";
import {SectionLegend} from "../components/section-map/section-legend";
import {SectionMap} from "../components/section-map/section-map";
import {Subscription} from "../components/subscription/subscription";
import {ActionTypes} from "../enums/action-types";
import {EventInstanceSaleStatus} from "../enums/event-instance-sale-status";
import {Paths} from "../enums/paths";
import {SeatingTypes} from "../enums/seating-types";
import {SubscriptionTypes} from "../enums/subscription-types";
import {TicketableEventTypes} from "../enums/ticketable-event-types";
import {InventoryService} from "../helpers/inventory-service";
import {createSeatAssignmentStringForSeat, getPWYWPriceWithFeesIncluded, getPriceWithFeesIncluded} from "../helpers/utilities";
import {InjectedInventoryServiceProps, injectInventoryService} from "../hoc/inject-inventory-service";
import {Layout, layout} from "../hoc/layout";
import {Analytics} from "../models/analytics";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {CartItem} from "../models/cart-item";
import {AllocationDescriptor} from "../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../models/event-descriptor/level-descriptor";
import {SeatDescriptor} from "../models/event-descriptor/seat-descriptor";
import {SubscriptionEventDescriptor} from "../models/event-descriptor/subscription-event-descriptor";
import {ButtonColors, ButtonProps} from "../models/public-ticket-app/button-props";
import {ModalProps} from "../models/public-ticket-app/modal-props";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";
import {RootState} from "../reducers";
import {RouteProps} from "../types/route-props";

/**
 * PMGR-9780 - After updating this project to use latest create-react-app Typescript template, plus latest React and React Router,
 * I observed that whenever I tried to load a PYOS Event Instance, the app blows up with the following ugly error: https://www.screencast.com/t/R46gY3Sq.
 * See my comment on the ticket for more details on this error. This only occurs when running the production bundle. If you run in development mode,
 * the section map loads fine. After much trial and error, I pinned this down to the cases where the <Route> component's render function returns 
 * something like this (see the renderInstance method):
 * 
 *    return <this.PYOSSectionSelectionLayout {...this.props} eventDescriptor={eventDescriptor}/>;
 * 
 * In this case the renderInstance method is returning the rendering of the Layout component created by calling the "layout" function. 
 * I found that I could avoid this error by passing the this.PYOSSectionSelectionLayout to this LayoutRenderer function component and having it 
 * render this.PYOSSectionSelectionLayout (or any other component class passed as the "layout" property), like so:
 * 
 *     return <LayoutRenderer layout={this.PYOSSectionSelectionLayout} {...this.props} eventDescriptor={eventDescriptor}/>;
 *     
 * This is basically the same pattern that components like GAItemSelection and ChooseYourOwnSubscription are doing. I use this technique
 * to render this.PYOSSectionSelectionLayout, this.PYOSSeatSelectionLayout, this.VenueDetailsWithoutSeatingChartLayout 
 * and this.VenueDetailsWithSeatingChartLayout. Don't ask me to explain why this was failing in the first place, 
 * nor how it actually fixes the problem. This is black magic. :(
 * @param props
 * @constructor
 */
const LayoutRenderer = (props: any) => {
	const TheLayout = props.layout;
	return <TheLayout {...props}/>;
}

///
/// Interface
///

/**
 * All properties available within this component
 */
interface AddToCartProps extends AddToCartExcludingInjections, InjectedInventoryServiceProps, InjectedIntlProps, AddToCartMappedDispatchProps, AddToCartMappedStateProps {}

/**
 * All properties that should be defined when using the component exported with injections.
 */
interface AddToCartExcludingInjections extends RouteComponentProps<any> {

}

interface AddToCartMappedDispatchProps {
	clearAllMessages: () => void;
	deleteCartItems: (cartId: string, cartItems: CartItem[], nonBlocking?: boolean) => Promise<any>;
	ensureCart: (createIfNecessary?: boolean, nonBlocking?: boolean) => Promise<any>;
	fetchEvents: () => void;
	fetchItemFeeData: (cartId: string) => Promise<any>;
	insertCartItems: (cartId: string, cartItems: CartItem[], nonBlocking?: boolean) => Promise<any>;
	insertPYOSSubscriptionCartItems: (cartId: string, subscriptionCartItems: CartItem[], nonBlocking?: boolean) => Promise<any>;
	insertSubscriptionCartItems: (cartId: string, subscriptionCartItems: CartItem[], selectedTicketPLsBySubscriptionPLMap: BasicStringKeyedMap<string[]> | null, nonBlocking: boolean) => Promise<any>;
	pageView: (title: string, url: string) => void;
	showAlert: (alertOptions: AlertOptions) => void;
	showModalAction: (modalProps: ModalProps) => void;
	updateCartItems: (cartId: string, cartItems: CartItem[], nonBlocking?: boolean) => Promise<any>;
	validateCYOSubscriptionPriceLevels: (subscriptionPriceLevelIds: string[]) => Promise<any>;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

interface AddToCartMappedStateProps {
	analytics: Analytics;
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	config: PublicTicketAppConfig;
	eventDescriptorCache: any;
	pendingItemIds: BasicStringKeyedMap<string>;
	pendingSeatRequests: BasicStringKeyedMap<string>;
}

interface AddToCartState {
	instanceId: string | null;
	eventDescriptor: EventDescriptor | null;
	derivedState: DerivedState | null;
	pyosCartItemMap: BasicStringKeyedMap<CartItem>;
	stagedSeatRequests: BasicStringKeyedMap<CartItem>
}

interface DerivedState {
	// Map of SeatDescriptors or AllocationDescriptors that require acknowledgment before being added to the cart, keyed by their id
	ackMap: BasicStringKeyedMap<Acknowledgment>;
	allocMap: BasicStringKeyedMap<AllocationDescriptor>;
	instanceId: string;
}

export interface Acknowledgment {
	id: string;
	name: string;
	acknowledgment: string;
	acknowledged: boolean;
}

export class AddToCart extends React.Component<AddToCartProps, AddToCartState> {
	
	public static getDerivedStateFromProps(props: AddToCartProps, state: AddToCartState) {
		// If a different eventDescriptor has been loaded into state, then recompute the derived state for the new ED
		if (!!state.eventDescriptor && (!state.derivedState || state.derivedState.instanceId !== state.eventDescriptor.id)) {
			state.derivedState = {
				ackMap: AddToCart.getAckMap(state.eventDescriptor),
				allocMap: AddToCart.getAllocMap(state.eventDescriptor),
				instanceId: state.eventDescriptor.id
			};
		}
		state.pyosCartItemMap = AddToCart.getPYOSCartItemMap(props.cart.cartItems);
		return state;
	}
	
	/**
	 * This method iterates over the SeatDescriptors and AllocationDescriptors and creates an "Acknowledment" object
	 * for any that require acknowledgment before they can be added to the Cart.
	 * @param eventDescriptor
	 */
	private static getAckMap = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<Acknowledgment> => {
		const ackMap = eventDescriptor.seatList.reduce((prev: BasicStringKeyedMap<Acknowledgment>, seat: SeatDescriptor) => {
			const {id, name, snAck} = seat;
			if (!!snAck) {
				prev[id] = {id, name, acknowledgment: snAck, acknowledged: false};
			}
			return prev;
		}, {});
		return eventDescriptor.allocList.reduce((prev: BasicStringKeyedMap<Acknowledgment>, alloc: AllocationDescriptor) => {
			const {id, name, noteAck} = alloc;
			if (!!noteAck) {
				prev[id] = {id, name, acknowledgment: noteAck, acknowledged: false};
			}
			return prev;
		}, ackMap);
	}
	
	/**
	 * Returns a map of AllocDescriptor objects, keyed by their Id
	 */
	private static getAllocMap = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<AllocationDescriptor> => {
		let allocList = eventDescriptor.allocList;
		if (eventDescriptor.teType === TicketableEventTypes.SUBSCRIPTION) {
			// If we're dealing with a single-venue PYOS Subscription ED, then map the allocations in the pyosSubAllocList instead
			const subEventDescriptor = eventDescriptor as SubscriptionEventDescriptor;
			if (subEventDescriptor.subscriptionType === SubscriptionTypes.FIXED
				&& subEventDescriptor.fulfillmentVenueIds.length === 1
				&& subEventDescriptor.pyosSubAllocList.length > 0) {
				allocList = subEventDescriptor.pyosSubAllocList;
			}
		}
		return allocList.reduce((prev: BasicStringKeyedMap<AllocationDescriptor>, alloc: AllocationDescriptor) => {
			prev[alloc.id] = alloc;
			return prev;
		}, {});
	}
	
	/**
	 * Returns a map of PYOS CartItem objects, keyed by the id of the SeatDescriptor assigned to the CartItem
	 */
	private static getPYOSCartItemMap = (cartItems: CartItem[]): BasicStringKeyedMap<CartItem> => {
		// Map PYOS cart items by their seatId. Only include single ticket cart items here, not subs. fulfillment items
		return cartItems.reduce((prev: BasicStringKeyedMap<CartItem>, cartItem: CartItem) => {
			if(cartItem.seatId && !cartItem.stoiId) {
				prev[cartItem.seatId] = cartItem;
			}
			return prev;
		}, {});
	}
	
	public readonly state: AddToCartState = {
		instanceId: null,
		eventDescriptor: null,
		derivedState: null,
		pyosCartItemMap: {},
		stagedSeatRequests: {}
	};
	
	private ErrPage: Layout<typeof ErrorMessage>;
	private GALayout: Layout<typeof GAEventInfo, typeof GASeatForm>;
	private MembershipLayout: Layout<typeof MembershipInfo, typeof MembershipForm>;
	private PYOSSeatSelectionLayout: Layout<typeof PYOSSeatSelection, PYOSCart>;
	private PYOSSectionSelectionLayout: Layout<typeof SectionMap, typeof SectionLegend>;
	private VenueDetailsWithoutSeatingChartLayout: Layout<typeof VenueDetail>;
	private VenueDetailsWithSeatingChartLayout: Layout<typeof VenueSeatingChart, typeof VenueDetail>;
	
	constructor(props: AddToCartProps) {
		super(props);
		this.ErrPage = layout<typeof ErrorMessage>({Main: ErrorMessage}, props.intl.formatMessage({id: "msg_not_on_sale"}));
	}
	
	public componentDidMount() {
		const {cart, config, ensureCart, fetchItemFeeData, inventoryService, match} = this.props;
		// Get a fresh copy of the cart if it exists, create if necessary
		ensureCart(true);

		// Fetch the event descriptor into component state
		const instanceId = match.params.eventInstanceId;
		const eventDescriptor = inventoryService.fetchEventDescriptor(instanceId);
		if (!!eventDescriptor) {
			this.setState({instanceId, eventDescriptor});
			this.createLayoutComponents(eventDescriptor);
		}

		// fetch item fee descriptors if necessary
		if(config.includeFeesInPrice && !!cart.cartId) {
			fetchItemFeeData(cart.cartId);
		}
	}
	
	public componentDidUpdate(prevProps: AddToCartProps, prevState: AddToCartState) {
		// If the eventDescriptorCache property has been updated or a different EI has been selected
		const {analytics, cart, config, ensureCart, eventDescriptorCache, fetchItemFeeData, inventoryService, match, pageView, pendingSeatRequests} = this.props;
		if ((eventDescriptorCache.lastUpdated !== prevProps.eventDescriptorCache.lastUpdated) ||
			(match.params.eventInstanceId !== prevProps.match.params.eventInstanceId)) {
			if (!!match.params.eventInstanceId) {
				// Get a fresh copy of the ED
				const eventDescriptor = inventoryService.fetchEventDescriptor(match.params.eventInstanceId);
				if (!!eventDescriptor) {
					// Save the EventDescriptor to component state
					this.setState({instanceId: match.params.eventInstanceId, eventDescriptor, derivedState: null});
				
					// Dispatch the page view event if the URL has changed
					if (analytics.url !== window.location.href) {
						pageView(this.getTitle(eventDescriptor), window.location.href);
					}
					
					// If a different EventDescriptor has been loaded, recreate the layout components that depend on the ED
					if (!prevState.eventDescriptor || eventDescriptor.id !== prevState.eventDescriptor.id) {
						this.createLayoutComponents(eventDescriptor);
					}
				}
			}
		}

		// fetch item fee descriptors if necessary and only once we have a cart Id
		if(config.includeFeesInPrice && !prevProps.cart.cartId && !!cart.cartId) {
			fetchItemFeeData(cart.cartId);
		}
		
		// This little bit of code detects when all pending seat requests have completed. When this happens,
		// it will dispatch an asynchronous fetchOrder action. This is done to deal with the fact that we might get the
		// responses to cart item updates back in a different order in which they were requested. This could lead to the
		// order state potentially being stale. So, once things have settled down, we fetch the order again to make sure
		// we have the current state of the order, and its associated cart items.
		if (Object.keys(prevProps.pendingSeatRequests).length > 0 && !(Object.keys(pendingSeatRequests).length > 0)) {
			ensureCart(false, true);
		}
	}
	
	public render() {
		const {intl} = this.props;
		const {derivedState, eventDescriptor} = this.state;

		if (!derivedState || !eventDescriptor) {
			return null;
		}

		const {
			name: eiName,
			teName,
			saleStatus,
			noSaleMsg,
			isPasscodeEligible,
		} = eventDescriptor;

		if (!eventDescriptor.active) {
			const noSaleMsgGeneric = intl.formatMessage({id: "msg_item_not_available_for_purchase"});
			return <this.ErrPage message={noSaleMsgGeneric} />;
		} else if (saleStatus !== EventInstanceSaleStatus.OS && !isPasscodeEligible) {
			const noSaleMsgDefault = intl.formatMessage({id: "msg_event_not_available_for_purchase"}, { teName, eiName });
			return (!!noSaleMsg) ? <this.ErrPage htmlMessage={noSaleMsg}/> : <this.ErrPage message={noSaleMsgDefault}/>;
		}
		
		return (
			<Switch>
				<Route path={Paths.INSTANCE__VENUE} exact={true} render={this.renderVenueDetails}/>
				<Route path={Paths.INSTANCE__SECTION} exact={true} render={this.renderSeatMap} />
				<Route path={Paths.INSTANCE__SECTION_GROUP} exact={true} render={this.renderSeatMap} />
				<Route path={Paths.INSTANCE} exact={true} render={this.renderInstance} />
				{/*Redirect all non-matching routes*/}
				<Redirect to={Paths.INSTANCE} />
			</Switch>
		);
	}
	
	// Composes layout components that rely on EventDescriptor props
	private createLayoutComponents = (eventDescriptor: EventDescriptor) => {
		const {intl} = this.props;
		// TODO - PMGR-8595 Do we still need this "About this event" heading?
		this.GALayout = layout(
			{Main: GAEventInfo, Panel: GASeatForm},
			null,
			null,
			{primary: eventDescriptor.teName, secondary: eventDescriptor.name}
		);
		this.MembershipLayout = layout(
			{Main: MembershipInfo, Panel: MembershipForm},
			null,
			null,
			{primary: eventDescriptor.teName}
		);
		this.PYOSSeatSelectionLayout = layout(
			{Main: PYOSSeatSelection, Panel: PYOSCart},
			null,
			null,
			{primary: eventDescriptor.teName, secondary: eventDescriptor.name}
		);
		// TODO - PMGR-8595 - This layout is kind of messed up on small screens. Review with Emma
		this.PYOSSectionSelectionLayout = layout(
			{Main: SectionMap, Panel: SectionLegend},
			null,
			null,
			{primary: eventDescriptor.teName, secondary: eventDescriptor.name}
		);
		this.VenueDetailsWithoutSeatingChartLayout = layout(
			{Main: VenueDetail},
			null,
			null,
			{primary: !!eventDescriptor.venue ? eventDescriptor.venue.name: intl.formatMessage({id: "lbl_VenueDetails"})}
		);
		this.VenueDetailsWithSeatingChartLayout = layout(
			{Main: VenueSeatingChart, Panel: VenueDetail},
			null,
			null,
			{primary: !!eventDescriptor.venue ? eventDescriptor.venue.name: intl.formatMessage({id: "lbl_VenueDetails"})}
		);
	}
	
	// Render function for the Venue routes
	private renderVenueDetails = () => {
		const {eventDescriptor} = this.state;
		if (!eventDescriptor || !eventDescriptor.venue) {
			return null;
		}
		return !!eventDescriptor.venue.seatingChartPath
			? <LayoutRenderer layout={this.VenueDetailsWithSeatingChartLayout} venue={eventDescriptor.venue} eventInstanceId={eventDescriptor.id} />
			: <LayoutRenderer layout={this.VenueDetailsWithoutSeatingChartLayout} venue={eventDescriptor.venue} eventInstanceId={eventDescriptor.id} />;
	}
	
	// Render function for the routes that render the PYOS seat map
	private renderSeatMap = (routeProps: RouteProps) => {
		const {derivedState, eventDescriptor, pyosCartItemMap, stagedSeatRequests} = this.state;
		if (!derivedState || !eventDescriptor) {
			return null;
		}
		
		return (
			<LayoutRenderer 
				layout={this.PYOSSeatSelectionLayout}
				{...this.props}
				eventDescriptor={eventDescriptor}
				onSeatClick={this.handleSeatClick}
				allocMap={derivedState.allocMap}
				pyosCartItemMap={pyosCartItemMap}
				stagedSeatRequests={stagedSeatRequests}
				deleteStagedSeat={this.deleteStagedSeat}
				updateStagedSeatPriceLevel={this.updateStagedSeatPriceLevel}
				insertStagedSeats={this.insertStagedSeats}
				{...routeProps} />
		);
	}
	
	// Render function for "instance" routes
	private renderInstance = (routeProps: RouteProps) => {
		const {eventDescriptor} = this.state;
		if (!eventDescriptor) {
			return null;
		}
		
		switch (eventDescriptor.teType) {
			case TicketableEventTypes.MEMBERSHIP:
				return <GAItemSelection {...this.props} eventDescriptor={eventDescriptor} layout={this.MembershipLayout} />;
			case TicketableEventTypes.TICKETS: {
				if (eventDescriptor.seatingType === SeatingTypes.GA) {
					return <GAItemSelection {...this.props} eventDescriptor={eventDescriptor} layout={this.GALayout} />;
				} else if (eventDescriptor.seatingType === SeatingTypes.PYOS) {
					return <LayoutRenderer layout={this.PYOSSectionSelectionLayout} {...this.props} eventDescriptor={eventDescriptor}/>;
				}
				break;
			}
			case TicketableEventTypes.SUBSCRIPTION: {
				const subscriptionEventDescriptor = eventDescriptor as SubscriptionEventDescriptor;
				if (subscriptionEventDescriptor.subscriptionType === SubscriptionTypes.FIXED && subscriptionEventDescriptor.fulfillmentVenueIds.length === 1 && subscriptionEventDescriptor.pyosSubAllocList.length > 0) {
					// If it is a Subscription that allows online fulfillment, and all performances are held within a single venue then render the section map
					return <LayoutRenderer layout={this.PYOSSectionSelectionLayout} {...this.props} eventDescriptor={subscriptionEventDescriptor} />;
				}
				// For all other cases, render the normal GA-style subscription selection experience
				return <Subscription {...this.props} eventDescriptor={subscriptionEventDescriptor} />;
			}
		}
		return null;
	}
	
	private getTitle = (eventDescriptor: EventDescriptor) => {
		const {intl} = this.props;
		return intl.formatMessage({id: "lbl_title_event_instance"}, {instanceName: `${eventDescriptor.teName} - ${eventDescriptor.name}`});
	}
	
	private handleSeatClick = (seat: SeatDescriptor) => {
		const {cart, deleteCartItems, intl, pendingSeatRequests, showModalAction} = this.props;
		const {derivedState, eventDescriptor, pyosCartItemMap, stagedSeatRequests} = this.state;
		
		if (!derivedState || !eventDescriptor) {
			return;
		}
		
		// Ignore clicks on "pending" seats
		if (seat.id in pendingSeatRequests) {
			return;
		}

		// If the clicked seat is currently staged, then simply un-stage it
		if(seat.id in stagedSeatRequests) {
			this.deleteStagedSeat(seat.id);
			return;
		}
		
		const ackMap: BasicStringKeyedMap<Acknowledgment> = derivedState.ackMap;
		const cartItem: CartItem | undefined = pyosCartItemMap[seat.id];
		
		// If the seat instance is available, then we're adding it to the cart
		if (seat.avail) {
			// If the seat requires acknowledgment, display the acknowledgment message in a modal dialog
			if (seat.id in ackMap && !ackMap[seat.id].acknowledged) {
				new Promise<void>((resolve, reject) => {
					showModalAction(new ModalProps(
						[
							// The callback on the "OK" button resolves the Promise
							new ButtonProps(ButtonColors.SECONDARY, intl.formatMessage({id: "lbl_button_Decline"}), true, () => reject()),
							new ButtonProps(ButtonColors.PRIMARY, intl.formatMessage({id: "lbl_button_OK"}), false, () => resolve())
						],
						seat.snAck,
						intl.formatMessage({id: "lbl_SeatWithName"}, {seatName: seat.name}),
						true,
						() => reject()
					));
				}).then(() => {
					// When the Promise is resolved, complete the process of adding the new CartItem
					this.addStagedSeat(seat);
					// And mark the seat as acknowledged
					ackMap[seat.id].acknowledged = true;
					derivedState.ackMap = ackMap;
					this.setState({derivedState});
				}).catch(() => {
					// do nothing on rejected promise
				});
				
			// No acknowledgment required, so add the new CartItem
			} else {
				this.addStagedSeat(seat);
			}
		// The seat instance is not available
		} else {
			// It may be because it is already in the cart. In this case, we should remove the cart item and free up the seat.
			if (!!cartItem) {
				const seatId = cartItem.seatId;
				deleteCartItems(cart.cartId, [cartItem], true);
				// Clear the acknowledgment too if needed
				if (!!seatId && seatId in ackMap) {
					ackMap[seatId].acknowledged = false;
					derivedState.ackMap = ackMap;
					this.setState({derivedState});
				}
			}
		}
	}
	
	private addStagedSeat = (seat: SeatDescriptor) => {
		const newCartItem = this.createRetailCartItemForSeat(seat);
		if(!!newCartItem) {
			this.setState((state) => {
				const stagedSeatRequests = {...state.stagedSeatRequests};
				stagedSeatRequests[newCartItem.seatId!] = newCartItem;
				return {stagedSeatRequests};
			});
		}
	}
	
	private deleteStagedSeat = (seatId: string) => {
		this.setState((state) => {
			const stagedSeatRequests = {...state.stagedSeatRequests};
			delete stagedSeatRequests[seatId];
			return {stagedSeatRequests};
		});
	}
	
	private updateStagedSeatPriceLevel = (seatId: string, priceLevelId: string, pwywPrice?: number) => {
		this.setState((state) => {
			const stagedSeatRequests = {...state.stagedSeatRequests};
			const seatRequest = stagedSeatRequests[seatId];
			if(!!seatRequest) {
				const priceLevel = InventoryService.getLevelsMappedById(this.state.eventDescriptor!)[priceLevelId];
				seatRequest.levelId = priceLevelId;
				this.setCartItemPricing(seatRequest, priceLevel, pwywPrice);
			}
			return {stagedSeatRequests}
		});
	}

	private insertStagedSeats = () => {
		const {eventDescriptor, stagedSeatRequests} = this.state;
		const {cart, insertCartItems, insertPYOSSubscriptionCartItems} = this.props;
		const cartItems = Object.values(stagedSeatRequests);
		if(!!cartItems && cartItems.length) {
			eventDescriptor!.teType === TicketableEventTypes.SUBSCRIPTION
				? insertPYOSSubscriptionCartItems(cart.cartId, cartItems, true).then(response => this.handleInsertStagedSeatsResponse(response))
				: insertCartItems(cart.cartId, cartItems, true).then(response => this.handleInsertStagedSeatsResponse(response));
		}
	}
	
	private handleInsertStagedSeatsResponse = (response: any) => {
		const {history, inventoryService} = this.props;
		const {eventDescriptor} = this.state;
		if(response.type === ActionTypes.API_SUCCESS) {
			// If the staged items are successfully inserted, navigate to the cart
			history.push(Paths.CART);
		} else if (response.type === ActionTypes.API_FAILURE) {
			// Otherwise, as long as this isn't just a validation error, clear the staged seats so the user can try again
			if (!response.errors.some((error: any) => error.message.msgId === 'msg_pwyw_minimum_not_met')) {
				this.setState({stagedSeatRequests: {}});
			}
			
			// and if the failure was due to a seat being unavailable, refresh the event descriptor so the user sees the most up to date seat availability
			if(response.errors.some((error: any) => error.reason === 'SEAT_UNAVAILABLE') && !!eventDescriptor) {
				inventoryService.expireCacheEntry(eventDescriptor.id);
				inventoryService.fetchEventDescriptor(eventDescriptor.id);
			}
		}
	}
	
	private createRetailCartItemForSeat = (seat: SeatDescriptor) => {
		const {cart} = this.props;
		const {derivedState, eventDescriptor} = this.state;
		
		// These should never be falsy at this point since we've already enforced the truthiness in the calling method.
		// But we need to convince TypeScript of the truthiness of derivedState and eventDescriptor.
		// Otherwise, we'd need to use the derivedState! and eventDescriptor! syntax everywhere.
		if (!derivedState || !eventDescriptor) {
			return;
		}
		
		// Figure out the price level to default the cart item to
		let priceLevel;
		if (eventDescriptor.teType === TicketableEventTypes.SUBSCRIPTION) {
			const subsEventDescriptor = eventDescriptor as SubscriptionEventDescriptor;
			const allocation: AllocationDescriptor = derivedState.allocMap[seat.taId];

			// Find the SPLLs that map to the selected Ticket Allocation
			const spllsForAllocation = subsEventDescriptor.subscriptionPriceLevelLinks.filter(spll => spll.allocationName === allocation.name);

			// Extract the unique "Subscription" price levels that these SPLLs map to.
			const subsPriceLevelMap: BasicStringKeyedMap<LevelDescriptor> = spllsForAllocation.reduce((prevSubsPriceLevelMap: {[index: string]: any}, spll) => {
				const subsPriceLevel = eventDescriptor.levelList.find(level => level.id === spll.subscriptionPriceLevelId);
				if (!!subsPriceLevel) {
					prevSubsPriceLevelMap[subsPriceLevel.id] = subsPriceLevel;
				}
				return prevSubsPriceLevelMap;
			}, {});

			const priceLevelIds: string[] = Object.keys(subsPriceLevelMap);
			// Identify the "default" price level
			// If there's only one, it's easy
			if (priceLevelIds.length === 1) {
				priceLevel = subsPriceLevelMap[priceLevelIds[0]];
			} else if (priceLevelIds.length > 1) {
				// Find the Subscription price level with the lowest sort order.
				// If sort order is not set, fall back to sorting by name. We'll use this as the default.
				const sortedSubsPriceLevels = priceLevelIds.map(id => subsPriceLevelMap[id]).sort((a, b) => {
					if (!!a.sortOrder && !!b.sortOrder) {
						if (a.sortOrder > b.sortOrder) { return 1; }
						if (a.sortOrder < b.sortOrder) { return -1; }
						return 0;
					}
					if (a.name > b.name) { return 1; }
					if (a.name < b.name) { return -1; }
					return 0;
				});
				priceLevel = sortedSubsPriceLevels[0];
			}
		} else {
			// This is a single ticket event, just get the default PL for the TA containing the seat
			priceLevel = InventoryService.getDefaultLevelByAllocationId(eventDescriptor, seat.taId);
		}
		
		if (!!priceLevel) {
			// Create a new cart item for the seat at the default price level
			const ci = new CartItem();
			ci.qty = 1;
			ci.seatId = seat.id;    // For PYOS Subs, the seat.id property here is actually the seat key (e.g., orch:B:1) rather than a seat instance id
			ci.levelId = priceLevel.id;
			ci.toId = cart.id;
			ci.eiId = eventDescriptor.id;
			ci.passcode = eventDescriptor.appliedPasscode;
			
			// These properties normally get set server side after the cart item has been inserted, but we need to populate them now so that the
			// item can be displayed correctly in the mini-cart while it's still in a staged status.
			ci.id = seat.id;	// this is just a stand-in Id (we need one immediately because it's used as a list key in the mini-cart)
			ci.levelName = priceLevel.name;
			ci.allocId = priceLevel.allocId;
			ci.seatAssign = createSeatAssignmentStringForSeat(seat, eventDescriptor);
			this.setCartItemPricing(ci, priceLevel);
			
			return ci;
		}
		return;
	}
	
	private setCartItemPricing = (cartItem: CartItem, priceLevel: LevelDescriptor, pwywPrice?: number) => {
		cartItem.unitPrice = pwywPrice ? pwywPrice : cartItem.unitPrice;
		cartItem.subtotal = pwywPrice ? pwywPrice : priceLevel.price;
		cartItem.itemTotal = pwywPrice ? getPWYWPriceWithFeesIncluded(pwywPrice, priceLevel) : getPriceWithFeesIncluded(priceLevel);
		cartItem.fees = priceLevel.fee;
		cartItem.fee2 = priceLevel.fee2;
		cartItem.fee3 = priceLevel.fee3;
		cartItem.fee4 = priceLevel.fee4;
		cartItem.fee5 = priceLevel.fee5;
	}
}

const mapStateToProps = (state: RootState): AddToCartMappedStateProps => {
	return {
		analytics: state.analytics,
		blockingActions: state.ptApp.blockingActions,
		config: state.ptApp.config,
		pendingItemIds: state.ptApp.pendingItemIds,
		pendingSeatRequests: state.ptApp.pendingSeatRequests,
		cart: state.cart,
		eventDescriptorCache: state.eventDescriptorCache,
		cartTimeRemaining: state.ptApp.cartTimeRemaining
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>): AddToCartMappedDispatchProps => {
	return {
		clearAllMessages: () => {
			dispatch(PublicTicketAppActions.clearAllMessages());
		},
		ensureCart: (createIfNecessary = true, nonBlocking = false): Promise<any> => {
			return dispatch(CartActions.ensureCart(createIfNecessary, nonBlocking));
		},
		insertCartItems: (cartId, cartItems, nonBlocking = false): Promise<any> => {
			return dispatch(ApiActions.insertCartItems(cartId, cartItems, null, nonBlocking));
		},
		insertPYOSSubscriptionCartItems: (cartId: string, subscriptionCartItems: CartItem[], nonBlocking: boolean = false): Promise<any> => {
			return dispatch(ApiActions.insertPYOSSubscriptionCartItems(cartId, subscriptionCartItems, null, nonBlocking));
		},
		insertSubscriptionCartItems: (cartId: string, subscriptionCartItems: CartItem[], selectedTicketPLsBySubscriptionPLMap: BasicStringKeyedMap<string[]> | null, nonBlocking: boolean = false): Promise<any> => {
			return dispatch(ApiActions.insertSubscriptionCartItems(cartId, subscriptionCartItems, selectedTicketPLsBySubscriptionPLMap, null, nonBlocking));
		},
		deleteCartItems: (cartId, cartItems, nonBlocking = false): Promise<any> => {
			return dispatch(ApiActions.deleteCartItems(cartId, cartItems, null, nonBlocking));
		},
		fetchEvents: () => {
			dispatch(ApiActions.fetchEvents());
		},
		fetchItemFeeData: (cartId: string) => {
			return dispatch(ApiActions.fetchItemFeeData(cartId));
		},
		updateCartItems: (cartId, cartItems, nonBlocking = false): Promise<any> => {
			return dispatch(ApiActions.updateCartItems(cartId, cartItems, null, nonBlocking));
		},
		showAlert: (alertOptions: AlertOptions): void => {
			dispatch(PublicTicketAppActions.showAlert(alertOptions));
		},
		showModalAction: (modalProps: ModalProps): void => {
			dispatch(PublicTicketAppActions.showModalAction(modalProps));
		},
		pageView: (title: string, url: string): void => {
			dispatch(AnalyticsActions.pageView(title, url));
		},
		validateCYOSubscriptionPriceLevels: (subscriptionPriceLevelIds: string[]) => {
			return dispatch(ApiActions.validateCYOSubscriptionPriceLevels(subscriptionPriceLevelIds));
		},
		validatePasscode: (passcode: string, eventInstanceId: string) => {
			return dispatch(ApiActions.validatePasscode(passcode,eventInstanceId));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectInventoryService(injectIntl(AddToCart)));
