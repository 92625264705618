import {SFC} from "react";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {CartItem} from "../../models/cart-item";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {RowDescriptor} from "../../models/event-descriptor/row-descriptor";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import Seat from "./seat";
import {SeatAttributes} from "./seat-attributes";

export interface RowProps {
	row: RowDescriptor;
	section: SectionDescriptor;
	eventDescriptor: EventDescriptor;
	allocMap: BasicStringKeyedMap<AllocationDescriptor>;
	pyosCartItemMap: BasicStringKeyedMap<CartItem>;
	pendingSeatRequests: BasicStringKeyedMap<string>;
	stagedSeatRequests?: BasicStringKeyedMap<string>;
	seatAttributes: SeatAttributes;
	onSeatClick: (seat: SeatDescriptor) => void;
}

export const Row: SFC<RowProps> = (props) => {
	const {allocMap, eventDescriptor, pendingSeatRequests, pyosCartItemMap, stagedSeatRequests, row, section, ...other} = props;
	const seatsInRow = eventDescriptor.seatList.reduce((prev: JSX.Element[], seat: SeatDescriptor) => {
		if (seat.rId === row.id && seat.sId === section.id) {
			prev.push(
				<Seat
					key={"seat-" + seat.id}
					seat={seat}
					row={row}
					section={section}
					allocation={allocMap[seat.taId]}
					eventDescriptor={eventDescriptor}
					cartItem={pyosCartItemMap[seat.id]}
					selected={seat.id in pyosCartItemMap}
					pending={seat.id in pendingSeatRequests}
					staged={!!stagedSeatRequests && seat.id in stagedSeatRequests}
					{...other}
				/>
			);
		}
		return prev;
	}, []);

	return (
		<div style={{position: "absolute", top: row.top, left: row.left}}>
			<div className="row-label">{row.name}</div>
			{seatsInRow}
		</div>
	);
};
