import * as React from "react";
import {InjectedIntl, MessageValue} from "react-intl";
import {DeliveryMethods} from "../enums/delivery-methods";
import {TicketableEventTypes} from "../enums/ticketable-event-types";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {CartItem} from "../models/cart-item";
import {PublicTicketAppConfig} from "../models/public-ticket-app/public-ticket-app-config";

interface OrderSummaryProps {
	cart: Cart;
	config: PublicTicketAppConfig;
	intl: InjectedIntl;
}

const getTicketsGroupedByTE = (cartItems: CartItem[]): BasicStringKeyedMap<CartItem[]> => {
	return groupCartItemsByTE(cartItems.filter(cartItem => cartItem.ticketType === TicketableEventTypes.TICKETS && !cartItem.stoiId));
};

const getSubscriptionsGroupedByTE = (cartItems: CartItem[]): BasicStringKeyedMap<CartItem[]> => {
	return groupCartItemsByTE(cartItems.filter(cartItem => cartItem.ticketType === TicketableEventTypes.SUBSCRIPTION));
};

const getMembershipsGroupedByTE = (cartItems: CartItem[]): BasicStringKeyedMap<CartItem[]> => {
	return groupCartItemsByTE(cartItems.filter(cartItem => cartItem.ticketType === TicketableEventTypes.MEMBERSHIP));
};

/**
 * Groups the passed in array of CartItems objects by their teId property
 * @param cartItems an array of CartItem objects
 */
const groupCartItemsByTE = (cartItems: CartItem[]): BasicStringKeyedMap<CartItem[]> => {
	return cartItems.reduce((accumulator: BasicStringKeyedMap<CartItem[]>, cartItem: CartItem) => {
		let cartItemsForTE: CartItem[] = accumulator[cartItem.teId];
		if (!cartItemsForTE) {
			cartItemsForTE = [];
			accumulator[cartItem.teId] = cartItemsForTE;
}
		cartItemsForTE.push(cartItem);
		return accumulator;
	}, {});
};

const createTESummarizedItem = (
	cartItemsGroupedByTE :BasicStringKeyedMap<CartItem[]>,
	intl: InjectedIntl,
	messageId: string,
	teType: TicketableEventTypes = TicketableEventTypes.TICKETS) => {
	
	return Object.keys(cartItemsGroupedByTE).map((teId: string) => {
		const cartItemsForTE = cartItemsGroupedByTE[teId];
		const options: {[key: string]: MessageValue} = {qty: cartItemsForTE.reduce((prevQty: number, cartItem: CartItem) => prevQty + cartItem.qty, 0)};
		if (teType === TicketableEventTypes.SUBSCRIPTION) {
			options.subscription = intl.formatMessage({id: 'pmgr_term_Subscription'}).toLowerCase();
			options.subscriptions = intl.formatMessage({id: 'pmgr_term_Subscriptions'}).toLowerCase();
		} else if (teType === TicketableEventTypes.MEMBERSHIP) {
			options.membership = intl.formatMessage({id: 'pmgr_term_Membership'}).toLowerCase();
			options.memberships = intl.formatMessage({id: 'pmgr_term_Memberships'}).toLowerCase();
		}
		const {teName} = cartItemsForTE[0];
		return (
			<div
				key={teId}
				className="d-flex justify-content-between flex-column flex-md-row mb-md-1">
				<span>{teName}</span>
				<span>{intl.formatMessage({id: messageId}, options)}</span>
			</div>
		);
	});
};

export const OrderSummary: React.FunctionComponent<OrderSummaryProps> = (props) => {
	const {cart, config, intl} = props;
	const ticketsGroupedByTE: BasicStringKeyedMap<CartItem[]> = getTicketsGroupedByTE(cart.cartItems);
	const subscriptionsGroupedByTE: BasicStringKeyedMap<CartItem[]> = getSubscriptionsGroupedByTE(cart.cartItems);
	const membershipsGroupedByTE: BasicStringKeyedMap<CartItem[]> = getMembershipsGroupedByTE(cart.cartItems);

	const feeLabel = config.showSalesTax
		? intl.formatMessage({id: "lbl_FeesAndTaxes"})
		: intl.formatMessage({id: "lbl_Fees"});

	return (
		<div>
			{/* Buyer info */}
			<div className="mb-2">
				<p className="m-0"><b>{intl.formatMessage({id: "lbl_BuyerInformation"})}</b></p>
				<p className="m-0">{cart.firstName + " " + cart.lastName}</p>
				<p className="m-0">{intl.formatMessage({id: "msg_address_line_1"}, {streetAddress: cart.address})}</p>
				<p className="m-0">{intl.formatMessage({id: "msg_address_line_2"}, {city: cart.city, state: cart.state, postalCode: cart.postalCode})}</p>
				<p className="m-0">{cart.phone}</p>
				<p className="m-0">{cart.email}</p>
			</div>
			
			
			{/* Delivery */}
			<div className="mb-2 pb-1 pt-2 border-top">
				<p className="m-0"><b>{intl.formatMessage({id: "lbl_DeliveryInformation"})}</b></p>
				<p className="m-0">{intl.formatMessage({id: "lbl_DeliveryMethod"}, {delMethod: cart.delMethod})}</p>
				{cart.delMethod === DeliveryMethods.SHIP && (
					<>
						<p className="m-0">{intl.formatMessage({id: "lbl_ShippingAddress"})}</p>
						<p className="m-0">{cart.shippingFirstName + " " + cart.shippingLastName}</p>
						<p className="m-0">{intl.formatMessage({id: "msg_address_line_1"}, {streetAddress: cart.shippingAddress})}</p>
						<p className="m-0">{intl.formatMessage({id: "msg_address_line_2"}, {city: cart.shippingCity, state: cart.shippingState, postalCode: cart.shippingPostalCode})}</p>
					</>
				)}
			</div>
			
			{/* Items in order */}
			<div className="mb-2 pb-1 pt-2 border-top">
			<p className="m-0"><b>{intl.formatMessage({id: "lbl_Items"})}</b></p>

			{createTESummarizedItem(ticketsGroupedByTE, intl, "msg_ticket_with_qty")}
			{createTESummarizedItem(subscriptionsGroupedByTE, intl, "msg_subscription_with_qty", TicketableEventTypes.SUBSCRIPTION)}
			{createTESummarizedItem(membershipsGroupedByTE, intl, "msg_membership_with_qty", TicketableEventTypes.MEMBERSHIP)}

		</div>

			{/* Add-ons, fees, and total price */}
			<div className="mb-2 pb-1 pt-2 border-top">
				<div className="d-flex justify-content-between">
					<span>{intl.formatMessage({id: "lbl_ItemTotal"})}</span>
					<span>
						{intl.formatNumber((cart.itemSubTotal || 0), {style: "currency", currency: config.currencyCode})}
					</span>
				</div>
				
				{!!cart.donationAmt && cart.donationAmt > 0 && (
					<div className="d-flex justify-content-between">
						<span>{intl.formatMessage({id: "lbl_Donation"})}</span>
						<span>
							{intl.formatNumber((cart.donationAmt), {style: "currency", currency: config.currencyCode})}
						</span>
					</div>
				)}

				<div className="d-flex justify-content-between">
					<span>{feeLabel}</span>
					<span>
						{intl.formatNumber((cart.itemFeeTotal || 0) + (cart.orderFeeTotal || 0) + (cart.salesTaxTotal || 0), {style: "currency", currency: config.currencyCode})}
					</span>
				</div>
				
				<div className="d-flex justify-content-between">
					<span>{intl.formatMessage({id: "lbl_Total"})}</span>
					<span>
						{intl.formatNumber((cart.orderTotal || 0), {style: "currency", currency: config.currencyCode})}
					</span>
				</div>
			</div>
		</div>
	);
};
