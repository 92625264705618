import * as React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {InjectedIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Table} from "reactstrap";
import {Paths} from "../../enums/paths";
import {PortalDonation} from "../../models/portal/portal-donation";
import { MainContentHeader } from "../main-content-header";
import {Message} from "../message";

interface PortalDonationListProps  {
	currencyCode: string;
	donations: PortalDonation[];
	intl: InjectedIntl;
	pageView: (title: string, url: string) => void;
}

/**
 * Component that renders portal donation history
 */
export const DonationList: React.FunctionComponent<PortalDonationListProps> = ({donations, currencyCode, intl, pageView}) => {

	pageView(intl.formatMessage({id: "lbl_title_portal_donations"}), window.location.href);

	const processedDonations = donations.filter(isProcessedDonation).sort(sortByDateDescending);
	const pendingDonations = donations.filter(isPendingDonation).sort(sortByDateAscending);
	const hasUpcomingDonations = pendingDonations.length > 0;
	const hasCompletedDonations = processedDonations.length > 0;

	return (
		<div>
			<MainContentHeader intlId="lbl_Donations" />

			<h5><FormattedMessage id="lbl_Upcoming" /></h5>
			{
				hasUpcomingDonations
					? <DonationTable
						donations={pendingDonations}
						currencyCode={currencyCode} />
					: <Message intlId="msg_you_have_no_upcoming_donations" />
			}

			<h5><FormattedMessage id="lbl_Completed" /></h5>
			{
				hasCompletedDonations
					? <DonationTable
						donations={processedDonations}
						currencyCode={currencyCode} />
					: <Message intlId="msg_you_have_no_completed_donations" />
			}
		</div>
	);
};

///
/// Private functions
///

/**
 * Returns true if donation has a payment complete status
 *
 * @param donation the donation to check
 * @returns true if considered processed, otherwise false
 */
const isProcessedDonation = (donation: PortalDonation) => {
	return donation.isClosed && donation.isWon;
};

/**
 * Returns true if the donation has a any unprocessed status.
 * Any donation that is not processed and is not a refund is considered unprocessed.
 *
 * @param donation the donation to check
 * @returns true if considered unprocessed, otherwise false
 */
const isPendingDonation = (donation: PortalDonation) => {
	return !donation.isClosed && !donation.isWon;
};

/**
 * Compare function used to sort list of portal donations by the close date in ascending order.
 *
 * @param donation the donation to check
 * @param nextDonation the donation to check
 */
const sortByDateAscending = (donation: PortalDonation, nextDonation: PortalDonation) => {
	return donation.closeDateEpoch - nextDonation.closeDateEpoch;
};

/**
 * Compare function used to sort list of portal donations by the close date in descending order.
 *
 * @param donation the donation to check
 * @param nextDonation the donation to check
 */
const sortByDateDescending = (donation: PortalDonation, nextDonation: PortalDonation) => {
	// reverse result since descending is the reverse of ascending
	return -1 * sortByDateAscending(donation, nextDonation);
};

///
/// Private sub components
///

interface DonationTableProps {
	currencyCode: string;
	donations: PortalDonation[];
}

/**
 * Component that renders table representation of the provided donation list
 */
const DonationTable: React.FunctionComponent<DonationTableProps> = ({donations, currencyCode}) => {
	const donationRows = donations.map(donationOrder => {
		const {amount, closeDate, id, name, ticketOrderId, ticketOrderName} = donationOrder;
		const orderDetailPath = Paths.PORTAL__ORDER.replace(':ticketOrderId', ticketOrderId);
		const hasAssociatedOrder = !!ticketOrderId;
		return (
			<tr key={id}>
				<td>{closeDate}</td>
				<td>
					<FormattedNumber
						value={amount || 0}
						style="currency"
						currency={currencyCode}
						currencyDisplay="symbol"/>
				</td>
				<td>
					<span>{name}</span>
					{hasAssociatedOrder && (
						<Link to={orderDetailPath} className="ml-2 badge badge-info">
							{ticketOrderName}
						</Link>
					)}
				</td>
			</tr>
		);
	});

	return (
		<Table striped={true} bordered={true} responsive={true}>
			<thead>
				<tr>
					<th><FormattedMessage id="lbl_Date" /></th>
					<th><FormattedMessage id="lbl_Amount" /></th>
					<th><FormattedMessage id="lbl_Name" /></th>
				</tr>
			</thead>
			<tbody>
				{donationRows}
			</tbody>
		</Table>
	);
};
