import {AnyAction} from "redux";
import {ActionTypes} from "../enums/action-types";
import {ApiActionNames} from "../enums/api-action-names";
import {isDraft, isPartiallyPaid} from "../helpers/utilities";
import {Cart} from "../models/cart";

// An array of Cart props whose values might be changed by various Cart operations
const calculatedOrderProps = [
	"orderStatus",
	"subtotal",
	"fees",
	"deliveryFee",
	"fee3",
	"fee4",
	"fee5",
	"salesTaxTotal",
	"orderTotal",
	"ccType",
	"ccLastFour",
	"errMsg",
	"ptId",
	"authCode",
	"amtPaid",
	"amtDue",
	"lastPaymentAmount",
	"ltId",
	"acctName",
	"qualMethod",
	"entryMethod",
	"modstamp",
	"orderFeeTotal",
	"itemSubTotal",
	"itemFeeTotal",
	"paymentAmount",
	"amtTendered",
	"partiallyPaid",
	"cartItems",
	"validated",
	"validationErrors",
	"showCheckNumber",
	"orderEntitlements",
	"badges",
	"emailed",
	"printed",
	"attended",
	"minPmt",
	"deliveryMethods",
	"orderType",
	"cartExpiration"
];

export function cart(state = new Cart(), action: AnyAction) {
	switch (action.type) {
		case ActionTypes.API_SUCCESS:
			switch (action.actionName) {
				case ApiActionNames.ENSURE_CART:
					// The ensure cart operation returns the cart as its payload.
					// If it returns null, set cart to an empty object.
					return action.data || new Cart();
				case ApiActionNames.UPDATE_CART:
				case ApiActionNames.INSERT_CART_ITEMS:
				case ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS:
				case ApiActionNames.INSERT_SUBSCRIPTION_CART_ITEMS:
				case ApiActionNames.INSERT_FULFILLMENT_ITEMS:
				case ApiActionNames.DELETE_CART_ITEMS:
				case ApiActionNames.UPDATE_CART_ITEMS:
					if (isDraft(action.data)) {
						// These actions should selectively apply only those fields that might have changed server-side due to recalculation
						return Object.assign({}, state, getFilteredProps(action.data));
					}
					return state;
				case ApiActionNames.APPLY_DISCOUNT:
					// The response to this action has a different shape than the ones above. It has both a "cart"
					// property, and an integer representing the number of items to which the discount was applied.
					// We need to extract the cart property and apply it to the store.
					if (isDraft(action.data.cart)) {
						return Object.assign({}, state, getFilteredProps(action.data.cart));
					}
					return state;
				case ApiActionNames.SUBMIT_CART:
					// The SUBMIT_CART action returns a result object which has a cart property
					if (isDraft(action.data.cart) || isPartiallyPaid(action.data.cart)) {
						return Object.assign({}, state, action.data.cart);
					}
					return state;
				case ApiActionNames.FETCH_GIFT_CARD_BALANCE:
					return {...state, gcHash: action.data.gcHash, gcBalance: action.data.gcBalance};
				default:
					return state;
			}
		case ActionTypes.CART_SAVE_PROPS: {
			if (isDraft(state) || isPartiallyPaid(state)) {
				return {...state, ...action.props};
			}
			return state;
			
		}
		case ActionTypes.CART_CLEAR: {
			if (isDraft(state) || isPartiallyPaid(state)) {
				return new Cart();
			}
			return state;
		}
		default:
			return state;
	}
}

export function getFilteredProps(c: Cart) {
	return calculatedOrderProps.reduce((prevFilteredProps: {[index:string]: any}, propName: string) => {
		prevFilteredProps[propName] = c[propName];
		return prevFilteredProps;
	}, {});
}

