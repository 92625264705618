import * as _ from "lodash";
import {AnyAction} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {ApiActions} from "../actions/api-actions";
import {ActionTypes} from "../enums/action-types";
import {SeatingTypes} from "../enums/seating-types";
import {TicketableEventTypes} from "../enums/ticketable-event-types";
import {BasicStringKeyedMap} from "../models/basic-map";
import {CartItem} from "../models/cart-item";
import {AllocationDescriptor} from "../models/event-descriptor/allocation-descriptor";
import {DiscountDescriptor} from "../models/event-descriptor/discount-descriptor";
import {EventDescriptor} from "../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../models/event-descriptor/level-descriptor";
import {RowDescriptor} from "../models/event-descriptor/row-descriptor";
import {SeatDescriptor} from "../models/event-descriptor/seat-descriptor";
import {SectionDescriptor} from "../models/event-descriptor/section-descriptor";
import {SubscriptionEventDescriptor} from "../models/event-descriptor/subscription-event-descriptor";
import {RootState} from "../reducers";

// The value passed to the "setTimeout" function called to do a delayed refresh of the event descriptor
export const ED_REFRESH_INTERVAL = 60000;

// The maximum age, in msec, a cached event descriptor will be allowed to reach before it is re-fetched from the server.
export const ED_MAX_AGE = 30000;

// If an ED was last accessed more than this number of msec ago, the timed refresh operation will delete the ED from the cache.
export const ED_ACCESS_WINDOW = 300000;

export class InventoryService {
	// Returns the Price Level objects, mapped by their Id
	public static getLevelsMappedById = (eventDescriptor : EventDescriptor): BasicStringKeyedMap<LevelDescriptor> => {
		// Return the levels mapped by their Id
		return eventDescriptor.levelList.reduce((prevLevelsMappedById: {[key: string]: LevelDescriptor}, level: LevelDescriptor) => {
			prevLevelsMappedById[level.id] = level;
			return prevLevelsMappedById;
		}, {});
	}
	// Returns the first "active" price level for the specified TA.Id
	public static getDefaultLevelByAllocationId = (eventDescriptor: EventDescriptor, allocId: string): LevelDescriptor | undefined => {
		// Return the first active PL within the specified TA
		return eventDescriptor.levelList.find((level: LevelDescriptor) => {
			return ((level.allocId === allocId) && level.active);
		});
	}
	// Returns the default Price Levels mapped by their Ticket Allocation Id
	public static getDefaultLevelsMappedByAllocationId = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<LevelDescriptor> => {
		return eventDescriptor.allocList.reduce((prevLevelsMappedByAllocId: {[key: string]: LevelDescriptor}, alloc: AllocationDescriptor) => {
			const levelDescriptor = InventoryService.getDefaultLevelByAllocationId(eventDescriptor, alloc.id);
			if (!!levelDescriptor) {
				prevLevelsMappedByAllocId[alloc.id] = levelDescriptor;
			}
			return prevLevelsMappedByAllocId;
		}, {});
	}
	// Returns the LevelDescriptor for the specified price level if active
	public static getLevelById = (eventDescriptor: EventDescriptor, levelId: string): LevelDescriptor | undefined => {
		// Return the the active level for the specified price level
		return eventDescriptor.levelList.find((level: LevelDescriptor) => {
			return ((level.id === levelId) && level.active);
		});
	}
	// Returns the list of "active" price levels for the specified allocation
	public static getLevelsByAllocationId = (eventDescriptor: EventDescriptor, allocId: string): LevelDescriptor[] => {
		// Return the the active levels for the specified allocation id
		return eventDescriptor.levelList.filter((level: LevelDescriptor) => {
			return ((level.allocId === allocId) && level.active);
		});
	}
	// Returns a map whose key is allocation id and its value is a list of price levels associated with that allocation id
	public static getLevelsMappedByAllocationId = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<LevelDescriptor[]> => {
		return eventDescriptor.levelList.reduce((prevLevelsMappedByAllocationId: BasicStringKeyedMap<LevelDescriptor[]>, level: LevelDescriptor) => {
			let levelList = prevLevelsMappedByAllocationId[level.allocId];
			if (!levelList) {
				levelList = Array<LevelDescriptor>();
				prevLevelsMappedByAllocationId[level.allocId] = levelList;
			}
			levelList.push(level);
			return prevLevelsMappedByAllocationId;
		}, {});
	}
	// Returns the Ticket Allocation objects, mapped by their Id
	public static getAllocationsMappedById = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<AllocationDescriptor> => {
		// Return the allocations for the specified event instance, mapped by their id
		return eventDescriptor.allocList.reduce((prevAllocsMappedById: {[key: string]: AllocationDescriptor}, alloc: AllocationDescriptor) => {
			prevAllocsMappedById[alloc.id] = alloc;
			return prevAllocsMappedById;
		}, {});
	}
	// Returns a list of Ticket Allocations in the specified Section
	public static getAllocationsBySectionId = (eventDescriptor: EventDescriptor, sectionId: string): AllocationDescriptor[] => {
		// Get the Seat Instances in the specified Section
		const seatsInSection = InventoryService.getSeatInstancesBySectionId(eventDescriptor, sectionId);
		if (!seatsInSection) {
			return [];
		}
		
		const allocsMappedById = InventoryService.getAllocationsMappedById(eventDescriptor);
		if (!allocsMappedById) {
			return [];
		}
		
		// Get the unique list of TA Ids
		const taIds = _.uniq(seatsInSection.map((seatInstance: SeatDescriptor) => seatInstance.taId));
		// Now return an array containing those TAs
		return taIds.reduce((allocsInSection: AllocationDescriptor[], taId: string) => {
			// This inserts the Ticket Allocation into the array in order, based on the "sortOrder" property
			allocsInSection.splice(_.sortedIndexBy(allocsInSection, allocsMappedById[taId], "sortOrder"), 0, allocsMappedById[taId]);
			return allocsInSection;
		}, []);
	}
	// Returns a list of Ticket Allocations in the specified Section Group
	public static getAllocationsByGroupId = (eventDescriptor: EventDescriptor, groupId: string): AllocationDescriptor[] => {
		// Get the Seat Instances in the specified Section Group
		const seatsInGroup = InventoryService.getSeatInstancesByGroupId(eventDescriptor, groupId);
		if (!seatsInGroup) {
			return [];
		}
		
		const allocsMappedById = InventoryService.getAllocationsMappedById(eventDescriptor);
		if (!allocsMappedById) {
			return [];
		}
		
		// Get the unique list of TA Ids
		const taIds = _.uniq(seatsInGroup.map((seatInstance: SeatDescriptor) => seatInstance.taId));
		
		// Now return an array containing those TAs, sorted on the sortOrder property
		return taIds.reduce((allocsInGroup: AllocationDescriptor[], taId: string) => {
			// This inserts the Ticket Allocation into the array in order, based on the "sortOrder" property
			allocsInGroup.splice(_.sortedIndexBy(allocsInGroup, allocsMappedById[taId], "sortOrder"), 0, allocsMappedById[taId]);
			return allocsInGroup;
		}, []);
	}
	// Returns a list of all Sections for the specified event instance that are "ungrouped" (they don't have a groupId reference)
	public static getUngroupedSections = (eventDescriptor: EventDescriptor): SectionDescriptor[] => {
		// Return those Section objects that don't reference a Section Group
		return eventDescriptor.sectionList.filter((section: SectionDescriptor) => {
			return !("groupId" in section);
		});
	}
	// Returns a list of all Section objects that are contained within the SectionGroup identified by the specified Id
	public static getSectionsByGroupId = (eventDescriptor: EventDescriptor, groupId: string): SectionDescriptor[] => {
		// Return those Section objects that reference the specified groupId
		return eventDescriptor.sectionList.filter((section: SectionDescriptor) => {
			return (section.groupId === groupId);
		});
	}
	// Returns the Section objects, mapped by their Id
	public static getSectionsMappedById = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<SectionDescriptor> => {
		return eventDescriptor.sectionList.reduce((sectionsMappedById: {[key: string]: SectionDescriptor}, section: SectionDescriptor) => {
			sectionsMappedById[section.id] = section;
			return sectionsMappedById;
		}, {});
	}
	// Returns the Rows in the Section identified by the specified ID
	public static getRowsBySectionId = (eventDescriptor: EventDescriptor, sectionId: string): RowDescriptor[] => {
		// Return those Row objects that reference the specified sectionId
		return eventDescriptor.rowList.filter((row: RowDescriptor) => {
			return (row.sectionId === sectionId);
		});
	}
	// Returns the Row objects, mapped by their Id
	public static getRowsMappedById = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<RowDescriptor> => {
		return eventDescriptor.rowList.reduce((rowsMappedById: {[key: string]: RowDescriptor}, row: RowDescriptor) => {
			rowsMappedById[row.id] = row;
			return rowsMappedById;
		}, {});
	}
	// Returns Seat Instances mapped by their ID
	public static getSeatInstancesMappedById = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<SeatDescriptor> => {
		// Return the seat instance for the specified event instance, mapped by their id
		return eventDescriptor.seatList.reduce((prevSeatsMappedById: {[key: string]: SeatDescriptor}, seatInst: SeatDescriptor) => {
			prevSeatsMappedById[seatInst.id] = seatInst;
			return prevSeatsMappedById;
		}, {});
	}
	// Returns the Seat Instances in the Section Group identified by the specified ID
	public static getSeatInstancesByGroupId = (eventDescriptor: EventDescriptor, groupId: string): SeatDescriptor[] => {
		// Return the seat instances that reference the specified group id
		return eventDescriptor.seatList.filter((seatInst: SeatDescriptor) => {
			return (seatInst.gId === groupId);
		});
	}
	// Returns the count of "available" Seat Instances in the specified Section Group
	public static getAvailableQuantityByGroupId = (eventDescriptor: EventDescriptor, groupId: string): number => {
		return InventoryService.getSeatInstancesByGroupId(eventDescriptor, groupId).reduce((previous: number, seatInst: SeatDescriptor) => {
			let result = previous;
			if (seatInst.avail) {
				result += 1;
			}
			return result;
		}, 0);
	}
	// Returns the count of "selected" Seat Instances a given seatList
	public static getSelectedSeatQuantity = (seatList: SeatDescriptor[], selectedItems: {[key: string]: any}): number => {
		return seatList.reduce((previous: number, seatInst: SeatDescriptor) => {
			let result = previous;
			if (selectedItems[seatInst.id]) {
				result += 1;
			}
			return result;
		}, 0);
	}
	// Returns the count of "selected" Seat Instances in the specified Section Group
	public static getSelectedSeatQuantityByGroupId = (eventDescriptor: EventDescriptor, groupId: string, selectedItems: {[key: string]: any}): number => {
		return InventoryService.getSelectedSeatQuantity(InventoryService.getSeatInstancesByGroupId(eventDescriptor, groupId), selectedItems);
	}
		// Returns the count of "selected" Seat Instances in the specified Section
	public static getSelectedSeatQuantityBySectionId = (eventDescriptor: EventDescriptor, sectionId: string, selectedItems: {[key: string]: any}): number => {
		return InventoryService.getSelectedSeatQuantity(InventoryService.getSeatInstancesBySectionId(eventDescriptor, sectionId), selectedItems);
	}
	// Returns the Seat Instances in the Section identified by the specified ID
	public static getSeatInstancesBySectionId = (eventDescriptor: EventDescriptor, sectionId: string): SeatDescriptor[] => {
		// Return the seat instances that reference the specified section id
		return eventDescriptor.seatList.filter((seatInst: SeatDescriptor) => {
			return (seatInst.sId === sectionId);
		});
	}
	// Returns the count of "available" Seat Instances in the specified Section
	public static getAvailableQuantityBySectionId = (eventDescriptor: EventDescriptor, sectionId: string): number => {
		return InventoryService.getSeatInstancesBySectionId(eventDescriptor, sectionId).reduce((previous: number, seatInst: SeatDescriptor) => {
			let result = previous;
			if (seatInst.avail) {
				result += 1;
			}
			return result;
		}, 0);
	}
	// Returns the Seat Instances in the Row identified by the specified ID
	public static getSeatInstancesByRowId = (eventDescriptor: EventDescriptor, rowId: string): SeatDescriptor[] => {
		// Return the seat instances that reference the specified row id
		return eventDescriptor.seatList.filter((seatInst: SeatDescriptor) => {
			return (seatInst.rId === rowId);
		});
	}
	// Given the passed-in collection of Cart Items, this method returns those that are assigned to the current Event Instance
	public static getCartItemsForEventInstance = (eventDescriptor: EventDescriptor, cartItems: any[]): CartItem[] => {
		// Get the Ticket Price Levels for the current EI
		const levelsMappedById = InventoryService.getLevelsMappedById(eventDescriptor);
		// Return the cart items that reference price levels in the specified event instance
		return cartItems.filter((cartItem: any) => {
			return (cartItem.levelId && (cartItem.levelId in levelsMappedById));
		});
	}
	// Returns the Discount Object objects applicable to the specified event instance, mapped by their Id
	public static getDiscountsMappedById = (eventDescriptor: EventDescriptor): BasicStringKeyedMap<DiscountDescriptor> => {
		// Return the discount codes, mapped by their id
		return eventDescriptor.discountList.reduce((prevDiscountsById: {[key: string]: DiscountDescriptor}, discount: DiscountDescriptor) => {
			prevDiscountsById[discount.id] = discount;
			return prevDiscountsById;
		}, {});
	}
	// Returns an array of Discounts that are applicable to the specified Ticket Allocation
	public static getApplicableDiscountsForAllocation = (eventDescriptor: EventDescriptor, allocId: string): DiscountDescriptor[] => {
		const applicableDiscounts: DiscountDescriptor[] = [];
		// Return the discounts that are applicable to the specified allocation id
		eventDescriptor.discountList.forEach((discount: DiscountDescriptor) => {
			// exclude any that have a Member Usage Limit (PMGR-7405)
			if (!discount.muLimit && discount.allocIds.indexOf(allocId) > -1) {
				applicableDiscounts.push(discount);
			}
		});
		return applicableDiscounts;
	}
	// Returns an array of Discounts that are applicable to the specified Ticket Allocations
	// The returned array is de-duped, so that a Discount appears no more than once.
	public static getApplicableDiscountsForAllocations = (eventDescriptor: EventDescriptor, allocIds: string[]): DiscountDescriptor[] => {
		let applicableDiscounts: DiscountDescriptor[] = [];
		// Get the discounts that can be applied to any of the passed in alloc ids
		allocIds.forEach((allocId) => {
			applicableDiscounts = applicableDiscounts.concat(InventoryService.getApplicableDiscountsForAllocation(eventDescriptor, allocId));
		});
		return _.uniqBy(applicableDiscounts, "id");
	}
	
	// Returns true is we're dealing with a PYOS Event (Ticket or Sub).
	public static isPYOSEventDescriptor = (eventDescriptor: EventDescriptor) : boolean => {
		return (eventDescriptor.teType === TicketableEventTypes.TICKETS && eventDescriptor.seatingType === SeatingTypes.PYOS)
			|| (eventDescriptor.teType === TicketableEventTypes.SUBSCRIPTION 
				&& (eventDescriptor as SubscriptionEventDescriptor).fulfillmentVenueIds.length === 1
				&& (eventDescriptor as SubscriptionEventDescriptor).pyosSubAllocList.length > 0);
	}
	
	private store: any;
	private timerIds = {};
	
	constructor(theStore: any) {
		this.store = theStore;
	}
	
	// Returns the specified event descriptor from the cache, or null if it is not in the cache
	public getEventDescriptor(instanceId: string): EventDescriptor | null {
		const edCache = this.store.getState().eventDescriptorCache;
		return (instanceId in edCache.descriptors) ? edCache.descriptors[instanceId].descriptor : null;
	}

	/**
	 * This method will return the requested EventDescriptor for the specified EI from cache.
	 * It will also initiate an async action to fetch the event descriptor for the specified Event Instance.
	 * 
	 * @param instanceId the ID of the Event Instance whose EventDescriptor is to be fetched
	 * @param refresh if true, a timer will be started to periodically refresh the requested EventDescriptor
	 * @param cartId if cartId and a list of SBSL IDs are specified, the method will fetch the "fulfillment" EventDescriptor.
	 * In this scenario, instanceId may actually be a comma separated string representing multiple fulfillment Event Instances
	 * that will be "layered" to form a subscription fulfillment seat map.
	 * @param sbslIds see cartId
	 * @param modstamp the cart modstamp
	 */
	public fetchEventDescriptor = (instanceId: string, refresh = true, cartId?: string, sbslIds?: string[], modstamp: number | null = null): EventDescriptor | null => {
		// Get the event descriptor from the cache
		const cacheEntry = this.store.getState().eventDescriptorCache.descriptors[instanceId];
		const now = new Date().getTime();
		let eventDescriptor = null;
		if (cacheEntry) {
			eventDescriptor = cacheEntry.descriptor;
			// If the cached copy hasn't been updated recently, fetch it again
			if ((cacheEntry.lastUpdated + ED_MAX_AGE) < now) {
				this.store.dispatch(this.dedupedFetch(instanceId, cartId, sbslIds, modstamp));
			}
		} else {
			this.store.dispatch(this.dedupedFetch(instanceId, cartId, sbslIds, modstamp));
		}
		// Dispatch an action to indicated we've accessed the event descriptor
		this.store.dispatch({type: ActionTypes.ISVC_ED_ACCESSED, instanceId});
		
		// If "refresh" is true, start a timer to do a periodic refresh of the EventDescriptor.
		// Only start a timer if there isn't one already running for this EventDescriptor.
		if (refresh && !(instanceId in this.timerIds)) {
			this.timerIds[instanceId] = setTimeout(this.timedFetch, ED_REFRESH_INTERVAL, instanceId, cartId, sbslIds, modstamp);
		}
		
		// Return the event descriptor retrieved from the cache
		return eventDescriptor;
	}
	
	// Expires cache for a specific EventInstance
	public expireCacheEntry = (instanceId: string) => {
		const cacheEntry = this.store.getState().eventDescriptorCache.descriptors[instanceId];
		if (cacheEntry) {
			cacheEntry.lastUpdated -= ED_MAX_AGE;
		}
	}
	
	// Expires the entire EventDescriptor cache
	public expireCache = () => {
		Object.keys(this.store.getState().eventDescriptorCache.descriptors).forEach(instanceId => this.expireCacheEntry(instanceId));
	}

	// Deletes the EventDescriptor for the specified Event Instance from the EventDescriptor cache if it is present.
	// This can be called to force an immediate re-fetch from the server on the next call to fetchEventDescriptor.
	public deleteEventDescriptor = (instanceId: string) => {
		// Stop refresh timer for the specified instance
		if (instanceId in this.timerIds) {
			clearTimeout(this.timerIds[instanceId]);
		}
				
		// Dispatch an ISVC_DELETE_ED action to purge the entry from the cache
		this.store.dispatch({type: ActionTypes.ISVC_DELETE_ED, instanceId});
	};

	/**
	 *
	 * @param instanceId - This may be a single EI Id, or a comma separated string of multiple EI Ids when fetching a
	 * fulfillment Event Descriptor.
	 * @param cartId - The id of the cart that contains the SBSLs being fulfilled
	 * @param sbslIds - The ids of the SBSL's currently being fulfilled
	 * @param modstamp - The cart's last modification timestamp
	 * @returns ThunkAction<Promise<EventDescriptor> if successful, otherwise ThunkAction<Promise<any>
	 */
	private dedupedFetch = (instanceId: string, cartId?: string, sbslIds?: string[], modstamp: number | null = null): ThunkAction<Promise<any> | null, RootState, void, AnyAction> => {
		return (dispatch: ThunkDispatch<RootState, void, AnyAction>, getState: () => any) => {
			// This little bit of code de-dupes ED fetch requests.
			// It only dispatches the request if the specified EI is not already in the middle of being fetched.
			if (!(instanceId in getState().eventDescriptorCache.pendingFetches)) {
				return !!cartId && !!sbslIds && !!modstamp
					? dispatch(ApiActions.fetchFulfillmentEventDescriptor(cartId, modstamp, instanceId, sbslIds))
					: dispatch(ApiActions.fetchEventDescriptor(instanceId));
			}
			return null;
		};
	}

	// This does a fetch of the specified EventDescriptor (or FulfillmentEventDescriptor if a fullfillment allocation
	// name is specified), and then re-schedules itself. This also handles terminating the refresh timers and purging
	// the EDs that have not been accessed within ED_ACCESS_WINDOW milliseconds.
	private timedFetch = (instanceId: string, cartId?: string, sbslIds?: string[], modstamp: number | null = null) => {
		delete this.timerIds[instanceId];
		const descriptorEntry = this.store.getState().eventDescriptorCache.descriptors[instanceId];
		if (descriptorEntry && descriptorEntry.lastAccessed < ((new Date()).getTime() - ED_ACCESS_WINDOW)) {
			// Dispatch an ISVC_DELETE_ED action to purge the entry from the cache
			this.store.dispatch({type: ActionTypes.ISVC_DELETE_ED, instanceId});
		} else {
			// Do an asynch fetch, and re-schedule the refresh
			!!cartId && !!sbslIds && !!modstamp
				? this.store.dispatch(ApiActions.fetchFulfillmentEventDescriptor(cartId, modstamp, instanceId, sbslIds))
				: this.store.dispatch(ApiActions.fetchEventDescriptor(instanceId));
			
			this.timerIds[instanceId] = setTimeout(this.timedFetch, ED_REFRESH_INTERVAL, instanceId, cartId, sbslIds, modstamp);
		}
	}
}
