import {useCallback, useEffect, useState} from "react";
import {InjectedIntl} from "react-intl";
import {SCPLOptions} from "../models/public-ticket-app/scpl-options";
import {FieldGroup, FieldGroupTypes} from "./field-group";

interface StateFieldProps{
	id: string;
	name: string;
	label: string;
	value: string;
	onChange: (fieldName: string, fieldValue: string) => void;
	invalid: boolean;
	feedbackMessage?: string;
	required: boolean;
	disabled: boolean;
	isSCPLEnabled: boolean;
	stateCodes?: string[];
	selectedCountry?: string;
	intl: InjectedIntl;
	scplOptions?: SCPLOptions[];
}

export const StateField: React.FunctionComponent<StateFieldProps> = (props) => {
	const {
		id,
		name,
		label,
		value,
		onChange,
		invalid,
		feedbackMessage,
		required,
		disabled,
		isSCPLEnabled,
		stateCodes,
		selectedCountry,
		intl,
		scplOptions,
	} = props;

	const [stateOptions, setStateOptions] = useState<JSX.Element[]>([]);
	
	useEffect(() => {
		let stateValues: string[] = [];
		let stateList: JSX.Element[] = [];
		
		if (isSCPLEnabled && !!scplOptions) {
			// If state and country picklists are enabled, find the list of states for the currently selected country
			stateList = [<option key="no-state" value="">{intl.formatMessage({ id: "lbl_SelectNone" })}</option>];
			const country = scplOptions.find(scplOption => scplOption.country === selectedCountry);
			if(!!country){
				stateValues = country.states;
			}
		}
		else if (!!stateCodes  && (stateCodes.length > 0)) {
			// If SCPL is disabled, then use custom settings state list for the picklist if available
			stateList = [<option key="no-state" value="">{intl.formatMessage({ id: "lbl_SelectNone" })}</option>];
			stateValues = stateCodes;
		}
		
		// Build the state picklist if possible
		if(stateValues.length > 0) {
			stateValues.forEach((state) => {
				stateList.push(<option key={state} value={state}>{state}</option>)
			});
		}

		setStateOptions(stateList);
		
	}, [intl, isSCPLEnabled, scplOptions, selectedCountry, stateCodes]);
	
	// When SCPL is enabled, if the current state value is not valid for the selected country, then clear the state value
	useEffect(() => { 
		if(isSCPLEnabled && !!value && !!scplOptions) {
			const country = scplOptions.find(scplOption => scplOption.country === selectedCountry);
			if(!!country && !country.states.find(state => state === value)){
				onChange(name, '');	
			}
		}
	}, [isSCPLEnabled, name, onChange, scplOptions, selectedCountry, value]);

	const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		onChange(evt.target.name, evt.target.value);
	}, [onChange]);

	return (
		(!!stateOptions && stateOptions.length > 0 ? (
				<FieldGroup
					id={id}
					name={name}
					type={FieldGroupTypes.SELECT}
					label={label}
					value={value}
					selectionOptions={stateOptions}
					onChange={handleChange}
					invalid={invalid}
					feedbackMessage={feedbackMessage}
					required={required}
					disabled={disabled || stateOptions.length <= 1}
				/>
			) : (
				// Use text entry field if neither SCPL or the custom settings state list are available
				<FieldGroup
					id={id}
					name={name}
					type={FieldGroupTypes.TEXT}
					label={label}
					value={value}
					onChange={handleChange}
					invalid={invalid}
					feedbackMessage={feedbackMessage}
					required={required}
					disabled={disabled}
				/>
			)
		)
	)
}