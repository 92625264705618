import * as React from 'react';
import {ComponentType} from 'react';
import {InjectedIntlProps, injectIntl} from 'react-intl';
import {Button, Input, Label, Row, Col} from 'reactstrap';
import {getPriceString} from "../../helpers/localization";
import {getPriceWithFeesIncluded} from "../../helpers/utilities";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {AddToCartFeeDisplay} from "../add-to-cart/add-to-cart-fee-display";
import {LoadingIcon, RemoveItemIcon} from "../icons/icons";
import './ga-item-quantity-selector.css';

interface GAItemProps {
	currencyCode: string;
	deleteFromCart: (quantityInput: GAQuantityInput) => void;
	quantityInput: GAQuantityInput;
	handleQuantityChange: (quantityInput: GAQuantityInput) => void;
	includeFeesInPrice: boolean;
	showPricingDetails: boolean;
}

export interface GAQuantityInput {
	level: LevelDescriptor;
	cartQty: number; //this is the quantity if there are already items for this price level in the cart.
	inputQty: string; //this is the quantity the user can adjust
	isBusy: boolean;
	isValid: boolean;
	noteAck: string;
	eiId: string;
	pwywPrice?: number
}

/**
 * A price level based cart item quantity picker.
 */
export class GAItemQuantitySelector extends React.Component<GAItemProps & InjectedIntlProps> {
	
	private MIN_QUANTITY: number = 0;
	private MAX_QUANTITY: number = 100;
	
	public render() {
		const {currencyCode, quantityInput, includeFeesInPrice, intl, showPricingDetails} = this.props;
		
		if (!quantityInput) {
			return null;
		}
		
		let price;

		if (includeFeesInPrice) {
			price = getPriceWithFeesIncluded(quantityInput.level, quantityInput.level.pwyw ? quantityInput.pwywPrice : undefined);
		} else {
			price = quantityInput.level.pwyw ? quantityInput.pwywPrice : quantityInput.level.price;	
		}

		const formattedPrice = getPriceString(intl, currencyCode, {minPrice: price!});

		const minimumPrice = getPriceString(intl, currencyCode, {minPrice: quantityInput.level.price});
		
		return (
			<div className="ga-cart-item">
				<div className="border-bottom small">
					<Label for={quantityInput.level.id} className="m-0">
						{quantityInput.level.name.toUpperCase()}
					</Label>
				</div>
				
				{/* quantity display/picker and price */}
				<Row noGutters={true} className="py-2">
					{
						quantityInput.cartQty > 0 ? (
							<Col lg="3" md="12" xs="3" className="d-flex">
								<span className="mt-auto mb-auto">{intl.formatMessage({id: "msg_ticket_with_qty"}, {qty: quantityInput.cartQty})}</span>
							</Col>
						) : (
							<Col lg="3" md="12" xs="3" className="d-flex">
								<Input
									type="number"
									className="mt-auto mb-auto"
									aria-label={quantityInput.level.name + intl.formatMessage({id: "lbl_QuantitySuffix"})}
									placeholder={intl.formatMessage({id: "lbl_QuantityShortened"})}
									min={this.MIN_QUANTITY}
									max={this.MAX_QUANTITY}
									id={quantityInput.level.id}
									name={quantityInput.level.id}
									value={quantityInput.inputQty}
									onChange={this.handleQtyChange}
								/>
							</Col>
						)
					}
					<Col lg="8" md="11" xs="8" className="d-flex mt-1 mt-md-0">
						<div className="d-flex mt-auto mb-auto w-100">
							<span className="ml-1 mr-1 mt-auto mb-auto">@</span>{quantityInput.level.pwyw && quantityInput.cartQty < 1 ? 
								<Row noGutters={true} className="d-flex">
									<Col className="mt-auto mb-auto">
										<Input
											type="number"
											aria-label={quantityInput.level.name + intl.formatMessage({id: "lbl_PWYWPriceSuffix"})}
											min={quantityInput.level.price}
											id={quantityInput.level.id + '-pwyw-price'}
											name={quantityInput.level.id + '-pwyw-price'}
											value={quantityInput.pwywPrice === undefined ? '' : quantityInput.pwywPrice}
											onChange={this.handlePwywPriceChange}
										/>
									</Col>
									<Col className="ml-1 mt-auto mb-auto">
										<span className="mt-auto mb-auto">({intl.formatMessage({id: "lbl_MinimumAmount"})} {minimumPrice})</span>
									</Col>
								</Row>
							: formattedPrice}
						</div>
					</Col>

				{/* Remove Button / Loading indicator */}
				<Col md="1" className="d-flex">
					{
						quantityInput.isBusy ? (
							<Button color="link" className="p-0 mt-auto mb-auto">
								<LoadingIcon />
							</Button>
						) : quantityInput.cartQty > 0 ? (
							<Button color="link" className="p-0 mt-auto mb-auto" onClick={this.handleDeleteFromCart}>
								<RemoveItemIcon />
							</Button>
						) : null
					}
				</Col>
			</Row>
			{(includeFeesInPrice || quantityInput.level.pwyw) && <AddToCartFeeDisplay isGA={true} isOpen={showPricingDetails} priceLevel={quantityInput.level} intl={intl}/>}
		</div>
		);
	}

	private handlePwywPriceChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const quantityInput = {...this.props.quantityInput};

		quantityInput.pwywPrice = Number(evt.target.value);
		this.props.handleQuantityChange(quantityInput);
	}
	
	private handleQtyChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const quantityInput = {...this.props.quantityInput};

		quantityInput.inputQty = evt.target.value;

		this.props.handleQuantityChange(quantityInput);
	}
	
	private handleDeleteFromCart = () => {
		this.props.deleteFromCart(this.props.quantityInput);
	}
	
}

export const CartItemQuantityPickerWithInjectedIntl: ComponentType<GAItemProps> = injectIntl(GAItemQuantitySelector);