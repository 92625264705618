import * as React from "react";
import {FormattedMessage, InjectedIntlProps} from "react-intl";
import {FormGroup, Input, Label} from "reactstrap";
import {InventoryService} from "../../helpers/inventory-service";
import {getPriceString} from "../../helpers/localization";
import {getPriceWithFeesIncluded} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {AddToCartFeeDisplay} from "../add-to-cart/add-to-cart-fee-display";
import {GAQuantityInput} from "../ga-selection/ga-item-quantity-selector";

interface SingleQuantitySelectorProps extends InjectedIntlProps {
	currencyCode: string;
	deleteFromCart: (quantityInput: GAQuantityInput) => void;
	eventDescriptor: EventDescriptor;
	handleQuantityChange: (quantityInput: GAQuantityInput) => void;
	handleLevelChange?: (priceLevelId: string) => void;
	quantityInputs: BasicStringKeyedMap<GAQuantityInput>;
	includeFeesInPrice: boolean;
	showPricingDetails: boolean;
}

export class SingleQuantitySelector extends React.Component<SingleQuantitySelectorProps> {

	// since only one item can be selected at a time, any change should set the newly selected input to 1 and all others to 0
	public handleSelectionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const {quantityInputs, handleQuantityChange, handleLevelChange} = this.props;
		const selectedPriceLevelId = evt.target.value;

		if (handleLevelChange) {
			handleLevelChange(selectedPriceLevelId);
		}
		
		Object.keys(quantityInputs).forEach(priceLevelId => {
			const quantityInput = {...quantityInputs[priceLevelId]};
			quantityInput.inputQty = priceLevelId === selectedPriceLevelId ? '1' : '0';
			handleQuantityChange(quantityInput);
		});
	}

	public render() {
		const {currencyCode, eventDescriptor, includeFeesInPrice, intl, showPricingDetails} = this.props;
		const isItemValid = eventDescriptor.allocList.length === 1;
		if (!isItemValid) {
			return <p className="text-info"><FormattedMessage id="msg_item_not_available_for_purchase"/></p>;
		}
		const allocation = eventDescriptor.allocList[0];
		const priceLevels = InventoryService.getLevelsByAllocationId(eventDescriptor, allocation.id);
		if (priceLevels.length <= 0) {
			return <p className="text-info"><FormattedMessage id="msg_item_not_available_for_purchase"/></p>;
		}
		if (!allocation.hasRetQty) {
			return <p className="text-info"><FormattedMessage id="lbl_SoldOut"/></p>;
		}
		return (
			<div className="mb-3">
				{
					priceLevels.map((level: LevelDescriptor) => (
						<FormGroup check={true} key={level.id}>
							<Label check={true}>
								<Input aria-label={level.name + " " + intl.formatMessage({id: "lbl_PriceLevelLabel"})} type="radio" name="price-level" value={level.id} onChange={this.handleSelectionChange} />
								{level.name} - {getPriceString(intl, currencyCode, {
									minPrice: includeFeesInPrice && !level.pwyw ? getPriceWithFeesIncluded(level) : level.price})}{level.pwyw ? ' ' + intl.formatMessage({id: "lbl_MinimumAmount"}) : ''}
							</Label>
							{(includeFeesInPrice && showPricingDetails) && <AddToCartFeeDisplay isOpen={showPricingDetails} priceLevel={level} intl={intl} />}
						</FormGroup>
					))
				}
			</div>
		);
	}

}
