import {InjectedIntlProps} from "react-intl";
import {useSelector} from "react-redux";
import {Card, Collapse} from "reactstrap";
import {getTicketFees, ITEM_FEE_PROPS} from "../../helpers/utilities";
import {CartItem} from "../../models/cart-item";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {FeeDescriptor} from "../../models/fee-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {TicketItemRow} from "../ticket-order/ticket-item-row";
import {PriceInfo} from "../../helpers/localization";

interface FeeDisplayProps extends InjectedIntlProps{
	priceLevel?: LevelDescriptor;
	cartItem?: CartItem;
	isOpen: boolean;
	noBorder?: boolean;
	isGA?: boolean;
}

export const AddToCartFeeDisplay = (props: FeeDisplayProps) => {
	const {priceLevel, cartItem, intl, isOpen, noBorder, isGA} = props;
	
	const itemFeeData = useSelector((state: any): FeeDescriptor[] => state.ptApp.itemFeeData);
	const config = useSelector((state: any): PublicTicketAppConfig => state.ptApp.config);
	
	// If we have a cart item, use it, otherwise mock up one based on the price level
	let thisItem = new CartItem();
	if(!!cartItem) {
		thisItem = cartItem;
	} else if (!!priceLevel) {
		thisItem.subtotal = priceLevel.price;
		thisItem.fees = priceLevel.fee;
		thisItem.fee2 = priceLevel.fee2;
		thisItem.fee3 = priceLevel.fee3;
		thisItem.fee4 = priceLevel.fee4;
		thisItem.fee5 = priceLevel.fee5;
	} else {
		// weren't provided any info, can't display fees
		return null;
	}
	
	// Create price info for the item subtotal (only CYO subs will have a min and max price)
	const priceInfo:PriceInfo = !!priceLevel && priceLevel.minPrice != null && priceLevel.maxPrice != null
		? {minPrice: priceLevel.minPrice, maxPrice: priceLevel.maxPrice}
		: {minPrice: thisItem.subtotal || 0};

	// Only display fees if there's a non-zero price possible
	const fees = priceInfo.minPrice || priceInfo.maxPrice
		? getTicketFees(thisItem, ITEM_FEE_PROPS, itemFeeData, config, intl)
		: [];

	let appendedLabelIntlId:string;
	if (priceLevel?.pwyw) {
		// We exit the component early if this is a PWYW component with no fees.
		if (fees.length === 0) {
			return null;
		} else {
			if (isGA) {
				appendedLabelIntlId = "lbl_EachAppliedAtCheckout";
			} else {
				appendedLabelIntlId = "lbl_AppliedAtCheckout";
			}
		}
	}
	
	return (
		<Collapse isOpen={isOpen || priceLevel?.pwyw}>
			<Card className={`pts-ticket-item p-2 my-1${noBorder ? " border-0" : ""}`} >
				{!priceLevel?.pwyw && <TicketItemRow intlId="lbl_Price" priceInfo={priceInfo} currencyCode={config.currencyCode} intl={intl}/>}
				{fees.map((fee: FeeDescriptor) => {
					return <TicketItemRow key={fee.label} userDefinedLabel={fee.label} appendedLabelIntlId={appendedLabelIntlId} priceInfo={{minPrice: fee.value}} currencyCode={config.currencyCode} intl={intl}/>
				})}
			</Card>
		</Collapse>
	)
}