import sanitizeHtml from 'sanitize-html';
import {IOptions} from "sanitize-html";

// see https://www.npmjs.com/package/sanitize-html/v/1.20.0 for options
// We're adding a few extras to the default options.
const {allowedAttributes, allowedTags, allowedSchemesByTag, allowedSchemes} = sanitizeHtml.defaults;
export const defaultOptions: IOptions = {
	allowedTags: allowedTags.concat(['h1', 'h2', 'img', 'iframe']),
	allowedAttributes: {
		...allowedAttributes,
		img: ['src', 'width', 'height', 'alt'],
		iframe: ['frameborder', 'src', 'width', 'height', 'style']
	},
	allowedSchemesByTag: {
		...allowedSchemesByTag,
		a: [ ...allowedSchemes, 'tel']
	},
	allowedIframeHostnames: [
		'www.youtube.com',
		'player.vimeo.com',
		'open.spotify.com',
		'bandcamp.com',
		'w.soundcloud.com',
		'embed.music.apple.com'
	],
};

interface SanitizeContentProps {
	rawHtml: string;
	options?: {};
}
export const sanitizeContent = (props: SanitizeContentProps): string => {
	const options = props.options || defaultOptions;
	const sanitized = sanitizeHtml(props.rawHtml, options);
	return sanitized.trim();
};