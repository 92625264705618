import * as React from "react";
import {FormattedMessage, InjectedIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Button, Col, Collapse} from "reactstrap";
import Row from "reactstrap/lib/Row";
import {AnyAction} from "redux";
import {ActionTypes} from "../../enums/action-types";
import {Paths} from "../../enums/paths";
import {TicketableEventTypes} from "../../enums/ticketable-event-types";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PortalOrder} from "../../models/portal/portal-order";
import {PortalOrderDetails} from "../../models/portal/portal-order-details";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {DetailToggleButton} from "../detail-toggle-button";
import {MainContentHeader} from "../main-content-header";
import {TicketOrderWithIntl} from "../ticket-order/ticket-order";
import {PendingRenewalHeader} from "./pending-renewal-header";

interface PendingRenewalOrdersProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	config: PublicTicketAppConfig;
	doNotRenew: (ticketOrderId: string) => Promise<any>;
	fetchPendingRenewal: (orderId: string) => Promise<any>;
	fetchPortalOrders: () => Promise<any>;
	intl: InjectedIntl;
	pageView: (title: string, url: string) => void;
	pendingRenewal: PortalOrderDetails;
	pendingRenewals: PortalOrder[];
	showModalConfirmation: (body: React.ReactNode, confirmationCallback: () => any, intl: InjectedIntl) => void;
}
interface PendingRenewalOrdersState {
	expandedOrderId: string;
}

/**
 * A list of pending subscription renewal orders.
 * User can expand orders to see detail,
 * such as list of performances and seat assignments.
 */
export class PendingRenewalOrders extends React.Component<PendingRenewalOrdersProps, PendingRenewalOrdersState> {
	public state = {
		expandedOrderId: '',
	};

	public componentDidMount(): void {
		const {intl, pageView} = this.props;
		pageView(intl.formatMessage({id: "lbl_title_portal_pending_renewals"}, {subscription: intl.formatMessage({id: 'pmgr_term_Subscription'})}), window.location.href);
	}

	public render() {
		const {blockingActions, config, intl, pendingRenewal, pendingRenewals} = this.props;
		const {cart, itemFeeData, orderFeeData} = pendingRenewal;
		const {expandedOrderId} = this.state;
		const isBusy = Object.keys(blockingActions).length > 0;
		const hasPendingRenewals = pendingRenewals.length > 0;
		if (!hasPendingRenewals) {
			return null;
		}
		return (
			<div>
				<MainContentHeader intlId="lbl_SubscriptionRenewal" values={{subscription: intl.formatMessage({id: 'pmgr_term_Subscription'})}} />
				{pendingRenewals.map(pr => {
					const areDetailsVisible = pr.id === expandedOrderId;
					const subItems = pr.items.filter(item => item.teType === TicketableEventTypes.SUBSCRIPTION);
					return (
						<div key={pr.id} className="mb-5">
							<PendingRenewalHeader
								currencyCode={config.currencyCode}
								intl={intl}
								orderNumber={pr.name}
								packageCount={subItems.length}
								status={pr.status}
								total={pr.total}
							/>
							<Row className="mb-2">
								<Col>
									<DetailToggleButton
										detailsVisible={areDetailsVisible}
										onClick={() => this.toggleDetails(pr.id)}
									/>
								</Col>
								<Col className="text-right">
									<Button size="sm" color="link" onClick={() => this.handleDoNotRenew(pr.id)} disabled={isBusy}>
										<span className="text-danger">
											<FormattedMessage id="lbl_button_CancelRenewal" />
										</span>
									</Button>
									<Link to={Paths.PORTAL__PENDING_RENEWAL__CONTACT_REQUEST.replace(":ticketOrderId", pr.id)} className="mr-2">
										<Button size="sm" color="link" disabled={isBusy}>
											<FormattedMessage id="lbl_button_RequestChanges" />
										</Button>
									</Link>
									<Link to={Paths.PORTAL__PENDING_RENEWAL.replace(":ticketOrderId", pr.id)} className="mr-0">
										<Button size="sm" color="primary" disabled={isBusy}>
											<FormattedMessage id="lbl_button_Renew" />
										</Button>
									</Link>
								</Col>
							</Row>
							<Collapse isOpen={areDetailsVisible}>
								<TicketOrderWithIntl
									allowEdit={false}
									blockingActions={blockingActions}
									cart={cart}
									config={config}
									detailsExpanded={true}
									itemFeeData={itemFeeData}
									orderFeeData={orderFeeData}
								/>
							</Collapse>
						</div>
					);
				})}
			</div>
		);
	}
	
	private toggleDetails = (ticketOrderId: string) => {
		const {fetchPendingRenewal} = this.props;
		const {expandedOrderId} = this.state;
		if (ticketOrderId === expandedOrderId) {
			// This hides the details
			this.setState({expandedOrderId: ""});
		} else {
			// Fetch the requested Pending Renewal
			fetchPendingRenewal(ticketOrderId).then((result: any) => {
				if (result.type === ActionTypes.API_SUCCESS) {
					this.setState({expandedOrderId: ticketOrderId});
				}
			});
		}
	}
	
	private handleDoNotRenew = (ticketOrderId: string) => {
		const {doNotRenew, fetchPortalOrders, intl, showModalConfirmation} = this.props;
		showModalConfirmation(
			intl.formatMessage({id: "msg_confirm_do_not_renew"}, {subscription: intl.formatMessage({id: "pmgr_term_Subscription"}).toLowerCase()}),
			() => {
				doNotRenew(ticketOrderId)
					.then((result) => {
						if (result.type === ActionTypes.API_SUCCESS) {
							fetchPortalOrders();
						}
					});
			},
			intl
		);
	}
}

