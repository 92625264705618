import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {HashRouter} from "react-router-dom";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import PublicTicketApp from "./containers/public-ticket-app";
import {InventoryService} from "./helpers/inventory-service";
import {analyticsMiddleware} from "./middleware/analytics-middleware";
import {logger} from "./middleware/logger";
import rootReducer from "./reducers";
import "./styles/structural.css";

// 'declare' keyword tells typescript that 'window' is defined elsewhere until we define window's types
declare let window: any;

const store = createStore(rootReducer, applyMiddleware(thunk, analyticsMiddleware, logger));

export const inventoryService = new InventoryService(store);

window.PublicTicketApp = window.PublicTicketApp || {};
window.PublicTicketApp.start = () => {
	const appDiv = document.getElementById("publicTicketApp");
	const stubDiv = document.getElementById("publicTicketStub");
	if (!appDiv || !stubDiv) {
		// Sigh... keep Typescript happy
		return;
	}
	
	ReactDOM.render(
		<Provider store={store}>
			<HashRouter>
				<PublicTicketApp/>
			</HashRouter>
		</Provider>,
		appDiv,
		() => {stubDiv.style.display = "none";}
	);
};
