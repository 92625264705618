import {useMemo, useState} from "react";
import {InjectedIntlProps} from "react-intl";
import {useSelector} from "react-redux";
import {Button, Card, Col, Row} from "reactstrap";
import {SeatingTypes} from "../../enums/seating-types";
import {getPriceString} from "../../helpers/localization";
import {getTotalItemFees} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {
	SubscriptionPriceLevelLinkDescriptor
} from "../../models/event-descriptor/subscription-price-level-link-descriptor";
import {MiniCartItem} from "../../models/mini-cart-item";
import {AddToCartFeeDisplay} from "../add-to-cart/add-to-cart-fee-display";
import {CountdownTimer} from "../countdown-timer";
import {DetailToggleButton} from "../detail-toggle-button";
import {RemoveItemIcon} from "../icons/icons";
import {MiniCartItemTotal} from "../mini-cart/mini-cart-item-total";
import {PanelNav} from "../panel-nav";
import {QuantityTypeSummary} from "../quantity-type-summary";

interface CYOMiniCartProps extends InjectedIntlProps{
	allocationName: string;
	currencyCode: string;
	disableNext: boolean;
	handleAddToCart: () => void;
	handleCancel: () => void;
	includeFeesInPrice: boolean;
	levelSummary: BasicStringKeyedMap<number>;
	numberOfSelections: number;
	onPerformanceSelection: (spll: SubscriptionPriceLevelLinkDescriptor) => void;
	priceLevelQuantityMap: BasicStringKeyedMap<string>;
	pyosAble: boolean;
	selectedCYOSpllMap: BasicStringKeyedMap<SubscriptionPriceLevelLinkDescriptor[]> | {};
	spllsMappedByInstanceAndEvent: BasicStringKeyedMap<BasicStringKeyedMap<SubscriptionPriceLevelLinkDescriptor[]>>;
	subscriptionPriceLevelsMappedById: BasicStringKeyedMap<LevelDescriptor>;
}

export const CYOMiniCart = (props: CYOMiniCartProps) => {
	const {
		allocationName,
		currencyCode,
		disableNext,
		handleAddToCart,
		handleCancel,
		includeFeesInPrice,
		levelSummary,
		numberOfSelections,
		onPerformanceSelection,
		priceLevelQuantityMap,
		pyosAble,
		selectedCYOSpllMap,
		spllsMappedByInstanceAndEvent,
		subscriptionPriceLevelsMappedById,
		intl
	} = props;
	const [showPricingDetails, setShowPricingDetails] = useState(true);
	
	const miniCartItems: BasicStringKeyedMap<MiniCartItem> = useMemo(() => {
		const _miniCartItems: BasicStringKeyedMap<MiniCartItem> = {};
		Object.keys(selectedCYOSpllMap).forEach((levelId: string) => {
			const subLevel = subscriptionPriceLevelsMappedById[levelId];
			_miniCartItems[levelId] = {
				levelName: subLevel.name,
				levelId: subLevel.id,
				allocName: subLevel.allocName,
				allocId: subLevel.allocId,
				// determine the current price of the item based on the current selections
				price: selectedCYOSpllMap[levelId].reduce((total: number, spll: SubscriptionPriceLevelLinkDescriptor) => total + spll.price, 0), 
				fees: includeFeesInPrice ? getTotalItemFees(subLevel) : 0,
				quantity: priceLevelQuantityMap[levelId]
			};
		});
		return _miniCartItems;
	},[includeFeesInPrice, priceLevelQuantityMap, selectedCYOSpllMap, subscriptionPriceLevelsMappedById]);

	const miniCartItemsArray = Object.keys(miniCartItems).map(itemId => miniCartItems[itemId]);
	const miniCartTotal: number = miniCartItemsArray.reduce((sum, {price, fees, quantity}) => {
		// fees aren't applicable if the sum of the selections for the price level is $0
		return includeFeesInPrice && price > 0 ? (price + fees) * Number(quantity) + sum : price * Number(quantity) + sum;
	}, 0);
	
	const nextButtonLabel = useMemo(() => {
		let label = intl.formatMessage({id: "pmgr_lbl_Buy"});
		if(pyosAble) {
			// determine if any of the options being selected from are PYOS event instances, and if so use the "Select Seats" label
			const includesPYOSOptions = Object.values(spllsMappedByInstanceAndEvent).some((spllMap) => {
				return Object.values(spllMap).some((splls) => splls.some((spll) => spll.seatingType === SeatingTypes.PYOS))
			});
			if(includesPYOSOptions) {
				label = intl.formatMessage({id: "lbl_SelectSeats"});
			}
		}
		return label;
	},[intl, pyosAble, spllsMappedByInstanceAndEvent]);

	const cartTimeRemaining = useSelector((state: any): number => state.ptApp.cartTimeRemaining);

	return (
		<div>
			<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
			<div className="mb-3">
				<QuantityTypeSummary priceLevelQuantityMap={levelSummary} allocationName={allocationName} intl={intl} />
			</div>
			<div className="mb-3">
				<h5>{intl.formatMessage({id: "lbl_SelectionSummary"})}:</h5>
				{Object.values(selectedCYOSpllMap)[0]?.map((spll: SubscriptionPriceLevelLinkDescriptor) => {
					return (
						<Card body key={spll.id} className="pts-ticket-item py-2 mb-2 rounded">
							<Row>
								<Col>
									<div className="font-weight-bold">
										{spll.ticketableEventName}
									</div>
									<div>
										{spll.eventInstanceName}
									</div>
								</Col>
								<Col xs="auto">
									{/*Simulate clicking on the performance tile, which since it's already selected, will unselect it*/}
									<Button color="link" onClick={() => onPerformanceSelection(spll)}>
										<RemoveItemIcon />
									</Button>
								</Col>
							</Row>
						</Card>
					)
				})}
				{ /* Placeholder cards*/ }
				{Array(numberOfSelections - Object.values(selectedCYOSpllMap)[0]?.length).fill(0).map((number, index) => {
					return (
						<Card body key={index} className="py-3 mb-2 rounded mini-cart-needs-selection">
							{intl.formatMessage({id: "lbl_SelectEvent"})}	
						</Card>
					)
				})}
			</div>
			
			<h5>{intl.formatMessage({id: "lbl_PricingSummary"})}:</h5>
			{miniCartItemsArray.map(item => {
				// retrieve the sub price level to pass into the AddToCartFeeDisplay
				const priceLevel = Object.assign({}, subscriptionPriceLevelsMappedById[item.levelId]);
				// set the price level's price to the current actual price based on the CYO selections and remove the CYO min/max range
				priceLevel.price = item.price;
				priceLevel.minPrice = priceLevel.maxPrice = undefined;
				if(priceLevel.price === 0){
					// no fees on $0 items
					priceLevel.fee = priceLevel.fee2 = priceLevel.fee3 = priceLevel.fee4 = priceLevel.fee5 = 0;
				}
				
				return (
					<div key={item.levelId}>
						<CYOMiniCartDetail
							intl={intl}
							miniCartItem={miniCartItems[item.levelId]}
							currencyCode={currencyCode}
							includeFeesInPrice={includeFeesInPrice}
						/>
						{includeFeesInPrice && <AddToCartFeeDisplay isOpen={showPricingDetails} priceLevel={priceLevel} intl={intl}/>}
					</div>
				)
				})
			}
			
			<MiniCartItemTotal currencyCode={currencyCode} itemTotal={miniCartTotal} />
			{includeFeesInPrice &&
				<DetailToggleButton
					onClick={() => setShowPricingDetails(prevState => !prevState)}
					detailsVisible={showPricingDetails}
					pricing={true}
				/>
			}
			<div className="mb-2"/>
			<PanelNav
				back={{handleClick: handleCancel, label: intl.formatMessage({id: "lbl_button_Cancel"})}}
				next={{handleClick: handleAddToCart, label: nextButtonLabel, isDisabled: disableNext}}
			/>
		</div>
	)
}

interface CYOMiniCartDetailProps extends InjectedIntlProps {
	currencyCode: string;
	includeFeesInPrice: boolean;
	miniCartItem: MiniCartItem;
}

const CYOMiniCartDetail = (props: CYOMiniCartDetailProps) => {
	const {
		currencyCode,
		includeFeesInPrice,
		intl,
		miniCartItem: {
			quantity,
			allocName,
			levelName,
			price,
			fees
		}
	} = props;
	
	return (
		<div className="d-flex justify-content-between align-items-center border-top py-2 font-weight-bold small">
			<div>
				{`${Number(quantity)} @ ${allocName} - ${levelName}`}
			</div>
			<div>
				{getPriceString(intl, currencyCode, {minPrice: includeFeesInPrice && price > 0 ? price + fees : price})}
			</div>
		</div>
	);
}