import * as React from 'react';
import {FormattedMessage, InjectedIntlProps} from 'react-intl';
import {Redirect, Route, Switch} from "react-router-dom";
import {Button} from "reactstrap";
import { AnyAction } from "redux";
import {EventViews} from "../../enums/event-views";
import {Paths} from "../../enums/paths";
import { BasicStringKeyedMap } from "../../models/basic-map";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {TicketableEvent} from "../../models/ticketable-events/ticketable-event";
import {RouteProps} from "../../types/route-props";
import {CalendarIcon, ListIcon} from '../icons/icons';
import {Message} from "../message";
import {EventCalendar} from "./event-calendar";
import {EventFilter} from "./event-filter";
import {EventList} from "./event-list";
import {EventListCondensed} from './event-list-condensed';

const DEFAULT_FILTER_STATE: boolean = false; // unchecked filter
const PARAM__FILTER_BY_CATEGORY: string = "filter[category]";

interface ListSelectorProps extends InjectedIntlProps, RouteProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	defaultCalendarDate?: string;
	defaultEventView: EventViews;
	fetchEvents: () => void;
	onCalendarNavChange:(newDate: string) => void;
	singleTicketEvents: TicketableEvent[] | null;
	supportedEventViews: string[];
	timezone: string;
	config: PublicTicketAppConfig;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

interface ListSelectorState {
	categoryFilter: BasicStringKeyedMap<boolean>; // where key is the name of category and value is true when "selected'
	isExpandedFocused: boolean;
	isCondensedFocused: boolean;
	isCalendarFocused: boolean;
}

export class ListSelector extends React.Component<ListSelectorProps , ListSelectorState> {

	constructor(props: ListSelectorProps) {
		super(props);

		const categoryFilter: BasicStringKeyedMap<boolean> = {};

		// initialize categories to default state
		props.config.ptsFilterCategories.forEach((category) => {
			categoryFilter[category] = DEFAULT_FILTER_STATE;
		});

		this.state = {
			categoryFilter,
			isExpandedFocused: false,
			isCondensedFocused: false,
			isCalendarFocused: false
		};
	}

	public componentDidMount(): void {
		this.enableCategoriesFromSearchParams(this.props.history.location.search);
	}

	/**
	 * Enables specified `category` filter if it was previously disabled, otherwise disables the category filter.
	 *
	 * @param category the filter categories
	 * @param event the DOM event such as mouse click
	 *
	 */
	public toggleCategoryFilters = (category: string, event: any) => {
		this.setState({categoryFilter: {...this.state.categoryFilter, [category]: !this.state.categoryFilter[category] } });
	}

	public render() {
		const {blockingActions, 
			clearAllMessages, 
			defaultEventView, 
			fetchEvents,
			intl,
			history, 
			singleTicketEvents, 
			supportedEventViews, 
			timezone, 
			validatePasscode,
			config
		} = this.props;
		
		const pathName = history.location.pathname;

		let defaultEventsPath: string = '';
		switch (defaultEventView) {
			case EventViews.EXPANDED:{
				defaultEventsPath = Paths.EVENTS__EXPANDED;
				break;
			}
			case EventViews.CALENDAR:{
				defaultEventsPath = Paths.EVENTS__CALENDAR;
				break;
			}
			default : {
				defaultEventsPath = Paths.EVENTS__CONDENSED;
			}
		}

		const activeFilterCategories = Object.keys(this.state.categoryFilter).filter(category => this.state.categoryFilter[category]);

		const atLeastOneActiveFilter = activeFilterCategories.length > 0;

		const filteredEventList = atLeastOneActiveFilter && !!singleTicketEvents
			? this.getFilteredEvents(singleTicketEvents, activeFilterCategories)
			: singleTicketEvents;

		const eventsDoNotMatchActiveFilters = !!singleTicketEvents && atLeastOneActiveFilter &&
			!!filteredEventList && filteredEventList.length === 0;

		const isExpandedDisabled = pathName === Paths.EVENTS__EXPANDED || (pathName === Paths.ROOT && defaultEventsPath === Paths.EVENTS__EXPANDED);
		const isCondensedDisabled = pathName === Paths.EVENTS__CONDENSED || (pathName === Paths.ROOT && defaultEventsPath === Paths.EVENTS__CONDENSED);
		const isCalendarDisabled = pathName === Paths.EVENTS__CALENDAR || (pathName === Paths.ROOT && defaultEventsPath === Paths.EVENTS__CALENDAR);

		return (
			<div>
				{supportedEventViews.length > 1 ? (
						<div className="mb-4">
							{supportedEventViews.find((view: string) => view === EventViews.EXPANDED) ?
								(<span style={ this.state.isExpandedFocused && !isExpandedDisabled ? { outline: '-webkit-focus-ring-color auto 5px' } : {}}>
									<Button
										className="mr-1"
										color="link"
										onClick={this.navigateToList}
										disabled={isExpandedDisabled}
										data-path={Paths.EVENTS__EXPANDED}
										onFocus={() => { this.setState({ isExpandedFocused: true }); }}
										onBlur={() => { this.setState({ isExpandedFocused: false }); }}>
										<span className="sr-only">
											<FormattedMessage id="lbl_ViewAsList"/>
										</span>
										<ListIcon />
									</Button>
								</span>)
								: null
							}
							{supportedEventViews.find((view: string) => view === EventViews.CONDENSED) ?
								(<span style={ this.state.isCondensedFocused && !isCondensedDisabled ? { outline: '-webkit-focus-ring-color auto 5px' } : {}}>
									<Button
										className="mr-1"
										color="link"
										onClick={this.navigateToList}
										disabled={isCondensedDisabled}
										data-path={Paths.EVENTS__CONDENSED}
										onFocus={() => { this.setState({ isCondensedFocused: true }); }}
										onBlur={() => { this.setState({ isCondensedFocused: false }); }}>
										<span className="sr-only">
											<FormattedMessage id="lbl_ViewAsList"/>
										</span>
										<ListIcon />
									</Button>
								</span>)
								: null
							}
							{supportedEventViews.find((view: string) => view === EventViews.CALENDAR) ?
								(<span style={ this.state.isCalendarFocused && !isCalendarDisabled ? { outline: '-webkit-focus-ring-color auto 5px' } : {}}>
									<Button
										className="mr-1"
										color="link"
										onClick={this.navigateToList}
										disabled={isCalendarDisabled}
										data-path={Paths.EVENTS__CALENDAR}
										onFocus={() => { this.setState({ isCalendarFocused: true }); }}
										onBlur={() => { this.setState({ isCalendarFocused: false }); }}>
										<span className="sr-only">
											<FormattedMessage id="lbl_ViewAsCalendar"/>
										</span>
										<CalendarIcon />
									</Button>
								</span>)
								: null
							}
						</div>
					) : null
				}

				<EventFilter config={config} categories={this.state.categoryFilter} handleClick={this.toggleCategoryFilters}/>

				{/* Conditionally render Route components to match the supported event views, based on their path */}
				<Switch>

					{ eventsDoNotMatchActiveFilters &&
						<Route render={() =>
							<Message intlId="msg_no_filtered_events_found"
									 values={{events: intl.formatMessage({id: "pmgr_term_Events"}).toLowerCase()}}/>
						}/>
					}

					{supportedEventViews.find((view: string) => view === EventViews.EXPANDED)
						? <Route path={Paths.EVENTS__EXPANDED} exact={true} render={() => <EventList eventList={filteredEventList} intl={intl}/>} />
						: null
					}
					{supportedEventViews.find((view: string) => view === EventViews.CONDENSED)
						? <Route
							path={Paths.EVENTS__CONDENSED} 
							exact={true} 
							render={() => 
								<EventListCondensed
									blockingActions={blockingActions}
									clearAllMessages={clearAllMessages}
									eventList={filteredEventList}
									fetchEvents={fetchEvents}
									intl={intl}
									validatePasscode={validatePasscode}
								/>} 
							/>
						: null
					}
					{supportedEventViews.find((view: string) => view === EventViews.CALENDAR) ? (
							<Route
								path={Paths.EVENTS__CALENDAR}
								exact={true}
								render={() => {
									return (
										<EventCalendar
											blockingActions={blockingActions}
											clearAllMessages={clearAllMessages}
											defaultCalendarDate={this.props.defaultCalendarDate}
											eventList={filteredEventList}
											fetchEvents={fetchEvents}
											history={history}
											intl={intl}
											onCalendarNavChange={this.props.onCalendarNavChange}
											timezone={timezone}
											validatePasscode={validatePasscode}
										/>
									);
								}}
							/>
						) : null
					}
					<Route
						path={Paths.ROOT}
					   	exact={true}
						render={() => 
							<>
								{defaultEventsPath === Paths.EVENTS__CALENDAR ? (
										<EventCalendar
											blockingActions={blockingActions}
											clearAllMessages={clearAllMessages}
											defaultCalendarDate={this.props.defaultCalendarDate}
											eventList={singleTicketEvents}
											fetchEvents={fetchEvents}
											history={history}
											intl={intl}
											onCalendarNavChange={this.props.onCalendarNavChange}
											timezone={timezone}
											validatePasscode={validatePasscode}
										/>
									) : null
								}
								{defaultEventsPath === Paths.EVENTS__CONDENSED ? 
									<EventListCondensed
										blockingActions={blockingActions}
										clearAllMessages={clearAllMessages}
										eventList={filteredEventList}
										fetchEvents={fetchEvents}
										intl={intl}
										validatePasscode={validatePasscode}
									/> 
									: null
								}
								{defaultEventsPath === Paths.EVENTS__EXPANDED ? <EventList eventList={filteredEventList} intl={intl}/> : null}
							</>
						}
					/>

					{/*Redirect all non-matching routes*/}
					<Redirect to={Paths.ROOT}/>
				</Switch>
			</div>
		);
	}
	private navigateToList = (evt: React.MouseEvent<HTMLButtonElement>) => {
		if (evt.currentTarget.dataset.path) {
			this.props.history.push(evt.currentTarget.dataset.path);
			this.setState({ isExpandedFocused: false, isCondensedFocused: false, isCalendarFocused: false });
		}
	}

	/**
	 * Returns list of events that have at least one of the provided active `categories`
	 *
	 * @param events the ticketable events
	 * @param categories categories we want to match
	 */
	private getFilteredEvents = (events: TicketableEvent[], categories: string[]): TicketableEvent[] => {
		return events.filter(event => this.doesEventContainCategory(event, categories));
	}

	/**
	 * True at least one of the `event`'s categories matches one of the provided `categories`
	 *
	 * @param event the ticketable event whose categories that will be matched
	 * @param categories the categories we want to match
	 */
	private doesEventContainCategory = (event: TicketableEvent, categories: string[]): boolean => {
		const eventCategories: string[] =  !!event.category ? event.category.split(';'): [];

		return eventCategories.some(eventCategory => {
			return categories.includes(eventCategory);
		});
	}

	/**
	 * Checks location search params for comma separated PTS filter categories and enables them.
	 * categories that are not part of the organizations configured filtered categories will be ignored.
	 */
	private enableCategoriesFromSearchParams = (searchParams: string) => {
		const urlParams = new URLSearchParams(searchParams);

		const paramValue = urlParams.get(PARAM__FILTER_BY_CATEGORY);

		const categories = !!paramValue ? paramValue.split(',') : [];

		const state = {...this.state.categoryFilter};

		categories.forEach(category => {
			if (category in this.state.categoryFilter) {
				state[category] = true;
			}
		});

		this.setState({categoryFilter: {...this.state.categoryFilter, ...state}});
	}

}
