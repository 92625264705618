import {omit, values} from "lodash";
import {InjectedIntl} from "react-intl";
import {AnyAction} from "redux";
import {ApiActionNames} from "../enums/api-action-names";
import {FeeAggregationTypes} from "../enums/fee_aggregation_types";
import {OrderEntitlements} from "../enums/order-entitlements";
import {Severities} from "../enums/severities";
import {TicketOrderStatus} from "../enums/ticket-order-status";
import {TicketableEventTypes} from "../enums/ticketable-event-types";
import {UserTypes} from "../enums/user-types";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {CartItem} from "../models/cart-item";
import {EventDescriptor} from "../models/event-descriptor/event-descriptor";
import {LevelDescriptor} from "../models/event-descriptor/level-descriptor";
import {SeatDescriptor} from "../models/event-descriptor/seat-descriptor";
import {VenueDescriptor} from "../models/event-descriptor/venue-descriptor";
import {FeeDescriptor} from "../models/fee-descriptor";
import {PublicTicketAppConfig, SuggestedDonationTypes} from "../models/public-ticket-app/public-ticket-app-config";
import {ThemeNames, ThemeProps} from "../models/public-ticket-app/theme-props";
import {UserProfile} from "../models/public-ticket-app/user-profile";
import {EventInstance} from "../models/ticketable-events/event-instance";
import {TicketableEvent} from "../models/ticketable-events/ticketable-event";
import {InventoryService} from "./inventory-service";

export const ITEM_FEE_PROPS: string[] = ['fees', 'fee2', 'fee3', 'fee4', 'fee5'];
export const ORDER_FEE_PROPS: string[] = ['fees', 'deliveryFee', 'fee3', 'fee4'];

/**
 * Strips out sensitive CC info. Called from methods that generate UPDATE_CART requests to sanitize the cart first.
 *
 */
export function getSanitizedCart(cart: Cart): Partial<Cart> {
	return omit(cart, ["ccNumber", "ccCvv2", "ccExpMonth", "ccExpYear"]);
}

/**
 * Converts empty field values to a proper value that will not result in an error
 *
 * @return null for numbers, 'checked' value for checkboxes
 */
export function scrubbedValue(evt: React.ChangeEvent<HTMLInputElement>): any {
	// Convert empty "number" field values to nulls to avoid "Unable to convert number to Apex type Decimal." error
	if (evt.target.type === "number" && evt.target.value === ""){
		return null;
		// For "checkbox" elements, return the value of the "checked" property as the value
	} else if (evt.target.type === "checkbox") {
		return evt.target.checked;
	}
	return evt.target.value;
}

/**
 * Comparison function to be used for sorting event list.
 * Sorts by "sortOrder" property in descending order,
 * e.g. 101, 102, 103.
 */
export function compareEvents(event1: TicketableEvent, event2: TicketableEvent): number {
	if (event1.sortOrder < event2.sortOrder) { return -1; }
	if (event1.sortOrder > event2.sortOrder) { return 1; }
	return 0;
}

/**
 * Comparison function to be used for sorting event instance list.
 * Sorts by instance date then ei.Name in ascending order,
 * e.g. 06/01/2018 7:00PM Seven Instance One
 *      06/01/2018 7:00PM Seven Instance Two
 *      06/01/2018 8:00PM Eight Instance One
 *      06/02/2018 8:00PM June 2nd
 */
export function compareEIs(ei1: EventInstance, ei2: EventInstance): number {
	if (ei1.formattedDates.ISO8601 < ei2.formattedDates.ISO8601) { return -1; }
	if (ei1.formattedDates.ISO8601 > ei2.formattedDates.ISO8601) { return 1; }
	//if they're equal sort be ei.Name ASC
	if (ei1.name < ei2.name) { return -1; }
	return 1;
}

/**
 * Flatten TE list into a flat list of all EIs
 */
export const getAllEI = (eventList: TicketableEvent[]): EventInstance[] => eventList.reduce((result, te) => [...result, ...te.instances], []);

export interface TeEiPair { te: TicketableEvent; ei: EventInstance; }

/**
 * getEventInstancesWithTicketableEvent - Zips together the list of TEs and EIs into a list of TE-EI pairs.
 * Useful in components like the condensed event list.
 *
 * @param teList List of ticketable events
 * @param eiList List of event instances
 */
export const getEventInstancesWithTicketableEvent = (teList: TicketableEvent[], eiList: EventInstance[]): TeEiPair[] => {
	return eiList.reduce((result: TeEiPair[], ei: EventInstance): TeEiPair[] => {
		result.push({te: ticketableEventById(teList, ei.eventId), ei});
		return result;
	}, []);
};

/**
 * teByEi - Find the Ticketable Event by id.
 * If no Ticketable Event is found, returns an "empty" but valid ticketable event object.
 *
 * @param teList - A list of Ticketable Events
 * @param teId - Ticketable Event id
 */
const ticketableEventById = (teList: TicketableEvent[], teId: string): TicketableEvent => teList.find(te => te.id === teId) || new TicketableEvent();


// Commonly used order entitlement helper functions
export function canEditBuyerInfo(cart: Cart) {
	return !!cart.orderEntitlements && OrderEntitlements.CAN_EDIT_BUYER_INFO in cart.orderEntitlements;
}

/**
 * Checks if user has access to the checkout navigation(Delivery, Donation,...) links
 *
 * @return true if allowed, otherwise false
 */
export function canCheckout(cart: Cart) {
	return !!cart.orderEntitlements && OrderEntitlements.CAN_SUBMIT in cart.orderEntitlements;
}

/**
 * Checks if user has access to the "Payment" navigation link
 *
 * @return true if allowed, otherwise false
 */
export function canAddPayment(cart: Cart) {
	return !!cart.orderEntitlements && OrderEntitlements.CAN_ADD_PAYMENT in cart.orderEntitlements;
}

/**
 * Returns true if the cart is in a state that allows subscription fulfillment.
 * @param cart the Cart
 */
export function canFulfill(cart: Cart) {
	return !!cart && !!cart.orderEntitlements && OrderEntitlements.CAN_FULFILL in cart.orderEntitlements;
}

// checks to see if the order is a pending renewal depending on either the path passed in or the orderStatus on the cart
export function isPendingRenewal(pathName: string = '', c: Cart | null = null){
	if (!!c) {
		return c.orderStatus === TicketOrderStatus.PENDING_RENEWAL;
	} else {
		// Unfortunately, I can't come up with a better way to determine this other than this regex.
		// This is because /portal/pendingRenewals/:ticketOrderId/contactRequest looks too much like one of the
		// Pending Renewal checkout routes to easily distinguish. This will break if we ever change any of the
		// /portal/pendingRenewals routes.
		const regex = /\/portal\/pendingRenewals\/([a-zA-Z|\d]{15,18})\/(delivery|donation|discount|contact|payment)\b/g;
		return regex.test(pathName);
	}
}

// checks to see if the user is a portal user
export function isPortalUser(userProfile: UserProfile | undefined){
	return !!userProfile && userProfile.type === UserTypes.PORTAL;
}

export function isDraft(c: Cart) {
	return c.orderStatus === TicketOrderStatus.DRAFT;
}

export function isPartiallyPaid(c: Cart) {
	return c.orderStatus === TicketOrderStatus.PARTIALLY_PAID;
}

/**
 * Normalize event items for use by TicketOrder
 */
export const getTicketFees  = (cartOrCartItem: Cart | CartItem, feeProps: string[], feeConfig: FeeDescriptor[], config: PublicTicketAppConfig, intl: InjectedIntl): FeeDescriptor[] => {
	// Create a FeeDescriptor object for dealing with grouped order-level or item-level fees
	const groupedFee = new FeeDescriptor();
	groupedFee.value = 0;
	if ("cartId" in cartOrCartItem) {
		groupedFee.label = !!config.orderFeeLabel ? config.orderFeeLabel : intl.formatMessage({id: 'lbl_OrderFees'});
	} else {
		groupedFee.label = !!config.itemFeeLabel ? config.itemFeeLabel : intl.formatMessage({id: 'lbl_ItemFees'});
	}
	
	const fees: FeeDescriptor[] = Array<FeeDescriptor>();
	feeConfig.forEach(fee => {
		// Only return fees whose value is greater than $0
		if (cartOrCartItem[fee.prop] > 0) {
			if (fee.aggregation === FeeAggregationTypes.GROUPED) {
				// If the fee is grouped with other fees, handle it separately by aggregating its value in the "groupedFee" object
				groupedFee.value += cartOrCartItem[fee.prop];
			} else {
				// Push properly labeled ungrouped fees onto the array
				const labeledFee = new FeeDescriptor();
				labeledFee.prop = fee.prop;
				labeledFee.label = fee.label;
				labeledFee.value = cartOrCartItem[fee.prop];
				fees.push(labeledFee);
			}
		}
	});
	// If the groupedFee value is greater than 0, push it onto the array last
	if (groupedFee.value > 0) {
		fees.push(groupedFee);
	}
	return fees;
};

/**
 * Checks PublicTicketAppConfig to decide if the donation page should be show during the checkout process
 * See PMGR-8032 for criteria
 *
 * @return true to show page, otherwise false
 */
export const showDonationPage = (config: PublicTicketAppConfig): boolean => {
	return !config.forProfitOrg && !!config.donationLabel && !!config.donationCTABody;
};

/**
 * Checks PublicTicketAppConfig to decide if the donation field should be shown during the checkout process
 * See PMGR-8032 for criteria
 *
 * @return true to show the field, otherwise false
 */
export const showDonationField = (config: PublicTicketAppConfig): boolean => {
	return !config.forProfitOrg && !showDonationPage(config) && !!config.donationLabel;
};

/**
 * Checks PublicTicketAppConfig to decide if the discount page should be show during the checkout process
 * This is more of a convenience method to stay consistent with the showDonation stuff
 * See PMGR-8032 for criteria
 *
 * @return true to show page, otherwise false
 */
export const showDiscountPage = (config: PublicTicketAppConfig): boolean => {
	return (!config.disableDiscountPage && config.usesDiscountCodes) || false;
};

/**
 * Properly formats the prev/next & cart navigation links
 *
 * @param path - path that needs to be reformatted
 * @param toId that should be inserted in the path
 *
 * @return updated path as a string
 */
export const formatNavigationPath = (path: string, toId: string): string =>{
	return path.replace(':ticketOrderId',toId);
};

/**
 * Calculates suggested donation based on its type, and suggestedDonationAmount value
 * When suggested donation is of 'Fixed' type the suggestedDonationAmount is used as is.
 * When suggested donation is of 'Percentage' type the suggestedDonationAmount is the percentage result rounded up so that
 * the amount ends with a 0 or a 5
 * When suggested donation is neither of those types 0 is returned
 *
 * @param {Cart} cart
 * @param {PublicTicketAppConfig} config
 * @param {ReactIntl.InjectedIntl} intl
 * @returns {number} suggested donation amount, or 0 if not of 'Fixed' or 'Percentage' type
 */
export const calculateSuggestedDonation = (cart: Cart, config: PublicTicketAppConfig) => {
	const {suggestedDonationAmount, suggestedDonationType} = config;

	const defaultSuggestedAmount = suggestedDonationAmount;
	let calculatedSuggestedAmount;

	if (defaultSuggestedAmount != null) {
		if (suggestedDonationType === SuggestedDonationTypes.FIXED) {
			return defaultSuggestedAmount;
		}
		else if (suggestedDonationType === SuggestedDonationTypes.PERCENTAGE) {
			// rounded up to a number that ends with 5 or 0
			calculatedSuggestedAmount = Math.round(0.01 * defaultSuggestedAmount * cart.subtotal);
			const mod5 = calculatedSuggestedAmount % 5;
			if ( mod5 !== 0) {
				//rounding up so that suggested amount ends in a 0 or 5
				calculatedSuggestedAmount += 5 - mod5;
			}
			// calculated amount must never be larger than the total
			return Math.min(calculatedSuggestedAmount, cart.subtotal);
		}
	}
	// The other type is "--NONE--" which means there is  nothing to suggested
	return 0;
};

export const venueHasDetails = (venue?: VenueDescriptor): boolean => (
	!!venue && (!!venue.detail || !!venue.seatingChartPath)
);

// TicketableEvent filter functions, used to filter "Tickets", "Subscription" and "Membership" events, respectively
export const singleTicketEventsOnly = (te: TicketableEvent): boolean => te.type === TicketableEventTypes.TICKETS;
export const subscriptionEventsOnly = (te: TicketableEvent): boolean => te.type === TicketableEventTypes.SUBSCRIPTION;
export const membershipEventsOnly = (te: TicketableEvent): boolean => te.type === TicketableEventTypes.MEMBERSHIP;

/**
 * If the passed in inputString ends with the specified "end" string, this method will return a new string
 * equal to the inputString value with the end removed. Otherwise it returns the unmodified inputString.
 * @param inputString
 * @param end
 */
export const removeEnd = (inputString: string, end: string): string => {
	if (inputString.endsWith(end)) {
		return inputString.substring(0, inputString.lastIndexOf(end));
	}
	return inputString;
};

/**
 * Determines whether the theme has a "dark mode" header
 * @param themeProps - The theme values set by the user in Theme Builder
 * @return true if the specified themeName has a "dark mode" header
 */
export const hasDarkModeHeader = (themeProps: ThemeProps | undefined): boolean => (
	!!themeProps && (themeProps.themeName === ThemeNames.CINEMATIC || themeProps.themeName === ThemeNames.MODERNE)
);

export const shouldSkipSectionMap = (eventDescriptor: EventDescriptor): boolean => {
	return !!eventDescriptor.singleSectionOrGroupId && !!eventDescriptor.venue && !!eventDescriptor.venue.skipSectionSelection;
};

/**
 * Checks to see if the passed in actionName is in the blockingActions collection
 *
 * @param blockingActions - collection of actions to be checked
 * @param actionName - action to find. If none specified it test to see if the blockingActions collection is empty
 * @return boolean
 *
 */
export function isBlockingActionsBusy(blockingActions: BasicStringKeyedMap<AnyAction> , actionName: ApiActionNames){
	return !!actionName ? !!values(blockingActions).find(a => actionName === a.actionName) : Object.keys(blockingActions).length > 0;
}

/**
 * Properly formats the passed in message into an ApplicationMessage[]
 * @param error - error message that needs to be converted
 * @return ApplicationMessage[]
 */
export const formatApplicationMessages = (error: BasicStringKeyedMap<any>): ApplicationMessage[] =>{
	// Convert errors into ApplicationMessage objects
	const appMessages: ApplicationMessage[] = [];
	if (!!error.messages && error.messages.length > 0) {
		error.messages.forEach((message: BasicStringKeyedMap<any>) => {
			appMessages.push(makeMessage(message.msgId, message.msgArgs, message.msg, message.severity));
		});
	} else {
		appMessages.push(makeErrorMessage(error));
	}
	return appMessages;
};

const makeErrorMessage = (error: any): ApplicationMessage => {
	if (!!error.message) {
		if (!!error.message.msgId) {
			return makeMessage(error.message.msgId, error.message.msgArgs, undefined);
		} else if (!!error.message.msg) {
			return makeMessage(undefined, undefined, error.message.msg);
		} else {
			return makeMessage(undefined, undefined, error.message);
		}
	} else {
		return makeMessage(undefined, undefined, error.toString());
	}
};

const makeMessage = (
	msgId: string | undefined,
	args: BasicStringKeyedMap<string> | undefined,
	msg: string | undefined,
	severity: Severities = Severities.ERROR): ApplicationMessage  => {
	
	return new ApplicationMessage(severity, msgId, args, msg);
};

export const setScrollPosition = (top: number = 0, left: number = 0) => {
	window.setTimeout(() => window.scrollTo({top, left}));
};

export const getPriceWithFeesIncluded = (level: LevelDescriptor, pwywPrice?: number) => {
	return (pwywPrice ? pwywPrice : level.price) + getTotalItemFees(level);
}

export const getPWYWPriceWithFeesIncluded = (pwywPrice: number, level: LevelDescriptor) => {
	return pwywPrice + getTotalItemFees(level);
}

export const getTotalItemFees = (level: LevelDescriptor) => {
	return level.fee + level.fee2 + level.fee3 + level.fee4 + level.fee5;
}

export const getCartPageTitle = (url: string, intl: InjectedIntl) => {
	if(url.endsWith('delivery')) {
		return intl.formatMessage({id: 'lbl_title_cart_delivery'});
	}
	else if (url.endsWith('discount')) {
		return intl.formatMessage({id: 'lbl_title_cart_discount'});
	}
	else if (url.endsWith('donation')) {
		return intl.formatMessage({id: 'lbl_title_cart_donation'});
	}
	else if (url.endsWith('contact')) {
		return intl.formatMessage({id: 'lbl_title_cart_contact'});
	}
	else if (url.endsWith('payment')) {
		return intl.formatMessage({id: 'lbl_title_cart_payment'});
	}
	else {
		// if all else fails, fall back to just "Cart"
		return intl.formatMessage({id: 'lbl_title_cart'});
	}
}

/**
 * Creates a seat assigment string for a given seat. Normally this is computed server side once the cart item is
 * inserted, however there are occasions when we need a fully formed seat assignment before inserting (ex. staged
 * seat requests that have not yet been processed).
 * 
 * @param seat the seat to generate a seat assignment for
 * @param eventDescriptor the event descriptor for the event referenced by the seat
 */
export const createSeatAssignmentStringForSeat = (seat: SeatDescriptor, eventDescriptor: EventDescriptor) => {
	const _section = InventoryService.getSectionsMappedById(eventDescriptor)[seat.sId];
	const _row = InventoryService.getRowsMappedById(eventDescriptor)[seat.rId];
	return `${_section.name} - Row:${_row.name} - Seat:${seat.name}`;
}