import * as React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

interface MiniCartItemTotalProps {
	currencyCode: string;
	itemTotal: number;
}

export const MiniCartItemTotal: React.SFC<MiniCartItemTotalProps> = ({itemTotal, currencyCode}) => (
	<div className="d-flex justify-content-between border-top py-2">
		<div className="mr-2">
			<FormattedMessage id="lbl_ItemTotal" />
		</div>
		<strong>
			<FormattedNumber
				value={itemTotal}
				style="currency"
				currency={currencyCode}
				currencyDisplay="symbol"
			/>
		</strong>
	</div>
);

