import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {Media} from "reactstrap";
import {getInstanceRoute} from "../../helpers/routing";
import {compareEvents} from "../../helpers/utilities";
import {TicketableEvent} from "../../models/ticketable-events/ticketable-event";
import {EventItem} from "../event-list/event-item";
import {GhostEvents} from "../event-list/ghost-event";
import {Message} from "../message";

interface MembershipListProps extends InjectedIntlProps {
	benefitId?: string | null;
	memberships: TicketableEvent[] | null;
}

export const MembershipList: React.FunctionComponent<MembershipListProps> = (props) => {
	const {benefitId, intl, memberships} = props;


	if (!memberships) {
		return <GhostEvents />;
	}
	else if (memberships.length < 1) {
		return <Message intlId="msg_no_memberships_found" values={{memberships: intl.formatMessage({id: "pmgr_term_Memberships"}).toLowerCase()}} />;
	}

	return (
		<Media list={true} className="p-0">
			{
				memberships
					.sort(compareEvents)
					.map((memEvent) => {
						// There is always only 1 instance for memberships
						const eventInstance = memEvent.instances[0];
						// Append the benefitId search param if present
						let membershipDetailRoute = getInstanceRoute(eventInstance.id);
						membershipDetailRoute += !!benefitId ? `?benefitId=${benefitId}` : "";
						return <EventItem key={memEvent.id} detailPath={membershipDetailRoute} ticketableEvent={memEvent}/>;
					})
			}
		</Media>
	);
};

export default injectIntl(MembershipList);