import * as React from "react";
import {InjectedIntl} from "react-intl";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import "./seat-map.css";

// when price level is private and allocation sales status is public it's minPrice will appear as '9999999'
const DEFAULT_MIN_PRICE = 9999999;

interface LegendProps {
	eventDescriptor: EventDescriptor;
	currencyCode: string;
	intl: InjectedIntl;
	allocationsInSectionOrGroup: AllocationDescriptor[];
}

export class Legend extends React.Component<LegendProps> {

	public render() {
		const {intl, allocationsInSectionOrGroup} = this.props;

		if (allocationsInSectionOrGroup == null) {
			return null;
		}

		const keyItems = [
			...this.createSeatPriceItems(),
			this.createSeatItem(intl.formatMessage({id: 'lbl_SectionOrGroupNotAvailable'}), 'seat-unavailable'),
			this.createSeatItem(intl.formatMessage({id: 'lbl_YourSeats'}), 'seat-selected')
		];

		return <div id='SeatLegend'>{this.createGrid(keyItems)}</div>;
	}

	/**
	 * Creates price item key for all allocations with available inventory
	 */
	public createSeatPriceItems = () => {
		const {allocationsInSectionOrGroup, currencyCode, intl} = this.props;

		return allocationsInSectionOrGroup
			.filter(this.canBePurchased)
			.map(allocation => {
				let priceToDisplay;

				const minPrice = intl.formatNumber((allocation.minPrice || 0), {style: "currency", currency: currencyCode});

				if (allocation.minPrice === allocation.maxPrice) {
					priceToDisplay  = minPrice;
				} else {
					const maxPrice = intl.formatNumber((allocation.maxPrice || 0), {style: "currency", currency: currencyCode});
					priceToDisplay = `${minPrice} - ${maxPrice}`;
				}

				return <div key={allocation.id} className='w-100'>
					{this.createSeatItem(priceToDisplay, undefined, allocation.color)}
				</div>;
		});
	}

	/**
	 * Creates a key and label representing a seat map seat.
	 *
	 * @param label the label to display to the right of the seat
	 * @param className optional seat class name for specific css decoration
	 * @param backgroundColor optional seat background color
	 */
	public createSeatItem = (label: string, className?: string, backgroundColor?: string) => {
		// legacy symbols used for "Not Available" and "Your Seats" might become misaligned if this value changes.
		const seatDiameter = 12;

		return <div className='w-100'>
			<span className={`seat-map seat ${className}`}
				  style={{
					  backgroundColor,
					  display: "inline-block",
					  width: seatDiameter,
					  height: seatDiameter,
					  marginRight: 10
				  }}/>
			{label}
		</div>;
	}

	/**
	 * Creates 2 column rows from the provided keyItems. A single keyItem occupies a single column.
	 *
	 * @param keyItems the content that will occupy the columns
	 */
	public createGrid = (keyItems: any[]): any[] => {
		const grid = [];

		for (let i = 0; i < keyItems.length;) {
			const hasNextElement = i + 1 < keyItems.length;

			grid.push( <div key={i} className="d-flex align-items-start">
				{keyItems[i]}
				{hasNextElement ? keyItems[i + 1] : null}
			</div>);

			// we are processing two at a time, so we must add by 2
			i += 2;
		}

		return grid;
	}

	/**
	 * Checks whether allocation meets requirements to be purchased
	 *
	 * @param allocation the allocation to check
	 * @return true when allocation can be purchased; otherwise false
	 */
	private canBePurchased = (allocation: AllocationDescriptor): boolean => {
		return !!allocation && allocation.isForSale && allocation.hasRetQty && allocation.minPrice < DEFAULT_MIN_PRICE;
	}
}