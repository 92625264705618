import * as React from "react";
import {FormattedMessage, InjectedIntl} from "react-intl";
import {RouterProps} from "react-router";
import {Button, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {AnyAction} from "redux";
import {Severities} from "../enums/severities";
import {canEditBuyerInfo} from "../helpers/utilities";
import {BasicStringKeyedMap} from "../models/basic-map";
import {Cart} from "../models/cart";
import {CountdownTimer} from "./countdown-timer";
import {FormElementLabel} from "./form-element-label/form-element-label";
import {PanelNav} from "./panel-nav";
import {WaitingMessage} from "./waiting-message";

interface DiscountFormProps extends RouterProps {
	applyDiscount: (cartId: string, discountCode: string, modstamp?: number) => Promise<any>;
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	intl: InjectedIntl;
	nextPagePath: string;
	prevPagePath: string;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
}

interface DiscountFormState {
	discountCode: string;
	error?: string;
}

export class DiscountForm extends React.Component<DiscountFormProps, DiscountFormState> {
	public readonly state: DiscountFormState = {
		discountCode: ''
	};

	public render() {
		const {blockingActions, cart, cartTimeRemaining, intl} = this.props;
		const {discountCode, error} = this.state;
		const isBusy: boolean = Object.keys(blockingActions).length > 0;
		return (
			<div style={{minHeight: '300px'}} className="d-flex flex-column">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				<FormGroup>
					<FormElementLabel intlLabelId={'lbl_EnterYourDiscountCode'}/>
					<InputGroup>
						<Input
							type="text"
							id="discountCode"
							name="discountCode"
							aria-label={intl.formatMessage({id: "lbl_DiscountCode"})}
							value={discountCode}
							onChange={this.handleChange}
							invalid={!!error}
							disabled={!canEditBuyerInfo(cart)}
						/>
						<InputGroupAddon addonType="append">
							<Button onClick={this.handleApply} disabled={isBusy || !canEditBuyerInfo(cart)}>
								<FormattedMessage id="lbl_Apply" />
							</Button>
						</InputGroupAddon>
						{!!error && <FormFeedback>{error}</FormFeedback>}
					</InputGroup>
				</FormGroup>
				<div className="mt-auto">
					<WaitingMessage isOpen={isBusy} />
					<PanelNav
						next={{handleClick: this.handleNext, label: intl.formatMessage({id: "lbl_Next"}), isDisabled: isBusy}}
						back={{handleClick: this.handleBack, label: intl.formatMessage({id: "lbl_Back"})}}
					/>
				</div>
			</div>
		);
	}

	private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({discountCode: evt.target.value, error: ''});
	}

	private handleApply = () => {
		const {applyDiscount, cart, clearAllMessages, intl, showAlert} = this.props;
		const {discountCode} = this.state;
		
		// Clear error messages at the start of the submit operation
		clearAllMessages();
		
		// Make sure the discount code value is not blank
		if (!this.state.discountCode) {
			this.setState({error: intl.formatMessage({id: "msg_discount_cannot_be_blank"})});
			return;
		}

		applyDiscount(cart.cartId, discountCode, cart.modstamp)
			.then(result => {
				if (!!result.data && 'numberOfItemsDiscounted' in result.data) {
					const discountCount = result.data.numberOfItemsDiscounted;
					if (discountCount > 0) {
						showAlert(intl.formatMessage({id: "msg_discount_applied_to_items"}, {discountCode, discountCount}), Severities.SUCCESS);
					} else {
						showAlert(intl.formatMessage({id: "msg_discount_not_applicable"}, {discountCode}), Severities.WARNING);
					}
				}
			});
	}

	private handleNext = () => {
		this.props.history.push(this.props.nextPagePath);
	}

	private handleBack = () => {
		this.props.history.push(this.props.prevPagePath);
	}
}
