import * as React from "react";
import {AnyAction} from "redux";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {Ticket} from "../../models/ticket";
import {TicketItem} from "./ticket-item";

///
/// Interfaces
///

interface TicketTableProps {
	// An array of objects that wrap a cartItem and it's associated fees
	tickets: Ticket[];
	
	//Displays the 'Edit' button to allow changes
	allowEdit: boolean;

	 // used by React Intl to format currency.
	currencyCode: string;

	// removeItem: A callback to dispatch an action to remove an item from the cart.
	removeItem?: (cartItemId: string) => void;

	// a callback to dispatch and action to update the price level on an item in the cart.
	changeItemPriceLevel?: (cartItemId: string, priceLevelId: string) => void;

	// a string keyed map of in-flight actions that should block the UI
	blockingActions: BasicStringKeyedMap<AnyAction>;
	
	// the EventDescriptor for the current item
	eventDescriptor: EventDescriptor | null;
}

///
/// Component
///

/**
 * Displays one or more tickets
 */
export const  TicketTable: React.FunctionComponent<TicketTableProps> = (props) => {
	const {tickets, blockingActions, ...other} = props;
	const isBusy = Object.keys(blockingActions).length > 0;
	return (
		<div>
			{
				tickets.map(ticket => (
					<TicketItem
						key={ticket.cartItem.id}
						ticket={ticket}
						isBusy={isBusy}
						{...other}
					/>
				))
			}
		</div>
	);
};
