import * as React from "react";
import {InjectedIntlProps} from "react-intl";
import { AnyAction } from "redux";
import { compareEIs, getAllEI, getEventInstancesWithTicketableEvent } from "../../helpers/utilities";
import { BasicStringKeyedMap } from "../../models/basic-map";
import { TicketableEvent } from "../../models/ticketable-events/ticketable-event";
import {Message} from "../message";
import { EventListCondensedItem } from "./event-list-condensed-item";
import {GhostEvents} from "./ghost-event";

interface EventListCondensedProps extends InjectedIntlProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	eventList: TicketableEvent[] | null;
	fetchEvents: () => void;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

export const EventListCondensed: React.FunctionComponent<EventListCondensedProps> = ({
	blockingActions, clearAllMessages, eventList, fetchEvents, intl, validatePasscode}) => {

	if (eventList == null) {
		return <GhostEvents />;
	} else if (eventList.length < 1) {
		return <Message intlId="msg_no_events_found" values={{events: intl.formatMessage({id: "pmgr_term_Events"}).toLowerCase()}} />;
	}

	const eiList = getAllEI(eventList).sort(compareEIs);
	const teEiList = getEventInstancesWithTicketableEvent(eventList, eiList);

	return (
		<>
			{teEiList.map(teEiPair => <EventListCondensedItem 
				key={teEiPair.ei.id}
				blockingActions={blockingActions} 
				clearAllMessages={clearAllMessages} 
				teEiPair={teEiPair} 
				fetchEvents={fetchEvents}
				validatePasscode={validatePasscode} 
			/>)}
		</>
	);
};