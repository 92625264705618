import {History} from "history";
import * as React from "react";
import {InjectedIntlProps} from "react-intl";
import {Link} from "react-router-dom";
import {Nav, NavItem} from "reactstrap";
import {AnyAction} from "redux";
import { EventInstanceSaleStatus } from "../../enums/event-instance-sale-status";
import {InventoryService} from "../../helpers/inventory-service";
import {getLegendForSectionOrGroup} from "../../helpers/localization";
import {getSectionGroupRoute, getSectionRoute} from "../../helpers/routing";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {GroupDescriptor} from "../../models/event-descriptor/group-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {CountdownTimer} from "../countdown-timer";
import {HTMLContent} from "../html-content/html-content";
import {PasscodeDisplayWithInjections as PasscodeDisplay, PasscodeFormWithInjections as PasscodeForm} from "../passcode/wrapped-components";

interface SectionLegendProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cartTimeRemaining?: number;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	eventDescriptor: EventDescriptor;
	fetchEvents: () => void;
	history: History;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

export class SectionLegend extends React.Component<SectionLegendProps & InjectedIntlProps> {
	public render() {
		const {blockingActions, cartTimeRemaining, clearAllMessages, config, eventDescriptor, fetchEvents, intl, validatePasscode} = this.props;
		const {appliedPasscode, groupList, id, sectionList, isPasscodeEligible, venue} = eventDescriptor;
		
		if ((!eventDescriptor) || (!InventoryService.isPYOSEventDescriptor(eventDescriptor)) || 
			(!eventDescriptor.hasRetQty && !eventDescriptor.isPasscodeEligible)) {
			return null;
		}
		
		let sectionOrGroupLinks: JSX.Element[] = groupList.reduce((prev: JSX.Element[], sectionGroup: GroupDescriptor): JSX.Element[] => {
			if (!!venue && venue.groupSectionsInLegend) {
				// If grouping Sections in the legend, generate one link for the entire Section Group
				prev.push(
					<NavItem key={"section-" + sectionGroup.id}>
						<Link to={getSectionGroupRoute(id, sectionGroup.id)} className="nav-link">
							{getLegendForSectionOrGroup(sectionGroup, intl, config.currencyCode)}
						</Link>
					</NavItem>
				);
			} else {
				// If not grouping Sections in the legend, then generate links for each Section within the Section Group
				prev = [...prev, ...this.getSectionLinks(InventoryService.getSectionsByGroupId(eventDescriptor, sectionGroup.id), true)];
			}
			return prev;
		}, []);
		
		// Concatenate links for ungrouped sections
		sectionOrGroupLinks = [...sectionOrGroupLinks, ...this.getSectionLinks(sectionList, false)];
		
		return (
			<div className="pyos-section-legend">
				<CountdownTimer cartTimeRemaining={cartTimeRemaining} elaborate={true} />
				
				{isPasscodeEligible && (
					<PasscodeForm
						blockingActions={blockingActions}
						clearAllMessages={clearAllMessages}
						eventInstanceId={eventDescriptor.id}
						fetchEvents={fetchEvents}
						validatePasscode={validatePasscode}
					/>
				)}
				{!!appliedPasscode && (
					<PasscodeDisplay
						appliedPasscode={appliedPasscode}
						eventInstanceId={eventDescriptor.id}
						fetchEvents={fetchEvents}
					/>
				)}
				
				{eventDescriptor.saleStatus === EventInstanceSaleStatus.OS && eventDescriptor.hasRetQty &&  (
					<>
						{!!config.portalSeatSelectionMessage && (
							<div className="mb-4">
								<HTMLContent rawHtml={config.portalSeatSelectionMessage}/>
							</div>
						)}
						
						<Nav pills={true} vertical={true}>
							{sectionOrGroupLinks}
						</Nav>
					</>
				)}
			</div>
		);
	}
	
	/**
	 * Returns an array of Links for the SectionDescriptor objects in the passed in array (excluding the "stage").
	 * @param sectionList array of SectionDescriptor objects
	 * @param groupedSectionsOnly if true, links will only be generated for Section that are contained within
	 * a Section Group (those that have a truthy "groupId" value), otherwise links will only be generated for those
	 * Sections that are NOT contained within a Section Group (falsy "groupId" value).
	 */
	private getSectionLinks = (sectionList: SectionDescriptor[], groupedSectionsOnly: boolean = false): JSX.Element[] => {
		const {config, eventDescriptor, intl} = this.props;
		return sectionList.reduce((prev: JSX.Element[], section: SectionDescriptor) => {
			// Generate links to Sections other than "stage"
			if (section.name !== "stage" &&
				((groupedSectionsOnly && !!section.groupId) || (!groupedSectionsOnly && !section.groupId))) {
				prev.push(
					<NavItem key={"section-" + section.id}>
						<Link to={getSectionRoute(eventDescriptor.id, section.id)} className="nav-link">
							{getLegendForSectionOrGroup(section, intl, config.currencyCode)}
						</Link>
					</NavItem>
				);
			}
			return prev;
		}, []);
	}
}
