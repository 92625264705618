import {FormattedMessage, InjectedIntlProps} from "react-intl";
import {shouldSkipSectionMap} from "../../helpers/utilities";
import {
	SubscriptionPriceLevelLinkDescriptor
} from "../../models/event-descriptor/subscription-price-level-link-descriptor";
import {useCallback, useEffect, useState} from "react";
import {SubscriptionEventDescriptor} from "../../models/event-descriptor/subscription-event-descriptor";
import {ThunkDispatch} from "redux-thunk";
import {RootState} from "../../reducers";
import {AnyAction} from "redux";
import {useDispatch} from "react-redux";
import {usePrevious} from "../../hooks";
import {ApiActions} from "../../actions/api-actions";
import {ActionTypes} from "../../enums/action-types";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {SectionMap} from "../section-map/section-map";
import {SeatMap} from "./seat-map";
import {InventoryService} from "../../helpers/inventory-service";
import {Cart} from "../../models/cart";
import Button from "reactstrap/lib/Button";

interface SeatMapPreviewProps extends InjectedIntlProps {
	closeSeatMap: () => void;
	instanceIdToPreview: string;
	spllsForInstance: SubscriptionPriceLevelLinkDescriptor[];
}

/**
 * PMGR-10281 Displays a preview of the seat map for a fulfillment event instance inside of a modal. Used during Choose
 * Your Own subscription performance selection to allow the user to check seat availability before locking in
 * their choices. This preview includes a transparent overlay to prevent clicking and also to give it more 
 * of a "preview" appearance.
 */
export const SeatMapPreview = (props: SeatMapPreviewProps) => {
	const {closeSeatMap, intl, instanceIdToPreview, spllsForInstance} = props;
	const [sectionToPreview, setSectionToPreview] = useState<string>('');
	const [previewEventDescriptor, setPreviewEventDescriptor] = useState<SubscriptionEventDescriptor>();
	const [singleSectionVenue, setSingleSectionVenue] = useState<Boolean>(false);
	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

	const spllsForInstanceIds = spllsForInstance.reduce((prev: string[], spll: SubscriptionPriceLevelLinkDescriptor) => prev.concat(spll.id),[]);
	const prevInstanceId = usePrevious(instanceIdToPreview);

	// Fetch the event descriptor for the instance being previewed. The API call is being made directly as opposed to using
	// the inventory service since this particular descriptor is only useful for the preview and is no longer needed once
	// the modal has been closed. Keeping it out of the event descriptor cache will prevent other components from accidentally
	// making use of it.
	useEffect(() => {
		if(prevInstanceId !== instanceIdToPreview) {
			dispatch(ApiActions.fetchPreviewFulfillmentEventDescriptor(instanceIdToPreview, spllsForInstanceIds))
				.then((response) => {
					if (response.type === ActionTypes.API_SUCCESS) {
						const eventDescriptor: SubscriptionEventDescriptor = response.data;
						if(shouldSkipSectionMap(eventDescriptor)) {
							// if there's only one section or section group to choose from, skip straight to previewing it
							setSingleSectionVenue(true);
							setSectionToPreview(eventDescriptor.singleSectionOrGroupId!);
						}
						setPreviewEventDescriptor(eventDescriptor);
					} else {
						closeSeatMap();
					}
				});
		}
	},[closeSeatMap, dispatch, instanceIdToPreview, prevInstanceId, spllsForInstanceIds]);

	const handleSectionSelection = useCallback((sectionId: string) => {
		setSectionToPreview(sectionId);
	},[]);

	return (
		<Modal isOpen={true} toggle={closeSeatMap} keyboard={true} className="text-dark" size="lg" aria-modal="true" aria-labelledby="CurrentAvailabilityLabel">
			<ModalHeader toggle={closeSeatMap}>
				<div>
					<div className="small font-weight-bold">{spllsForInstance[0].ticketableEventName}</div>
					<div className="small">{spllsForInstance[0].eventInstanceName}</div>
				</div>
			</ModalHeader>
			<ModalBody>
				<h4 className="text-center text-uppercase" id="CurrentAvailabilityLabel"><FormattedMessage id={"lbl_PreviewOnly"}/></h4>
				{!!previewEventDescriptor && (
					!sectionToPreview
						? (
							<SectionMap
								eventDescriptor={previewEventDescriptor}
								fulfillment={true}
								handleSectionSelection={handleSectionSelection}
								match={{}}
							/>
						) : (
							<>
								{!singleSectionVenue && (
									<Button className="mb-2" color="light" size="sm" onClick={() => setSectionToPreview('')}>
										<FormattedMessage id={"lbl_ChangeSection"} />
									</Button>
								)}
								<SeatMap
									allocMap={InventoryService.getAllocationsMappedById(previewEventDescriptor)}
									cart={new Cart()}
									eventDescriptor={previewEventDescriptor}
									intl={intl}
									pendingSeatRequests={{}}
									previewMode={true}
									pyosCartItemMap={{}}
									sectionOrGroupId={sectionToPreview}
									onSeatClick={() => null}
								/>
							</>
						)
				)}
			</ModalBody>
		</Modal>
	);
}