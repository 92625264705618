import * as React from "react";
import {InjectedIntlProps} from "react-intl";
import {injectIntl}  from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {AnyAction, Dispatch} from "redux";
import {AnalyticsActions} from "../actions/analytics-actions";
import {Paths} from "../enums/paths";

/**
 * All properties available within this component
 */
interface Error404Props extends InjectedIntlProps {
	pageView: (title: string, url: string) => void;
}

/**
 * All properties that should be defined when using the component exported with injections.
 */
interface Error404ExcludingInjectedProps {}

export class Error404 extends React.Component<Error404Props> {
	public componentDidMount() {
		const title = this.props.intl.formatMessage({id: "lbl_title_error_404"}, {organizationName: window.PublicTicketApp.orgName});
		this.props.pageView(title, window.location.href);
	}
	
	// TODO: PMGR-8214 - This needs cosmetic work by Emma. & needs to be localized
	public render() {
		return  (
			<>
				<h1> Oops! </h1>
				<h3> We can't seem to find the page you're looking for. </h3>

				<h5> Here are some helpful links instead: </h5>

				<ul>
					<li>
						<Link to={Paths.ROOT}>Events</Link>
					</li>
				</ul>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		pageView: (title: string, url: string) => dispatch(AnalyticsActions.pageView(title, url))
	};
};

export default connect(null, mapDispatchToProps)(injectIntl<Error404ExcludingInjectedProps>(Error404));