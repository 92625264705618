import {InjectedIntlProps} from "react-intl";
import {Col, Row} from "reactstrap";
import {BasicStringKeyedMap} from "../models/basic-map";

interface QuantityTypeSummaryProps extends InjectedIntlProps {
	priceLevelQuantityMap: BasicStringKeyedMap<number>, 
	allocationName: string,
	rowClassName?: string,
}

export const QuantityTypeSummary = (props: QuantityTypeSummaryProps) => {
	const {priceLevelQuantityMap, allocationName, intl, rowClassName} = props;
	
	return (
		<Row className={rowClassName ? rowClassName : "ml-0"}>
			<Col sm={true} className="mb-2">
				<Row><h5>{intl.formatMessage({id: "lbl_QuantityHeading"})}</h5></Row>
				{Object.keys(priceLevelQuantityMap).map((key) => <Row key={key}>{`${key} x${priceLevelQuantityMap[key]}`}</Row>)}
			</Col>
			<Col sm={true} className="mb-2">
				<Row><h5>{intl.formatMessage({id: "lbl_Type"})}</h5></Row>
				<Row>{allocationName}</Row>
			</Col>
		</Row>
	)
}