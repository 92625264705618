import {Fragment, useEffect, useState} from "react";
import {InjectedIntl} from "react-intl";
import {Col, FormGroup, Input, Label} from "reactstrap";
import {getNameWithPriceString, PriceInfo} from "../../helpers/localization";
import {getPriceWithFeesIncluded, getTotalItemFees} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {AddToCartFeeDisplay} from "../add-to-cart/add-to-cart-fee-display";
import {DetailToggleButton} from "../detail-toggle-button";
import {FormElementLabel} from "../form-element-label/form-element-label";

interface PriceLevelQuantitySelectionProps {
	currencyCode: string;
	intl: InjectedIntl;
	onQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	priceLevelQuantityMap: BasicStringKeyedMap<string>;
	priceLevels: LevelDescriptor[];
	includeItemFees: boolean;
}

export const PriceLevelQuantitySelection = (props: PriceLevelQuantitySelectionProps) => {
	const {currencyCode, intl, onQuantityChange, priceLevelQuantityMap, priceLevels, includeItemFees} = props;
	const [priceLabelMap, setPriceLabelMap] = useState<BasicStringKeyedMap<string>>({});
	const [showPricingDetails, setShowPricingDetails] = useState(true);
	
	useEffect(() => {
		const _priceLabelMap = priceLevels.reduce((prevPriceLabelMap, priceLevel) => {
			let priceInfo: PriceInfo;
			if(priceLevel.minPrice != null && priceLevel.maxPrice != null) {
				// Only CYO subs will have a min and max price range, and we need to use that to generate the price string
				let minPrice = priceLevel.minPrice;
				let maxPrice = priceLevel.maxPrice;
				if(includeItemFees) {
					if(minPrice > 0) {
						minPrice += getTotalItemFees(priceLevel);
					}
					if(maxPrice > 0) {
						maxPrice += getTotalItemFees(priceLevel);
					}
				}
				priceInfo = {minPrice, maxPrice}
			} 
			else {
				// All other subs can have their price string obtained directly from the LevelDescriptor
				priceInfo = {minPrice: includeItemFees ? getPriceWithFeesIncluded(priceLevel) : priceLevel.price};
			}
			prevPriceLabelMap[priceLevel.id] = getNameWithPriceString(intl, currencyCode, priceLevel.name, priceInfo);
			return prevPriceLabelMap;
		}, {});
		setPriceLabelMap(_priceLabelMap);
	}, [currencyCode, includeItemFees, intl, priceLevels]);
	
	if (Object.keys(priceLabelMap).length < 1) {
		// Wait until we've initialized the priceLabelMap structure before rendering anything
		return null;
	}
	
	return (
		<div className="mb-4">
			<Fragment>
				<FormElementLabel intlLabelId="lbl_SelectQuantity"/>
				{priceLevels.map((priceLevel: LevelDescriptor, index: number) => {
					return (
						<FormGroup key={priceLevel.id} row={true}>
							<Label for={priceLevel.id} sm={6}>
								{priceLabelMap[priceLevel.id]}
								{includeItemFees && <AddToCartFeeDisplay priceLevel={priceLevel} isOpen={showPricingDetails} intl={intl} noBorder={true} />}
							</Label>
							<Col sm={6}>
								<Input
									id={priceLevel.id}
									min="0"
									name={priceLevel.id}
									aria-label={priceLevel.name + intl.formatMessage({id: "lbl_QuantitySuffix"})}
									onChange={onQuantityChange}
									type="number"
									value={priceLevelQuantityMap[priceLevel.id]}
									autoFocus={index === 0}
								/>
							</Col>
						</FormGroup>
					);
				})}
				{includeItemFees &&
					<DetailToggleButton
						onClick={() => setShowPricingDetails(prevState => !prevState)}
						detailsVisible={showPricingDetails}
						pricing={true}
					/>
				}
			</Fragment>
		</div>
	);
};