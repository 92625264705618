import * as React from "react";
import { Button } from "reactstrap";

interface ShareButtonProps {
	onClick?: () => void;
	icon?: any;
	context: string;
}

/**
 * ShareButton - For sharing a completed order via email, social media.
 * Also allows the user to add the event to their calendar.
 *
 * @param props context - Indicates type of share. E.g. 'email', 'twitter'.
 */
export const ShareButton: React.SFC<ShareButtonProps> = ({onClick, icon, context }) => {
		const IconComponent = icon;

		return (
			<div className="mb-1">
				{!!IconComponent && <IconComponent size="20"/>}
				<Button
					onClick={onClick}
					color="link"
					className="ml-2"
				>
					<span className="ml-1">
						{context}
					</span>
				</Button>

			</div>
		);
	};
