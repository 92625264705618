import * as React from 'react';
import {FormattedMessage, InjectedIntlProps} from "react-intl";
import {Button, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {AnyAction} from "redux";
import {ActionTypes} from '../../enums/action-types';
import {ApiActionNames} from "../../enums/api-action-names";
import {isBlockingActionsBusy} from "../../helpers/utilities";
import {InjectedInventoryServiceProps} from "../../hoc/inject-inventory-service";
import {BasicStringKeyedMap} from "../../models/basic-map";

interface PasscodeFormProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	eventInstanceId: string;
	fetchEvents: () => void;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

interface PasscodeFormState {
	passcode: string;
	error?: string;
}

export class PasscodeForm extends React.Component<PasscodeFormProps & InjectedIntlProps & InjectedInventoryServiceProps, PasscodeFormState> {
	public readonly state: PasscodeFormState = {
		passcode: ''
	};
	
	public render() {
		const {blockingActions, eventInstanceId, intl} = this.props;
		const {passcode, error} = this.state;
		const isBusy: boolean = isBlockingActionsBusy(blockingActions, ApiActionNames.VALIDATE_PASSCODE);
		const pHolder = intl.formatMessage({id: "lbl_Passcode"});
		const inputId: string = "passcode-" + eventInstanceId;
		
		return (
			<FormGroup className="mb-0">
				<InputGroup>
					<div className="mb-2 d-flex">
						<Input type="text" id={inputId} name="passcode" value={passcode} onChange={this.handleChange} placeholder={pHolder} invalid={!!error} />
						<InputGroupAddon addonType="append">
							<Button outline={true} color="primary" onClick={this.handleApply} disabled={isBusy}>
								{isBusy
									? <FormattedMessage id="lbl_Waiting"/>
									: <FormattedMessage id="lbl_Apply" />
								}
							</Button>
						</InputGroupAddon>
					</div>
					{!!error && <FormFeedback>{error}</FormFeedback>}
				</InputGroup>
			</FormGroup>
		);
	}
	
	private handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({passcode: evt.target.value, error: ''});
	}

	private handleApply = () => {
		
		const {
			clearAllMessages,
			eventInstanceId, 
			fetchEvents, 
			intl, 
			inventoryService,
			validatePasscode
		} = this.props;
		const {passcode} = this.state;

		// Clear error messages at the start of the submit operation
		clearAllMessages();

		// Make sure the passcode value is not blank
		if (!passcode) {
			this.setState({error: intl.formatMessage({id: "msg_passcode_cannot_be_blank"})});
			return;
		}
		validatePasscode(passcode.trim(), eventInstanceId)
			.then(result => {
				if (result.type === ActionTypes.API_SUCCESS) {
					//set the passcode, reset the eventDescriptor, and refetch the events
					window.PublicTicketApp.passcode = (!! result.data) ? result.data : '';
					
					inventoryService.expireCache();
					inventoryService.fetchEventDescriptor(eventInstanceId);
					fetchEvents();
				}
				return null;
			});
	}
	
}
