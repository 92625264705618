import {FormattedMessage, InjectedIntl} from "react-intl";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";

/**
 * This message populates the "errors" object with localized error messages generated from the passed in
 * collection of ApplicationMessage objects. In the case of ApplicationMessage objects that specify a field name
 * the errors are keyed by the Cart property they pertain to, so the fields on the form can be decorated with the
 * error class/message.
 * @param appMessages
 */
export const handleApplicationErrors = (appMessages: ApplicationMessage[], fieldMap: BasicStringKeyedMap<string>, intl: InjectedIntl): BasicStringKeyedMap<string> => {
	const errors: BasicStringKeyedMap<string> = {};
	appMessages.forEach((appMsg: ApplicationMessage, idx: number) => {
		// Default to the server generated validation error message
		let formattedMsg: string = (appMsg.msg as string) || "";

		// If there's a msgId property defined, use the id and args to get the localized message
		if (!!appMsg.msgId) {
			formattedMsg = intl.formatMessage({ id: appMsg.msgId }, appMsg.msgArgs);

			// This block of code is used to decorate the fields with field specific error messages.
			if (!!appMsg.msgArgs && ("fieldName" in appMsg.msgArgs)) {
				// If msgArgs contains the "fieldName" prop, identify the Cart property that is associated with that field (for example: firstname__c => Cart.firstName)
				const cartProp = fieldMap[appMsg.msgArgs.fieldName.toLowerCase()];
				if (!!cartProp) {
					// Put this error into the errors object
					errors[cartProp] = formattedMsg;
					return;
				}
			}

			// likely custom field validation error, use the custom field api name as the key
			if (!!appMsg.msgArgs && !!appMsg.msgArgs.fieldName ) {
				errors[appMsg.msgArgs.fieldName] = formattedMsg;
				return;
			}
		}

		// if all else fails and we land here then the error message is not likely linked to a specific input field validation error
		if (!!formattedMsg) {
			errors[formattedMsg] = formattedMsg;
		}
	});
	return errors;
};

/**
 * Takes the passed in map of validation errors and formats it into a JSX.Element suitable for rendering as an alert
 * @param errors
 */
export const formatValidationErrors = (errors: BasicStringKeyedMap<string>, msgId: string = "msg_problems_with_your_order"): JSX.Element => {
	const errorListItems: JSX.Element[] = [];
	Object.keys(errors).forEach((key: string, index: number) => {
		errorListItems.push(<li key={index}>{errors[key]}</li>);
	});
	return (
		<div>
			<FormattedMessage id={msgId}/>
			<ul>{errorListItems}</ul>
		</div>
	);
};
