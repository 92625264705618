import * as React from "react";
import {Redirect} from "react-router";

/**
 * Redirects to itself with the added nonFoundError state.
 *
 * Solving the keeping the url the same but showing a 404 instead.
 *
 *
 * @see https://github.com/ReactTraining/react-router/issues/4698#issuecomment-314419439
 * @constructor
 */
export const RouteNotFound: React.SFC = () => <Redirect to={{state: {notFoundError: true} }}/>;
