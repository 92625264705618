import {History} from "history";
import * as React from "react";
import {FormattedMessage, InjectedIntl} from "react-intl";
import {RouterProps} from "react-router";
import {AnyAction} from "redux";
import {ActionTypes} from "../../enums/action-types";
import {Severities} from "../../enums/severities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {PanelNav} from "../panel-nav";
import {MessageTypes, WaitingMessage} from "../waiting-message";
import {FieldGroup, FieldGroupTypes} from "../field-group";

export interface GiftCardApplyFormProps extends RouterProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	cart: Cart;
	clearAllMessages: () => void;
	config: PublicTicketAppConfig;
	intl: InjectedIntl;
	showAlert: (alertBody: React.ReactNode, alertSeverity: Severities) => void;
	submitCart: (history: History) => Promise<any>;
	saveProps: (props: any) => void;
}

interface PropsFromPaymentForm {
	hideGiftCardApply: () => void;
	onGiftCardApplyCancel: () => void;
}


export class GiftCardApplyForm extends React.Component<GiftCardApplyFormProps & PropsFromPaymentForm> {
	
	public render() {
		const {blockingActions, cart, config, hideGiftCardApply, intl, onGiftCardApplyCancel} = this.props;
		const isBusy: boolean = Object.keys(blockingActions).length > 0;

		const gcBalance = cart.gcBalance || 0;
		const formattedGCBalance = intl.formatNumber((gcBalance), {style: "currency", currency: config.currencyCode});

		const formattedAmtDue = intl.formatNumber((cart.amtDue), {style: "currency", currency: config.currencyCode});
		
		const amtDueAfterGC = gcBalance > cart.amtDue ? 0 : cart.amtDue - gcBalance;
		const gcBalanceAfterOrder = Math.max(gcBalance - cart.amtDue, 0.0);
		const formattedAmtDueAfterGC = intl.formatNumber((amtDueAfterGC), {style: "currency", currency: config.currencyCode});
		
		const gcCoversBalance = gcBalance >= cart.amtDue;
		let message = null;
		let question = null;
		let saveGiftCardOnFileCheckbox = null;
		let panelNav = null;
		
		if (gcBalance <= 0) {
			message = <FormattedMessage id="msg_gift_card_balance_is_empty" />;
			panelNav = (
				<PanelNav
					next={{
						label: intl.formatMessage({id: "lbl_button_OK"}),
						handleClick: onGiftCardApplyCancel,
						isDisabled: isBusy
					}}
				/>
			);
		} else {
			if (amtDueAfterGC <= 0) {
				message = (
					<FormattedMessage
						id="msg_gift_card_balance_ge_amount_due"
						values={{
							gcBalance: formattedGCBalance,
						}}
					/>
				);
				question = <FormattedMessage id="msg_apply_gift_card_and_submit_order" />;
				panelNav = (
					<PanelNav
						next={{
							label: intl.formatMessage({id: "lbl_Submit"}),
							handleClick: (gcCoversBalance) ? this.handleSubmit : hideGiftCardApply,
							isDisabled: isBusy
						}}
						back={{
							label: intl.formatMessage({id: "lbl_No"}),
							handleClick: onGiftCardApplyCancel,
							isDisabled: isBusy
						}}
					/>
				);
			} else if (amtDueAfterGC > 0) {
				message = (
					<FormattedMessage
						id="msg_gift_card_balance_lt_amount_due"
						values={{
							gcBalance: formattedGCBalance,
							amountDue: formattedAmtDue,
							ccPaymentAmount: formattedAmtDueAfterGC,
						}}
					/>
				);
				question = <FormattedMessage id="msg_apply_gift_card" />;
				panelNav = (
					<PanelNav
						next={{
							label: intl.formatMessage({id: "lbl_Yes"}),
							handleClick: (gcCoversBalance) ? this.handleSubmit : hideGiftCardApply,
							isDisabled: isBusy
						}}
						back={{
							label: intl.formatMessage({id: "lbl_No"}),
							handleClick: onGiftCardApplyCancel,
							isDisabled: isBusy
						}}
					/>
				);
			}
			
			if (config.canSavePaymentMethods && cart.gcNumber != null && 0 < gcBalanceAfterOrder) {
				saveGiftCardOnFileCheckbox = (
					<FieldGroup
						id='saveGiftCardOnFile'
						name='saveGiftCardOnFile'
						type={FieldGroupTypes.CHECKBOX}
						label={intl.formatMessage({id: "lbl_SaveCardForFuturePurchase"})}
						value={cart.saveGiftCardOnFile}
						onChange={this.onSaveGiftCardOnFileChangedHandler}
					/>
				);
			}

		}
		
		return (
			<div style={{minHeight: '300px'}} className="d-flex flex-column">
				<div className="mb-4">
					<p>{message}</p>
					<p>{question}</p>
					{saveGiftCardOnFileCheckbox}
				</div>
				
				<div className="mt-auto">
					<WaitingMessage isOpen={isBusy} type={MessageTypes.SUBMITTING}/>
					{panelNav}
				</div>
			</div>
		);
	}

	private onSaveGiftCardOnFileChangedHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.props.saveProps({[evt.target.name]: evt.target.checked});
	}

	private handleSubmit = () => {
		const {clearAllMessages, config, intl, showAlert, submitCart, history} = this.props;
		
		// Clear errors first
		clearAllMessages();
		submitCart(history)
			.then(result => {
				if (result.type === ActionTypes.API_SUCCESS) {
					if (!!result.data.messages[0] && result.data.messages[0].msgId === "msg_new_gift_card_balance") {
						const newBalance = result.data.messages[0].msgArgs.newBalance;
						const formattedNewBalance = intl.formatNumber((newBalance), {
							style: "currency",
							currency: config.currencyCode
						});
						showAlert(intl.formatMessage({id: "msg_new_gift_card_balance"}, {newBalance: formattedNewBalance}), Severities.INFO);
					}
				}
			});
		return true;
	}
}