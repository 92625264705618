import * as React from "react";
import {Container} from "reactstrap";

interface ErrorBoundaryState {
	hasError: boolean;
	error?: any;
	info?: any;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
	public readonly state: ErrorBoundaryState = {
		hasError: false
	};

	public componentDidCatch(error: any, info: any) {
		// Display fallback UI
		this.setState({hasError: true, error, info});
		console.error(error, info);	// eslint-disable-line no-console
	}
	
	public render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Container>
					<div className="alert alert-danger" role="alert">
						<h3>Uncaught error!</h3>
						<h4>{this.state.error.message}</h4>
						<p><pre>{this.state.info.componentStack}</pre></p>
					</div>
				</Container>
			);
		}
		return this.props.children;
	}
}
