import {isEmpty} from "lodash";
import * as React from "react";
import {FormattedMessage, InjectedIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {AlertOptions} from "../../actions/public-ticket-app-actions";
import {ApiAction} from "../../api/api-action";
import {OperationResult} from "../../api/operation-result";
import {Paths} from "../../enums/paths";
import {Severities} from "../../enums/severities";
import {formatValidationErrors} from "../../helpers/checkout-form-utils";
import {scrubbedValue} from "../../helpers/utilities";
import {ApplicationMessage} from "../../models/application-message";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {PicklistEntryDescriptor} from "../../models/public-ticket-app/picklist-entry-descriptor";
import {SCPLOptions} from "../../models/public-ticket-app/scpl-options";
import {UserProfile} from "../../models/public-ticket-app/user-profile";
import {FieldGroup, FieldGroupTypes} from "../field-group";
import {EmailIcon, PhoneIcon} from "../icons/icons";
import {MainContentHeader} from "../main-content-header";
import {RequiredFieldLegend} from "../required-field-legend";
import {StateField} from "../state-field";

/**
 * All properties available within this component
 */
interface PortalProfileProps {
	config: PublicTicketAppConfig;
	user: UserProfile;
	intl: InjectedIntl;
	clearAllMessages: () => void;
	pageView: (title: string, url: string) => void;
	updateUserProfile: (user: UserProfile) => Promise<ApiAction<OperationResult<UserProfile>>>;
	showAlert: (alertOptions: AlertOptions) => void;
}

interface PortalProfileState {
	isEditing: boolean;
	form: PortalProfileForm;  // the actual form
	fieldLabels: PortalProfileForm; // labels for each form element
	errors: Partial<PortalProfileForm>; // errors that could possibly be for each element
}

interface PortalProfileForm {
	firstName: string;
	lastName: string;
	salutation: string;
	pronouns: string;
	email: string;
	emailConfirm: string;
	phone: string;
	street: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
}

export class Profile extends React.Component<PortalProfileProps, PortalProfileState> {

	public readonly state: PortalProfileState = {
		isEditing: false,
		errors: {},
		form: {
			firstName: this.props.user.firstName,
			lastName: this.props.user.lastName,
			salutation: this.props.user.salutation,
			pronouns: this.props.user.pronouns,
			email: this.props.user.email,
			emailConfirm: '',
			phone: this.props.user.phone,
			street: this.props.user.street,
			city: this.props.user.city,
			state: this.props.user.state,
			postalCode: this.props.user.postalCode,
			country: this.props.user.country,
		},
		fieldLabels: {
			firstName: this.props.intl.formatMessage({id: "user.firstname"}),
			lastName: this.props.intl.formatMessage({id: "user.lastname"}),
			salutation: this.props.intl.formatMessage({id: "user.salutation"}),
			pronouns: this.props.intl.formatMessage({id: "user.pronouns"}),
			email: this.props.intl.formatMessage({id: "user.email"}),
			emailConfirm: this.props.intl.formatMessage({id: "pmgr_lbl_ConfirmEmail"}),
			phone: this.props.intl.formatMessage({id: "user.phone"}),
			street: this.props.intl.formatMessage({id: "user.street"}),
			city: this.props.intl.formatMessage({id: "user.city"}),
			state: this.props.intl.formatMessage({id: "user.state"}),
			postalCode: this.props.intl.formatMessage({id: "user.postalcode"}),
			country: this.props.intl.formatMessage({id: "user.country"}),
		}
	};

	// map of field api names to corresponding form
	private readonly fieldMap: BasicStringKeyedMap<string> = {
		firstname: 'firstName',
		lastname: 'lastName',
		salutation: 'salutation',
		pronouns: 'pronouns',
		email: 'email',
		phone: 'phone',
		street: "street",
		city: 'city',
		state: 'state',
		postalcode: 'postalCode',
		country: 'country'
	};

	private readonly salutationOptions: JSX.Element[];
	private readonly scplCountryOptions: JSX.Element[];

	constructor(props: PortalProfileProps) {
		super(props);
		const {config} = props;

		// Construct country picklist when the SF state and country picklist feature is enabled in the org
		if (config.isSCPLEnabled && !!config.scplOptions) {
			this.scplCountryOptions = config.scplOptions.map((scplOption: SCPLOptions) => {
				return <option key={scplOption.country} value={scplOption.country}>{scplOption.country}</option>
			});
		}

		this.salutationOptions = config.salutations.map((salutation: PicklistEntryDescriptor) => {
			return <option key={salutation.value} value={salutation.value}>{salutation.label}</option>;
		});
	}

	public componentDidMount(): void {
		const {intl, pageView, user, config} = this.props;
		pageView(intl.formatMessage({id: "lbl_title_portal_profile"}), window.location.href);

		// if state and country picklist are enabled and no country is already selected, set to the default (first) option
		if(config.isSCPLEnabled && !user.country){
			this.setState({
				form: {
					...this.state.form,
					country: config.scplOptions[0].country
				}
			})
		}
	}

	public render() {
		const {isEditing} = this.state;
		return (
			<div>
				<MainContentHeader intlId="lbl_MyProfile" />
				{isEditing
					? this.renderForm()
					: this.renderProfileInfo()
				}
			</div>
		);
	}

	private renderProfileInfo = () => {
		const {
			firstName, lastName, email, phone, street, city, state, country, postalCode, salutation, pronouns
		} = this.props.user;
		const {
			arePronounsEnabled, isSalutationDisabled
		} = this.props.config;
		return (
			<div>
				<h5>{!isSalutationDisabled && salutation} {firstName} {lastName}</h5>
				<div className="mb-3">
					{arePronounsEnabled && 
						<p data-testid="pronouns" className="mb-1">
							{pronouns}
						</p>
					}
					<p className="mb-1">
						<span className="mr-2"><EmailIcon /></span>
						{email || <FormattedMessage id="lbl_None" />}
					</p>
					<p className="mb-1">
						<span className="mr-2"><PhoneIcon /></span>
						{phone || <FormattedMessage id="lbl_None" />}
					</p>
				</div>
				<div className="mb-3">
					<p className="small border-bottom m-0">
						<FormattedMessage id="lbl_Address" />
					</p>
					<p className="mb-0">{street}</p>
					<p className="mb-0">
						<span className="mr-1">{city}</span>
						<span className="mr-1">{state}</span>
					</p>
					<p className="mb-0">{country}</p>
					<p className="mb-0">{postalCode}</p>
				</div>
				<div>
					<Link className="mr-2" to={Paths.PORTAL__CHANGE_PASSWORD}>
						<FormattedMessage id="lbl_ChangePassword" />
					</Link>
					<Button id="EditProfile" color="primary" onClick={this.toggleEditing}>
						<FormattedMessage id="lbl_button_EditProfile" />
					</Button>
				</div>
			</div>
		);
	}

	private renderForm = () => {
		const {form, errors} = this.state;
		const {config, intl} = this.props;
		return (
			<div>
				<RequiredFieldLegend />

				{!config.isSalutationDisabled && 
					<FieldGroup
						id="salutation"
						name="salutation"
						data-testid="salutation"
						type={FieldGroupTypes.SELECT}
						label={this.state.fieldLabels.salutation}
						value={this.state.form.salutation || ""}
						selectionOptions={this.salutationOptions}
						onChange={this.handleChange}
						invalid={!!errors.salutation}
						feedbackMessage={errors.salutation}
						required={false}
					/>
				}

				<FieldGroup
					id="firstName"
					name="firstName"
					type={FieldGroupTypes.TEXT}
					label={this.state.fieldLabels.firstName}
					value={form.firstName}
					onChange={this.handleChange}
					invalid={!!errors.firstName}
					feedbackMessage={errors.firstName}
					required={true}
				/>

				<FieldGroup
					id="lastName"
					name="lastName"
					type={FieldGroupTypes.TEXT}
					label={this.state.fieldLabels.lastName}
					value={this.state.form.lastName}
					onChange={this.handleChange}
					invalid={!!errors.lastName}
					feedbackMessage={errors.lastName}
					required={true}
				/>

				{config.arePronounsEnabled && 
					<FieldGroup
						id="pronouns"
						name="pronouns"
						type={FieldGroupTypes.TEXT}
						label={this.state.fieldLabels.pronouns}
						value={this.state.form.pronouns}
						onChange={this.handleChange}
						invalid={!!errors.pronouns}
						feedbackMessage={errors.pronouns}
						required={false}
					/>
				}

				<FieldGroup
					id="email"
					name="email"
					type={FieldGroupTypes.EMAIL}
					label={this.state.fieldLabels.email}
					value={this.state.form.email}
					onChange={this.handleChange}
					invalid={!!errors.email}
					feedbackMessage={errors.email}
					required={true}
				/>

				<FieldGroup
					id="emailConfirm"
					name="emailConfirm"
					type={FieldGroupTypes.EMAIL}
					label={"Verify Email"}
					value={this.state.form.emailConfirm}
					onChange={this.handleChange}
					invalid={!!errors.emailConfirm}
					feedbackMessage={errors.emailConfirm}
					required={true}
				/>

				<FieldGroup
					id="phone"
					name="phone"
					type={FieldGroupTypes.TEXT}
					label={this.state.fieldLabels.phone}
					value={this.state.form.phone}
					onChange={this.handleChange}
					invalid={!!errors.phone}
					feedbackMessage={errors.phone}
					required={true}
				/>

				<FieldGroup
					id="street"
					name="street"
					type={FieldGroupTypes.TEXTAREA}
					label={this.state.fieldLabels.street}
					value={this.state.form.street}
					onChange={this.handleChange}
					invalid={!!errors.street}
					feedbackMessage={errors.street}
					required={true}
				/>

				<FieldGroup
					id="city"
					name="city"
					type={FieldGroupTypes.TEXT}
					label={this.state.fieldLabels.city}
					value={this.state.form.city}
					onChange={this.handleChange}
					invalid={!!errors.city}
					feedbackMessage={errors.city}
					required={true}
				/>

				<StateField 
					id="state"
					name="state"
					label={this.state.fieldLabels.state}
					value={this.state.form.state}
					onChange={this.handleStateFieldChange}
					invalid={!!errors.state}
					feedbackMessage={errors.state}
					required={true}
					disabled={false}
					isSCPLEnabled={config.isSCPLEnabled}
					scplOptions={config.scplOptions}
					stateCodes={config.stateCodes}
					selectedCountry={this.state.form.country}
					intl={intl}
				/>

				<FieldGroup
					id="postalCode"
					name="postalCode"
					type={FieldGroupTypes.TEXT}
					label={this.state.fieldLabels.postalCode}
					value={this.state.form.postalCode}
					onChange={this.handleChange}
					invalid={!!errors.postalCode}
					feedbackMessage={errors.postalCode}
					required={true}
				/>

				{config.isSCPLEnabled ? (
					<FieldGroup
						id="country"
						name="country"
						type={FieldGroupTypes.SELECT}
						label={this.state.fieldLabels.country}
						value={this.state.form.country}
						selectionOptions={this.scplCountryOptions}
						onChange={this.handleChange}
						invalid={!!errors.country}
						feedbackMessage={errors.country}
						required={false}
					/>
				) : (
					<FieldGroup
						id="country"
						name="country"
						type={FieldGroupTypes.TEXT}
						label={this.state.fieldLabels.country}
						value={this.state.form.country}
						onChange={this.handleChange}
						invalid={!!errors.country}
						feedbackMessage={errors.country}
						required={false}
					/>
				)}
				
				<div className="text-right mt-3">
					<Button id="CancelProfileEdit" size="sm" color="link" onClick={this.handleCancel}>
						<span className="text-danger">
							<FormattedMessage id="lbl_button_Cancel" />
						</span>
					</Button>
					<Button id="SaveProfileEdit" color="primary" onClick={this.handleSubmit}>
						<FormattedMessage id="lbl_Save" />
					</Button>
				</div>
			</div>
		);
	}

	private toggleEditing = () => {
		this.setState({
			isEditing: !this.state.isEditing,
		});
	}

	private handleCancel = () => {
		const {clearAllMessages, user} = this.props;
		clearAllMessages();
		const form = {...user, emailConfirm: ''};
		this.setState({
			isEditing: false,
			form,
			errors: {},
		});
	}

	private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			form: {
				...this.state.form,
				[event.target.name]: scrubbedValue(event)
			}
		});
	}

	private handleStateFieldChange = (fieldName: string, fieldValue: string) => {
		this.setState({form: {...this.state.form, [fieldName]: fieldValue}});
	}

	private createUser = (form: PortalProfileForm): UserProfile => {
		const {user} = this.props;

		return {
			...user, // copy existing user values before overwriting them with
			firstName: form.firstName,
			lastName: form.lastName,
			salutation: form.salutation,
			pronouns: form.pronouns,
			email: form.email,
			phone: form.phone,
			street: form.street,
			city: form.city,
			state: form.state,
			postalCode: form.postalCode,
			country: form.country,
		};
	}

	private handleSubmit = () => {
		const {clearAllMessages, updateUserProfile} = this.props;

		// clear error messages at the start of the submit operation
		clearAllMessages();

		// perform any client-sided validation
		if (!this.validate()) {
			return;
		}

		// attempt to update profile, which could result with server-sided validation errors
		updateUserProfile(this.createUser(this.state.form))
			.then((result) => {
				this.handleApplicationMessageErrors(result.data.applicationMessages);
				const noErrors = isEmpty(this.state.errors);
				if (noErrors) {
					this.toggleEditing();
				}
			});
	}

	/**
	 * Validates the form with client sided form validation.
	 */
	private validate = () => {
		const {user, intl, showAlert} = this.props;
		const {form} = this.state;

		const errors: BasicStringKeyedMap<string> = {};

		if (form.email !== user.email && form.email !== form.emailConfirm) {
			errors.emailConfirm = intl.formatMessage({id: "msg_email_mismatch"});
		}

		const isValid = Object.keys(errors).length === 0;

		if (!isValid) {
			showAlert({alertBody: formatValidationErrors(errors, "msg_problems_with_your_profile"), alertSeverity: Severities.ERROR});
		}

		this.setState({errors});
		return isValid;
	}

	private handleApplicationMessageErrors = (validationErrors: ApplicationMessage[]) => {
		const {showAlert} = this.props;

		const errors = this.extractAndLocalizeApplicationMessageFormErrors(validationErrors);

		// call showAlert only when there are actual errors to display
		if (Object.keys(errors).length > 0) {
			showAlert({alertBody: formatValidationErrors(errors, "msg_problems_with_your_profile"), alertSeverity: Severities.ERROR});
		}

		this.setState({errors});
	}

	private extractAndLocalizeApplicationMessageFormErrors = (validationErrors: ApplicationMessage[]) => {
		const {intl} = this.props;

		// application message must have a field name or else we are just guessing.
		const errors = {};

		validationErrors.forEach((error) => {
			let errorMessage;
			let errorName;

			switch (error.msgId) {
				case 'msg_is_required':
					const formFieldName = this.fieldMap[error.msgArgs.fieldName];
					const formLabel = error.msgArgs.fieldLabel;

					errorName = formFieldName;
					errorMessage = intl.formatMessage({id: error.msgId}, {fieldLabel: formLabel});
					break;
				case 'msg_unexpected_error':
					errorMessage = intl.formatMessage({id: 'msg_unexpected_error_occurred'});
					errorName = 'unexpectedError';
					break;
				default:
					errorMessage = error.msg;
					// just in case there are more than one uncategorized error messages, using time so they don't override each other
					errorName = `uncategorizedError_${Date.now()}`;
					break;
			}

			errors[errorName] = errorMessage;
		});

		return errors;
	}

}
