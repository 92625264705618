import * as React from 'react';
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {RouterProps} from "react-router";
import {AnyAction} from "redux";
import {AnalyticsActions} from "../../actions/analytics-actions";
import {ApiActions} from "../../actions/api-actions";
import {ChangePasswordForm} from "../../components/portal/change-password-form";
import {ActionTypes} from "../../enums/action-types";
import {Paths} from "../../enums/paths";
import {formatApplicationMessages} from "../../helpers/utilities";
import {ApplicationMessage} from "../../models/application-message";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import ApplicationAlert from "../application-messages/application-alert";
import {ThunkDispatch} from "redux-thunk";
import {RootState} from "../../reducers";


interface ExpiredPasswordProps extends InjectedIntlProps, RouterProps, ExpiredPasswordDispatchProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	config: PublicTicketAppConfig;
}

interface ExpiredPasswordState {
	appMessage: ApplicationMessage | null;
}

interface ExpiredPasswordDispatchProps {
	changePassword: (oldPassword: string, newPassword: string, verifyPassword: string) => Promise<any>;
	pageView: (title: string, url: string) => void;
}

interface ExpiredPasswordStateProps {
	config: PublicTicketAppConfig;
}

export class ExpiredPassword extends React.Component<ExpiredPasswordProps, ExpiredPasswordState> {
	public readonly state: ExpiredPasswordState = {
		appMessage: null
	};
	
	public componentDidMount(): void {
		const {intl, pageView} = this.props;
		pageView(intl.formatMessage({id: "lbl_title_portal_change_password"}), window.location.href);
	}

	public render(): JSX.Element {
		const {appMessage} = this.state;
		return (
			<>
				{appMessage && (<ApplicationAlert message={appMessage} onClose={this.clearMessages} />)}
				
				<ChangePasswordForm
					blockingActions={this.props.blockingActions}
					changePassword={this.handleChangePassword}
					changePasswordMessageId="msg_previous_password_expired"
					history={this.props.history}
					intl={this.props.intl}
					oldPasswordRequired={false}
					pageView={this.props.pageView}
				/>
			</>
		);
	}
	
	private handleChangePassword = (oldPassword: string, newPassword: string, verifyPassword: string) => {
		this.props.changePassword(oldPassword,newPassword,verifyPassword)
			.then((result) => {
				if (result.type === ActionTypes.API_SUCCESS) {
					// This needs to be a reload so we set isPasswordExpired again, which should be false at this point,
					// We will still keep them on the current page unless they're on the change password page.
					// In that case redirect them to the portal page
					if (this.props.history.location.pathname === Paths.PORTAL__CHANGE_PASSWORD){
						window.location.hash = '#' + Paths.PORTAL;
					}
					window.location.reload();
					
				} else if (result.type === ActionTypes.API_FAILURE){
					if (!!result.errors) {
						const appMessages: ApplicationMessage[] = [];
						result.errors.forEach((error: BasicStringKeyedMap<any>) => {
							formatApplicationMessages(error).forEach((message: ApplicationMessage) => {
								appMessages.push(message);
							});
						});
						// there should only be 1 message.
						this.setState({appMessage: appMessages[0]});
					}
				}
			});
	}
	
	private clearMessages = () => {
		this.setState({appMessage: null});
	}
}

const mapStateToProps = (state: any): ExpiredPasswordStateProps => {
	return {
		config: state.ptApp.config
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>): ExpiredPasswordDispatchProps => {
	return {
		changePassword: (oldPassword,newPassword,verifyPassword): Promise<any> => {
			return dispatch(ApiActions.changePassword(oldPassword,newPassword,verifyPassword));
		},
		pageView: (title: string, url: string) => {
			dispatch(AnalyticsActions.pageView(title, url));
		}
	};
};

export const ExpiredPasswordConnected = injectIntl(connect(mapStateToProps,mapDispatchToProps)(ExpiredPassword));
