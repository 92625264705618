import {useCallback, useEffect, useState} from "react";
import {InjectedIntl} from "react-intl";
import {FormGroup} from "reactstrap";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {LevelDescriptor} from "../../models/event-descriptor/level-descriptor";
import {FormElementLabel} from "../form-element-label/form-element-label";
import {RadioButton} from "./radio-button";

interface AllocationSelectionProps {
	allocations: AllocationDescriptor[];
	intl: InjectedIntl;
	onAllocationChange: (allocationId: string) => void;
	selectedAllocationId: string;
	subscriptionPriceLevelsMap: BasicStringKeyedMap<LevelDescriptor[]>;
}

export const AllocationSelection = (props: AllocationSelectionProps) => {
	const {allocations, onAllocationChange, selectedAllocationId, subscriptionPriceLevelsMap} = props;
	const [allocationsWithPriceLevels, setAllocationsWithPriceLevels] = useState<AllocationDescriptor[]>([]);
	
	const handleAllocationChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		onAllocationChange(event.target.value);
	}, [onAllocationChange]);
	
	useEffect(() => {
		const _allocationsHavingPriceLevels = allocations.filter((allocation: AllocationDescriptor) => {
			const priceLevels = subscriptionPriceLevelsMap[allocation.id];
			return !!priceLevels && priceLevels.length > 0;
		});
		setAllocationsWithPriceLevels(_allocationsHavingPriceLevels);
	}, [allocations, onAllocationChange, subscriptionPriceLevelsMap]);
	
	return (
		<div className="mb-4">
			<FormElementLabel intlLabelId="lbl_SelectOneOfTheFollowing" />
			<FormGroup check={true}>
				{allocationsWithPriceLevels.map((allocation: AllocationDescriptor) => (
					<RadioButton
						key={allocation.id}
						label={allocation.name}
						name="allocation"
						handleChange={handleAllocationChange}
						value={allocation.id}
						defaultChecked={allocation.id === selectedAllocationId}
					/>
				))}
			</FormGroup>
		</div>
	);
}