import * as React from 'react';
import {InjectedIntl, InjectedIntlProps} from "react-intl";
import {RouterProps} from "react-router";
import {Col, Row} from "reactstrap";
import {AnyAction} from "redux";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {ChangePasswordForm} from "./change-password-form";

interface ChangePasswordProps extends InjectedIntlProps, RouterProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	changePassword: (oldPassword: string, newPassword: string, verifyPassword: string) => void;
	intl: InjectedIntl;
	pageView: (title: string, url: string) => void;
}

export class ChangePassword extends React.Component<ChangePasswordProps> {
	public componentDidMount() {
		const {intl, pageView} = this.props;
		pageView(intl.formatMessage({id: "lbl_title_portal_change_password"}), window.location.href);
	}
	
	public render(): JSX.Element {
		return (
			<Row>
				<Col md="6">
					<ChangePasswordForm
						blockingActions={this.props.blockingActions}
						changePassword={this.props.changePassword}
						history={this.props.history}
						intl={this.props.intl}
						oldPasswordRequired={true}
						pageView={this.props.pageView}
					/>
				</Col>
			</Row>
		);
	}
}
