import * as React from "react";
import {FormattedMessage, FormattedNumber, InjectedIntl, InjectedIntlProps, injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import {AnyAction} from "redux";
import {TicketableEventTypes} from "../../enums/ticketable-event-types";
import {getNormalizedEventsUrl, NormalizedEventsUrl} from "../../helpers/routing";
import {getTicketFees, ORDER_FEE_PROPS} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {FeeDescriptor} from "../../models/fee-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {Ticket} from "../../models/ticket";
import {getEventItems} from "./helpers";
import {NonTicketItem} from "./non-ticket-item";
import {WrappedEventItem as EventItem} from "./wrapped-components";
import {SubscriptionTicketDisplay} from "../../models/subscription/subscription-ticket-display";
import {SubscriptionItem} from "./subscription-item";

///
/// Interfaces
///

/**
 *  All properties available within this component
 */
interface TicketOrderProps extends TicketOrderPropsExcludingInjectedProps, InjectedIntlProps {}

/**
 * All properties that should be defined when using the component exported with injections.
 */
export interface TicketOrderPropsExcludingInjectedProps {
	// displays the "Remove" and "Edit" buttons to allow changes
	allowEdit?: boolean;
	
	// map of in-flight API actions that block the UI
	blockingActions: BasicStringKeyedMap<AnyAction>;
	
	// Redux store's state.cart
	cart: Cart;
	
	// a callback to the function that updates the price level for a given cart item.
	changeItemPriceLevel?: (cartItemId: string, priceLevelId: string) => void;
	
	// contains configuration settings required to render properly.
	config: PublicTicketAppConfig;

	deleteCartItems?: (cartId: string, cartItems: CartItem[], modstamp?: number | null) => Promise<any>;

	// if true, details will be expanded and the hide/show details link hidden
	detailsExpanded?: boolean;
	
	// a callback to set the status of a "Pending Renewal" order as "Did Not Renew".
	doNotRenew?: () => any;
	
	// a callback to delete all items from the current Cart. Only works for the "Draft" Cart. Not applicable to "Pending Renewal" Cart.
	emptyCart?: () => any;
	
	// Redux store's state.ptsApp.itemFeeData
	itemFeeData: FeeDescriptor[];

	// Redux store's state.ptsApp.itemFeeData
	orderFeeData: FeeDescriptor[];

	// Property from the eventDescriptorCache that tracks Subscriptions PYOS-enabled subs that are fulfillable online
	pyosEnabledSubscriptionEventDescriptors?: BasicStringKeyedMap<string>;
	
	// a callback to whatever code removes the seat from the cart.
	removeItem?: (cartItemId: string) => void;

	// the currently selected Event Descriptor
	selectedEI?: EventDescriptor | null;

	// a callback to show a modal confirmation dialog
	showModalConfirmation?: (body: React.ReactNode, confirmationCallback: () => any, intl: InjectedIntl) => void;
}

export interface EventItemProps {
	id: string;
	eiName: string;
	teName: string;
	ticketType?: TicketableEventTypes;
	tickets: Ticket[];
}

///
/// Component
///

/**
 * Component that displays a PTS order
 */
export class TicketOrder extends React.Component<TicketOrderProps> {
	public static defaultProps: Partial<TicketOrderProps> = {
		allowEdit: true,
		detailsExpanded: false
	};

	public render() {
		const {
			blockingActions,
			cart,
			config,
			deleteCartItems,
			emptyCart,
			intl,
			itemFeeData,
			orderFeeData,
			pyosEnabledSubscriptionEventDescriptors,
			showModalConfirmation,
			...other
		} = this.props;
		
		// Because TypeScript sucks, you need to convince it this value will never be undefined; hence the ! at the end.
		// See https://github.com/Microsoft/TypeScript/issues/23812 and https://medium.com/@martin_hotell/react-typescript-and-defaultprops-dilemma-ca7f81c661c7
		const allowEdit = this.props.allowEdit!;
		const detailsExpanded = this.props.detailsExpanded!;

		const {
			subtotal,
			orderTotal,
			cartItems,
			donationAmt,
			delMethod,
			salesTaxTotal,
		} = cart;

		const {eventsUrl, salesTaxLabel} = config;
		
		if (!cart.id) {
			return null;
		}
		
		// Split up the CartItem collection into Subscription objects and non-subscription retail CartItems
		const subscriptions: SubscriptionTicketDisplay[] = SubscriptionTicketDisplay.getSubscriptionTicketDisplayStructure(cartItems, intl);
		const nonSubscriptionItems: CartItem[] = cartItems.filter(cartItem => {
			return !cartItem.stoiId && cartItem.ticketType !== TicketableEventTypes.SUBSCRIPTION;
		});
		const eventItems: EventItemProps[] = getEventItems(nonSubscriptionItems, itemFeeData, config, intl);
		const orderFees = getTicketFees(cart, ORDER_FEE_PROPS, orderFeeData, config, intl);
		
		let addMoreLink = null;
		const normalizedEventsUrl: NormalizedEventsUrl | null = getNormalizedEventsUrl(eventsUrl);
		if (!!normalizedEventsUrl) {
			addMoreLink = normalizedEventsUrl.isInternal
				? <Link className="btn btn-outline-primary" to={normalizedEventsUrl.eventsUrl}><FormattedMessage id="lbl_AddMoreItems"/></Link>
				: <a className="btn btn-outline-primary" href={normalizedEventsUrl.eventsUrl}><FormattedMessage id="lbl_AddMoreItems"/></a>;
		}
		
		return (
			<div className="pts-ticket-order">
				<Row>
					<Col>
						{
							subscriptions.map(subscription => {
								const allowFulfillment = pyosEnabledSubscriptionEventDescriptors && (subscription.eiId in pyosEnabledSubscriptionEventDescriptors);
								return (
									<SubscriptionItem
										key={subscription.allocId}
										subscription={subscription}
										itemFeeData={itemFeeData}
										config={config}
										intl={intl}
										allowEdit={allowEdit}
										allowFulfillment={allowFulfillment}
										allowNavigation={cart.orderStatus === 'Draft'}
										blockingActions={blockingActions}
										detailsExpanded={detailsExpanded}
										cart={cart}
										deleteCartItems={deleteCartItems}
										{...other}
									/>
								);
							})
						}

						{
							eventItems.map(eventItem => (
								<EventItem
									key={eventItem.id}
									eventInstanceId={eventItem.id}
									eventInstanceName={eventItem.eiName}
									ticketableEventName={eventItem.teName}
									ticketableEventType={eventItem.ticketType}
									tickets={eventItem.tickets}
									config={config}
									intl={intl}
									allowEdit={allowEdit}
									allowNavigation={cart.orderStatus === 'Draft'}
									blockingActions={blockingActions}
									detailsExpanded={detailsExpanded}
									{...other}
								/>
							))
						}
					</Col>
				</Row>

				{addMoreLink && (
					<Row>
						<Col>
							<p className="pts-cart-add-more-message py-2 pr-2 small text-left">
								{addMoreLink}
							</p>
						</Col>
					</Row>
				)}

				<Row className="my-3 font-weight-bold" >
					<Col>
						<FormattedMessage id={"lbl_Subtotal"} />
					</Col>
					<Col className="text-right">
						<span className="amount">
							<FormattedNumber
								value={subtotal}
								style="currency"
								currency={config.currencyCode}
								currencyDisplay="symbol"
							/>
						</span>
					</Col>
				</Row>

				<Row>
					<Col>
						{
							orderFees.map((item, i) => (
								<NonTicketItem
									key={`fee-${item.label}-${i}`}
									name={item.label}
									amount={item.value}
									description={item.prop === 'deliveryFee' ? delMethod : ''}
									currencyCode={config.currencyCode}
								/>
							))
						}
					</Col>
				</Row>

				{
					donationAmt &&
						<NonTicketItem
							name={intl.formatMessage({id:"lbl_Donation"})}
							amount={donationAmt}
							currencyCode={config.currencyCode}
						/>
				}

				{
					!!salesTaxTotal  &&
						<NonTicketItem
							name={!!salesTaxLabel ? salesTaxLabel : intl.formatMessage({id:"lbl_SalesTax"})}
							amount={salesTaxTotal}
							currencyCode={config.currencyCode}
						/>
				}

				<Row className="d-flex mb-5 align-items-center">
					<Col>
						<span className="total">
							<FormattedMessage id="lbl_GrandTotal"/>
						</span>
					</Col>
					<Col className="text-right">
						<span className="total">
							<FormattedNumber
								value={orderTotal}
								style="currency"
								currency={config.currencyCode}
								currencyDisplay="symbol"
								/>
						</span>
					</Col>
				</Row>
				<Row className="d-flex mb-5 align-items-center">
					{allowEdit && !!emptyCart && !!showModalConfirmation && (
						<Col>
							<Button color="light" size="sm" onClick={this.confirmClearCart}>
								<FormattedMessage id="lbl_ClearCart"/>
							</Button>
						</Col>
					)}
				</Row>
			</div>
		);
	}
	
	private confirmClearCart = () => {
		const {emptyCart, intl, showModalConfirmation} = this.props;
		if (!!showModalConfirmation && !!emptyCart) {
			showModalConfirmation(intl.formatMessage({id: "msg_confirm_clear_cart"}), emptyCart, intl);
		}
	}
}

/**
 * Component exported with injections
 */
export const TicketOrderWithIntl = injectIntl<TicketOrderPropsExcludingInjectedProps>(TicketOrder);
