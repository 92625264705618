import {Calendar, CalendarOptions, EventInput} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import "font-awesome/css/font-awesome.css";
import {History} from "history";
import * as _ from "lodash";
import moment from "moment";
import * as React from "react";
import {FormattedMessage, InjectedIntlProps} from "react-intl";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import PopoverHeader from "reactstrap/lib/PopoverHeader";
import {AnyAction} from "redux";
import {ContentFormats} from "../../enums/content-formats";
import {EventInstanceSaleStatus} from "../../enums/event-instance-sale-status";
import {getInstanceRoute} from "../../helpers/routing";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {EventInstance} from "../../models/ticketable-events/event-instance";
import {TicketableEvent} from "../../models/ticketable-events/ticketable-event";
import {SimpleHTMLContent} from "../html-content/simple-html-content";
import {Message} from "../message";
import {
	PasscodeDisplayWithInjections as PasscodeDisplay,
	PasscodeFormWithInjections as PasscodeForm
} from "../passcode/wrapped-components";
import "./event-calendar.css";
import {GhostCalendar} from "./ghost-event";

interface EventCalendarProps extends InjectedIntlProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	clearAllMessages: () => void;
	defaultCalendarDate?: string;
	eventList: TicketableEvent[] | null;
	history: History;
	onCalendarNavChange:(newDate: string) => void;
	timezone: string;
	fetchEvents: () => void;
	validatePasscode: (passcode: string, eventInstanceId: string) => Promise<any>;
}

interface EventCalendarState {
	date: Date | null;
	eventsForDate: EventInput[];
	isPopoverShowing: boolean;
}

interface ExtendedEventProps {
	appliedPasscode: string;
	eiId: string;
	eiName: string;
	isPasscodeEligible: boolean;
	noSaleMessage?: string;
	saleStatus: string;
	soldOut: boolean;
	startDate: Date;
	teId: string;
}

export class EventCalendar extends React.Component<EventCalendarProps, EventCalendarState> {

	// since we are not using fullCalendar react component, we need to keep track of
	// the initialized calendar reference
	public calendar: Calendar;

	public readonly state: EventCalendarState = {
		date: null,
		eventsForDate: [],
		isPopoverShowing: false
	};
	
	public componentDidMount() {
		const {eventList, timezone} = this.props;
		if (eventList && eventList.length > 0) {
			this.updateCalendar(this.convertToCalendarEvents(eventList), timezone);
		}
	}

	public getSnapshotBeforeUpdate(prevProps: EventCalendarProps, prevState: any) {
		const {eventList} = this.props;
		if (!!eventList && !_.isEqual(eventList, prevProps.eventList)) {
			return this.convertToCalendarEvents(eventList);
		}
		return null;
	}

	public componentDidUpdate(prevProps: EventCalendarProps, prevState: any, calendarEvents: EventInput[] | null) {
		if (calendarEvents !== null) {

			if (!!this.state.date) {
				const filteredEvents = this.getCalendarEventsForDate(this.state.date);

				this.setState({eventsForDate: filteredEvents});
			}

			this.updateCalendar(calendarEvents, this.props.timezone);
		}
	}

	public render() {
		const {
			blockingActions,
			clearAllMessages,
			eventList,
			fetchEvents,
			intl,
			validatePasscode
		} = this.props;
		if (!eventList) {
			return <GhostCalendar />;
		} else if (eventList.length < 1) {
			return <Message intlId="msg_no_events_found" values={{events: intl.formatMessage({id: "pmgr_term_Events"}).toLowerCase()}}/>;
		}

		const {date, eventsForDate, isPopoverShowing} = this.state;

		return (
			<div id="calendar">
				{!!date && eventsForDate.length > 0 && (
					<Popover
						hideArrow={true}
						isOpen={isPopoverShowing}
						placement="auto"
						target={document.querySelector(`[data-date='${date.toISOString().slice(0, 10)}']`) as HTMLElement}
					>
						<PopoverHeader className="pts-cal-popover-header">
							<span className="pts-cal-popover-header__title">{moment(date).format('LL')}</span>
							<Button className="pts-cal-popover-header__close" close={true} onClick={this.hidePopover}/>
						</PopoverHeader>
						<PopoverBody>
							{eventsForDate.map((event) => {

								let cta;
								let passcodeDisplay;

								const extProps = (event.extendedProps as ExtendedEventProps);

								if (extProps.soldOut) {
									cta = <span className="text-info mb-2">{this.getNoSaleMessage(extProps)}</span>;
								} else if (extProps.saleStatus === EventInstanceSaleStatus.OS){
									cta = (
											<Link className="btn btn-primary btn-sm" to={getInstanceRoute(event.id as string)}>
												<FormattedMessage id="pmgr_lbl_Buy"/>
											</Link>
									);

									if (!!extProps.appliedPasscode){
										passcodeDisplay =
											<div>
												<PasscodeDisplay
													appliedPasscode={extProps.appliedPasscode}
													eventInstanceId={extProps.eiId}
													fetchEvents={fetchEvents}
												/>
											</div>;
									}
								}

								if (extProps.noSaleMessage) {
									cta = (
										<span className="text-info mb-2">
											<SimpleHTMLContent rawHtml={extProps.noSaleMessage}/>
										</span>
									);
								}

								let passcodeCTA;
								if (extProps.isPasscodeEligible){
									passcodeCTA =
										<div>
											<PasscodeForm
												blockingActions={blockingActions}
												clearAllMessages={clearAllMessages}
												eventInstanceId={extProps.eiId}
												fetchEvents={fetchEvents}
												validatePasscode={validatePasscode}
											/>
										</div>;
								}
								return (
									<div key={event.id} className="pts-cal-popover-event">
										<p className="pts-cal-popover-event__te-name">{event.title}</p>
										<p className="pts-cal-popover-event__ei-name mb-2">{extProps.eiName}</p>
										{passcodeDisplay}
										{cta}
										{passcodeCTA}
									</div>
								);
							})}
						</PopoverBody>
					</Popover>
				)}
			</div>
		);
	}
	
	private getNoSaleMessage = (extendedEventProps: ExtendedEventProps) => {
		return !!extendedEventProps && !!extendedEventProps.noSaleMessage
			? extendedEventProps.noSaleMessage
			: <FormattedMessage id="msg_item_not_available_for_purchase"/>;
	}

	private hidePopover = () => {
		this.setState({
			date: null,
			eventsForDate: [],
			isPopoverShowing: false
		});
	}
	
	private showPopover = (date: Date, eventsForDate: EventInput[]) => {
		this.hidePopover();
		this.setState({
			date,
			eventsForDate,
			isPopoverShowing: true
		});
	}

	/**
	 * Converts ticketable events to full-calendar events
	 *
	 * @param eventList the list of events to convert into full-calendar events
	 */
	private convertToCalendarEvents = (eventList: TicketableEvent[]): EventInput[] => {
		const calendarEvents: EventInput[] = [];
		eventList.forEach((te: TicketableEvent) => {
			te.instances.forEach((ei: EventInstance) => {
				// Filter out "Video on Demand" EIs from the calendar view
				if (ei.contentFormat !== ContentFormats.VOD) {
					const start = moment(ei.formattedDates.ISO8601);
					const extendedProps: ExtendedEventProps = {
						appliedPasscode: ei.appliedPasscode || '',
						eiId: ei.id,
						eiName: ei.name,
						isPasscodeEligible: ei.isPasscodeEligible || false,
						noSaleMessage: ei.noSaleMessage,
						saleStatus: ei.saleStatus,
						soldOut: ei.soldOut,
						startDate: start.toDate(),
						teId: te.id
					};
					const calEvent: EventInput = {
						id: ei.id,
						title: te.name,
						allDay: false,
						start: start.toISOString(),
						teId: te.id,
						extendedProps
					};
					calendarEvents.push(calEvent);
				}
			});
		});
		return calendarEvents;
	}

	/**
	 * Determines what the default event date should be.
	 *
	 * Priority is given as follow
	 *   1. defaultCalendarDate prop passed into this component
	 *   2. earliest future event when defaultCalendarDate prop is undefined
	 *   3. today's date when no future events exist
	 *
	 * @param calendarEvents list of visible events
	 * @return the default calendar date
	 */
	private getDefaultCalendarDate = (calendarEvents: EventInput[]): Date | string => {

		// return the default date if one is specified
		if (!!this.props.defaultCalendarDate) {
			return this.props.defaultCalendarDate;
		}

		const now = new Date();
		let defaultDate: Date | undefined;

		if (calendarEvents.length > 0) {

			calendarEvents.forEach(currentCalendarEvent => {

				// through our implementation this should never be null but
				// we still have to handle it as part of the `EventInput` interface
				const extendedProps = !!currentCalendarEvent.extendedProps
					? currentCalendarEvent.extendedProps as ExtendedEventProps
					: null;

				// only consider future event dates
				if (!!extendedProps && extendedProps.startDate > now) {
					if (!defaultDate || extendedProps.startDate < defaultDate) {
						defaultDate = extendedProps.startDate;
					}
				}
			});
		}

		return !!defaultDate ? defaultDate : now;
	}

	/**
	 * Initializes and Populates calendar if it has not already been created; otherwise updates existing calendar events
	 *
	 * @param calendarEvents used only during initialization as the calendar events to set
	 * @param timeZone used only during initialization as the timeZome the calendar should be set to
	 */
	private updateCalendar = (calendarEvents: EventInput[], timeZone: string) => {

		if (!!this.calendar) {
			// If the calendar already exists we just need to call the fullCalendar refetchEvents(). Despite the name
			// it won't make an REST call to fetch events as we have it configured to use this.props.eventList (see the
			// `events: (info, successCallback, failureCallbak)` function of the calendar `OptionsInput`)
			this.calendar.refetchEvents();
			return;
		}
		
		// if we are here then the calendar has not been created yet so lets configure & create it
		
		// element with id of "calendar" is required otherwise we have nowhere to place
		// the calendar. It might not be defined if there are no events available. At that time
		// we will render a message instead of the calendar element
		const calendarEl = document.getElementById("calendar");
		
		if (!calendarEl) {
			return;
		}
		
		const options: CalendarOptions = {
			plugins: [
				dayGridPlugin,
				interactionPlugin,
				momentTimezonePlugin
			],
			events: (info: any, successCallback: any, failureCallback: any) => {
				// configuring this property to use this eventSource function to setup events so that we can call
				// `this.calendar.refetchEvents()` at any time and have it update this calendar's events
				// see https://fullcalendar.io/docs/event-source-object#options for more details
				const {eventList} = this.props;

				if (eventList) {
					successCallback(this.convertToCalendarEvents(eventList));
				} else {
					failureCallback();
				}
			},
			themeSystem: "bootstrap4",
			bootstrapFontAwesome: false,
			initialView: "dayGridMonth",
			headerToolbar: {
				left: "title",
				right: "today prev,next"
			},
			eventDisplay: 'block',
			eventInteractive: true,
			buttonIcons: false,
			contentHeight: 'auto',
			initialDate: this.getDefaultCalendarDate(calendarEvents),
			timeZone,
			eventTimeFormat: {
				hour: 'numeric',
				minute: '2-digit',
				omitZeroMinute: false,
				meridiem: 'narrow'
			},
			// This assigns a unique DOM ID to each calendar event
			eventClick: (info) => {
				const {event, jsEvent} = info;
				// Get just the date part of the startDate (e.g. convert 2019-01-01T16:00:00-0400 to 2019-01-01T00:00:00-0400)
				const date = moment(event.extendedProps.startDate).startOf('day').toDate();
				const filteredEvents = this.getCalendarEventsForDate(date);
				this.showPopover(date, filteredEvents);
				jsEvent.preventDefault();
			},
			eventDidMount: (info) => {
				info.el.classList.add('pts-cal-event');
			},
			datesSet: (info) => {
				const {view} = info;
				this.hidePopover();
				this.props.onCalendarNavChange(view.currentStart.toISOString());
			},
			dateClick: (info) => {
				const filteredEvents = this.getCalendarEventsForDate(info.date);
				this.showPopover(info.date, filteredEvents);
			}
		};
		
		this.calendar = new Calendar(calendarEl!, options);
		this.calendar.render();
	}

	/**
	 * Returns calendar events whose startDate is on the specified `date` param
	 *
	 * @param date used to filtering for events whose startDate lands on the same day as this `date`
	 */
	private getCalendarEventsForDate = (date: Date) => {
		const calendarEvents = this.convertToCalendarEvents(this.props.eventList || []);
		return calendarEvents
			.filter((calEvent: EventInput) => {
				if (!!calEvent.extendedProps) {
					// The last arg to the isBetween function is the inclusivity specifier. [) means inclusive of the first date, but exclusive of the second.
					return moment((calEvent.extendedProps as ExtendedEventProps).startDate).isBetween(moment(date), moment(date).add(1, 'd'), undefined, '[)');
				}
				return false;
			})
			.sort((eventA, eventB): number => {
				// Handle undefined dates
				if (!eventA.start || !eventB.start) {
					return 0;
				}
				
				if (eventA.start < eventB.start) {
					return -1;
				}
				
				if (eventA.start > eventB.start) {
					return 1;
				}
				
				// same time
				return 0;
			});
	}
}
