import * as React from "react";
import {InjectedIntl} from "react-intl";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {Badge} from "reactstrap";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import {TicketOrderStatus} from "../../enums/ticket-order-status";
import {PortalOrder} from "../../models/portal/portal-order";

interface PortalOrderHeaderProps {
	portalOrder: PortalOrder;
	isOpen: boolean;
	currencyCode: string;
	intl: InjectedIntl;
}

export class OrderHeader extends React.Component<PortalOrderHeaderProps> {

	/**
	 * Returns a presentable status representing the provided `ticketOrderStatus`. Use to hide implementation
	 * details that don't have any meaning to patrons
	 *
	 * @param intl the i18n object
	 * @param ticketOrderStatus the ticket order status to convert
	 * @returns presentable representation of ticket order status a patron can understand
	 */
	public getPresentableTicketOrderStatus = (ticketOrderStatus: TicketOrderStatus) => {
		const {intl} = this.props;

		switch (ticketOrderStatus){
			case TicketOrderStatus.COMPLETE:
			case TicketOrderStatus.TO_BE_QUALIFIED:
				return intl.formatMessage({id: "lbl_TicketOrderCompleteStatus"});
			case TicketOrderStatus.PARTIALLY_PAID:
				return intl.formatMessage({id: "lbl_TicketOrderPartiallyPaidStatus"});
			case TicketOrderStatus.RESERVATION:
				return intl.formatMessage({id: "lbl_TicketOrderReservationStatus"});
			case TicketOrderStatus.PAYMENT_EXCEPTION:
			case TicketOrderStatus.CONFIRMATION_EXCEPTION:
			case TicketOrderStatus.CHARGEBACK_PENDING:
				return intl.formatMessage({id: "lbl_TicketOrderInReviewStatus"});
			case TicketOrderStatus.FULLY_REFUNDED:
				return intl.formatMessage({id: "lbl_TicketOrderRefundedStatus"});
			default:
				return '';
		}
	}

	public render() {
		const {portalOrder, isOpen, currencyCode} = this.props;
		const {total, status, quantity, name} = portalOrder;

		return (
			<div>
				<Row>
					<Col>
						<p className="m-0 text-uppercase">
							<span>{name}</span>
							<Badge color="info" className="ml-2">{this.getPresentableTicketOrderStatus(status)}</Badge>
						</p>
						<p className="m-0">
							<small>
								<FormattedMessage id="lbl_Quantity" values={{ quantity }} />
							</small>
						</p>
					</Col>
					<Col className="text-right">
						<h3 className={`font-weight-light ${isOpen && 'invisible'}`}>
							<FormattedNumber
								value={total}
								style="currency"
								currency={currencyCode}
								currencyDisplay="symbol"
							/>
						</h3>
					</Col>
				</Row>
			</div>
		);
	}
}