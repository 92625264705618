import * as React from "react";
import {InjectedIntlProps} from "react-intl";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {Col, Row} from "reactstrap";
import {Paths} from "../../enums/paths";
import {InventoryService} from "../../helpers/inventory-service";
import {getSectionGroupRoute} from "../../helpers/routing";
import {venueHasDetails} from "../../helpers/utilities";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {HTMLContent} from "../html-content/html-content";
import {Message} from "../message";
import {SeatMap} from "../seat-map/seat-map";
import {TicketableEventDetailLink} from "../ticketable-event-detail-link";
import {VenueLink} from "../venue-link";

interface PYOSSeatSelectionProps extends RouteComponentProps<any>, InjectedIntlProps{
	allocMap: BasicStringKeyedMap<AllocationDescriptor>;
	cart: Cart;
	config: PublicTicketAppConfig;
	eventDescriptor: EventDescriptor;
	pendingItemIds: BasicStringKeyedMap<string>;
	pendingSeatRequests: BasicStringKeyedMap<string>;
	pyosCartItemMap: BasicStringKeyedMap<CartItem>;
	onSeatClick: (seat: SeatDescriptor) => void;
}

export class PYOSSeatSelection extends React.Component<PYOSSeatSelectionProps> {
	public render() {
		const {cart, eventDescriptor, match, location, ...other} = this.props;
		
		if (!eventDescriptor || !InventoryService.isPYOSEventDescriptor(eventDescriptor)) {
			return null;
		}
		
		const {teId, venue, id, eiDetail, noSaleMsg, hasRetQty} = eventDescriptor;

		// PMGR-8145 - This code is meant to handle navigation via the "Change seat" link on the PYOS CartItem.
		// The CartItem contains a sectionId prop for the seat that is assigned to it. However, it does not contain
		// a groupId prop to identify the Section Group in the case of a V2 venue (Sections contained within Section Groups).
		// So, we need to determine whether the Section specified by the sectionId param references a grouped Section.
		// If so, we need to redirect to the Section Group route with the appropriate Section Group Id.
		let sectionOrGroupId;
		if (match.path === Paths.INSTANCE__SECTION_GROUP) {
			sectionOrGroupId = match.params.sectionGroupId;
		} else if (match.path === Paths.INSTANCE__SECTION) {
			sectionOrGroupId = match.params.sectionId;
			const sectionsMappedById: BasicStringKeyedMap<SectionDescriptor> = InventoryService.getSectionsMappedById(eventDescriptor);
			if (sectionOrGroupId in sectionsMappedById) {
				const section = sectionsMappedById[sectionOrGroupId];
				// If this Section is contained within a Section Group then redirect to Section Group path
				// after replacing the Section ID with Section Group ID
				if (!!section.groupId) {
					return <Redirect to={getSectionGroupRoute(eventDescriptor.id, section.groupId)}/>;
				}
			}
		}
		
		return (
			<Row>
				<Col>
					<div className="mb-4">
						<TicketableEventDetailLink teId={teId} />
						{!!venue && <VenueLink venueName={venue.name} eventInstanceId={id} showDetailsLink={venueHasDetails(venue)}/>}
					</div>
					{!!eiDetail && (
						<div className="mb-4">
							<HTMLContent rawHtml={eiDetail} />
						</div>
					)}
					
					{hasRetQty ? (
						<SeatMap
							sectionOrGroupId={sectionOrGroupId}
							cart={cart}
							eventDescriptor={eventDescriptor}
							{...other}
						/>
					) : (
						!!noSaleMsg ? <Message htmlMessage={noSaleMsg}/> : <Message intlId="msg_item_not_available_for_purchase"/>
					)}
				</Col>
			</Row>
		);
	}
}
