import * as React from "react";
import {SFC} from "react";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {CartItem} from "../../models/cart-item";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {GroupDescriptor} from "../../models/event-descriptor/group-descriptor";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {SeatAttributes} from "./seat-attributes";
import {SeatMap} from "./seat-map";
import {Section} from "./section";

interface SectionGroupProps {
	sectionGroup: GroupDescriptor;
	sectionsInGroup: SectionDescriptor[];
	eventDescriptor: EventDescriptor;
	cart: any;
	allocMap: BasicStringKeyedMap<AllocationDescriptor>;
	previewMode: boolean;
	pyosCartItemMap: BasicStringKeyedMap<CartItem>;
	seatAttributes: SeatAttributes;
	onSeatClick: (seat: SeatDescriptor) => void;
}

export const SectionGroup: SFC<SectionGroupProps> = (props) =>{

	const {sectionGroup, sectionsInGroup, eventDescriptor, previewMode, ...other} = props;
	const invertStageArrow = !!eventDescriptor.venue && eventDescriptor.venue.invertStageArrow;
	const sectionElements = sectionsInGroup.map((section: SectionDescriptor) => {
		const sectionLabelElement = Section.getSectionLabel(section);
		const rows = Section.getRowsInSection(section, eventDescriptor, other);
		return (
			<React.Fragment key={"section-" + section.id}>
				{sectionLabelElement}
				{rows}
			</React.Fragment>
		);
	});
	const stageLocationBoxStyle = SeatMap.getStageLocationBoxStyle(sectionGroup);
	return (
		<div>
			<h4 style={{textAlign: "center"}}>Selected Group: {sectionGroup.name}</h4>
			<div className="map-container" style={stageLocationBoxStyle}>
				{/* When previewing the seat map, cover it with an overlay to prevent clicking and make it appear more like a preview */}
				{previewMode && <div className="seat-map-preview-overlay"/>}
				<div
					className={`stage ${SeatMap.getStageLocationImageClass(sectionGroup)}${invertStageArrow ? " inverted" : ""}`}
					style={SeatMap.getStageLocationImageStyle(sectionGroup)}
				/>
				<div className="section-group-box" style={SeatMap.getSeatLocationBoxStyle(sectionGroup)}>
					<div style={{height: sectionGroup.sbh, width: sectionGroup.sbw}}>
						{sectionElements}
					</div>
				</div>
			</div>
		</div>
	);
};
