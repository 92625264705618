import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import Error404 from "../../containers/error-404";

/**
 * Captures any redirect with its `location.state.notFoundError` set to true, and Displays a 404 error instead.
 */
class CaptureRouteNotFound extends React.Component<RouteComponentProps<any>> {
	render() {
		const {children, location} = this.props;
		// @ts-ignore
		return !!location && !!location.state && location.state.notFoundError ? <Error404 /> : children;
	}
}

export default withRouter(CaptureRouteNotFound);