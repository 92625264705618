import * as H from "history";
import * as React from "react";
import {KeyboardEvent} from "react";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import VfRemote from "../api/vf-remote";
import ApplicationAlert from "../containers/application-messages/application-alert";
import {formatApplicationMessages} from "../helpers/utilities";
import {ApplicationMessage} from "../models/application-message";
import {BasicStringKeyedMap} from "../models/basic-map";
import {FieldGroup, FieldGroupTypes} from "./field-group";
import {HTMLContent} from "./html-content/html-content";

interface LoginFormProps {
	cartId: string;
	location: H.Location;
	loginPageMessage?: string;
	isOpen: boolean;
	onClose: () => void;
	onForgotPasswordClick: () => void;
}

interface LoginFormState {
	errors: BasicStringKeyedMap<string>;
	formValues: BasicStringKeyedMap<string>;
	showPassword: boolean,
	isBusy: boolean;
	modalMessage: ApplicationMessage | null;
}

export class LoginForm extends React.Component<LoginFormProps & InjectedIntlProps, LoginFormState> {
	public readonly state: LoginFormState = {
		errors: {},
		formValues: {
			userName: '',
			password: ''
		},
		showPassword: false,
		isBusy: false,
		modalMessage: null
	};
	
	public componentDidUpdate(prevProps: LoginFormProps){
		// modal just closed.  Clear state
		if (prevProps.isOpen && !this.props.isOpen) {
			this.clearState();
		}
	}

	public render() {
		const {errors, isBusy, modalMessage} = this.state;
		const {loginPageMessage, intl, isOpen, onClose} = this.props;
		
		return (
			<Modal
				size="lg"
				isOpen={isOpen}
				className="text-dark"
				returnFocusAfterClose={false}
				keyboard={true}
				toggle={onClose}
				autoFocus={false}
				aria-modal="true"
				aria-labelledby="LoginLabel"
			> 
				<ModalHeader toggle={onClose}>
					<div id="LoginLabel"><FormattedMessage id="lbl_LogIn" /></div>
				</ModalHeader>
				<ModalBody>
					
					{modalMessage && (<ApplicationAlert message={modalMessage} onClose={this.clearMessages} />)}
					
					<div>
						{!!loginPageMessage && (
							<div className="mb-4">
								<HTMLContent rawHtml={loginPageMessage}/>
							</div>
						)}
						<Row className="justify-content-center my-4">
							<Col lg={6}>
								<FieldGroup
									id="userName"
									name="userName"
									type={FieldGroupTypes.TEXT}
									label={intl.formatMessage({id:'lbl_Username'})}
									value={this.state.formValues.userName || ""}
									onChange={this.handlePropertyChange}
									invalid={!!errors.userName}
									feedbackMessage={errors.userName}
									disabled={isBusy}
									autoFocus={true}
								/>
								<FieldGroup
									id="password"
									name="password"
									type={this.state.showPassword ? FieldGroupTypes.TEXT : FieldGroupTypes.PASSWORD}
									label={intl.formatMessage({id:'lbl_Password'})}
									value={this.state.formValues.password || ""}
									onChange={this.handlePropertyChange}
									onKeyPress={this.onKeyPress}
									invalid={!!errors.password}
									feedbackMessage={errors.password}
									disabled={isBusy}
								/>
								<FieldGroup
									id="showPassword"
									name="showPassword"
									type={FieldGroupTypes.CHECKBOX}
									label={intl.formatMessage({id: 'lbl_ShowPassword'})}
									value={this.state.showPassword || false}
									checked={this.state.showPassword}
									onChange={this.handleShowPasswordChanged}
									disabled={isBusy}
								/>
							</Col>
						</Row>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button id="ForgotPassword" size="sm" color="link" onClick={this.props.onForgotPasswordClick} disabled={isBusy} >
						<span className="text-danger">
							<FormattedMessage id="lbl_ForgotPassword" />
						</span>
					</Button>
					<Button id="LoginButton" color="primary" onClick={this.handleLogin} disabled={isBusy}>
						<FormattedMessage id="lbl_LogIn" />
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
	
	private onKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
		if(evt.which === 13) {
			this.handleLogin();
		}
	}

	private handleShowPasswordChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({showPassword: evt.target.checked});
	}
	
	private handlePropertyChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const {formValues} = this.state;
		formValues[evt.target.name] = evt.target.value;
		this.setState({formValues});
	}
	
	private handleLogin = () => {
		if (this.validateForm(["userName","password"])) {
			this.setState({isBusy:true});
			const {cartId, location: {pathname}} = this.props;
			const {userName, password} = this.state.formValues;
			const retURL = !!pathname ? pathname.replace(/^\/+/g, '') : '';
			VfRemote.vfLogin({userName, password, retURL, cartId})
				.then((result) => {
					window.location.replace(result);
				})
				.catch((errors) => {
					const modalMessages: ApplicationMessage[] = formatApplicationMessages(errors);
					//there should only be 1 message
					this.setState({modalMessage: modalMessages[0],isBusy:false});
				});
		}
	}
	
	private validateForm = (requiredFields:string[]) => {
		const {intl} = this.props;
		
		const errors: BasicStringKeyedMap<string> = {};
		requiredFields.forEach((fieldName: string) => {
			if (!this.state.formValues[fieldName]) {
				errors[fieldName] = intl.formatMessage({id: "msg_required_field"});
			}
		});
		this.setState({errors});
		
		return (Object.keys(errors).length === 0);
	}
	
	private clearState = () => {
		this.setState({
			formValues: {...this.state.formValues, userName: '',password:''},
			errors: {},
			modalMessage: null
		});
	}
	private clearMessages = () => {
		this.setState({modalMessage: null});
	}
}
export const LoginFormWithIntl = injectIntl(LoginForm);
