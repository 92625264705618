import * as React from "react";
import {Component} from "react";
import {FormattedMessage, InjectedIntl, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect, RouteComponentProps, Switch} from "react-router";
import {Link, Route} from "react-router-dom";
import {Nav, NavItem, NavLink} from "reactstrap";
import {AnyAction} from "redux";
import {AnalyticsActions} from "../../actions/analytics-actions";
import {ApiActions} from "../../actions/api-actions";
import {AlertOptions, PublicTicketAppActions} from "../../actions/public-ticket-app-actions";
import {ApiAction} from "../../api/api-action";
import {OperationResult} from "../../api/operation-result";
import {BenefitList} from "../../components/portal/benefit-list";
import {ChangePassword} from "../../components/portal/change-password";
import {ContactRequestForm} from "../../components/portal/contact-request-form";
import {DonationList} from "../../components/portal/donation-list";
import {OrderDetail} from "../../components/portal/order-detail";
import {OrderHistoryList} from "../../components/portal/order-history-list";
import {PendingRenewalOrders} from "../../components/portal/pending-renewal-orders";
import {Profile} from "../../components/portal/profile";
import {ActionTypes} from "../../enums/action-types";
import {Paths} from "../../enums/paths";
import {Severities} from "../../enums/severities";
import {isPortalUser} from "../../helpers/utilities";
import {Layout, layout} from "../../hoc/layout";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Benefit} from "../../models/portal/benefit";
import {PortalDonation} from "../../models/portal/portal-donation";
import {PortalOrder} from "../../models/portal/portal-order";
import {PortalOrderDetails} from "../../models/portal/portal-order-details";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {UserProfile} from "../../models/public-ticket-app/user-profile";
import {RootState} from "../../reducers";
import {PortalPendingRenewalConnected} from "./pending-renewal";
import {ThunkDispatch} from "redux-thunk";

///
/// Interfaces
///

/**
 * All properties available within this component
 */
interface PortalProps extends PortalPropsExcludingInjectedProps, PortalStateToProps, PortalDispatchToProps, InjectedIntlProps {}

/**
 * All properties that should be defined when using the component exported with injections.
 */
interface PortalPropsExcludingInjectedProps extends RouteComponentProps<any>{}

interface PortalStateToProps {
	blockingActions: BasicStringKeyedMap<AnyAction>;
	config: PublicTicketAppConfig;
	donations: PortalDonation[];
	orders: PortalOrder[];
	pendingRenewal: PortalOrderDetails;
	pendingRenewals: PortalOrder[];
	portalOrderCache: BasicStringKeyedMap<PortalOrderDetails>;
	renewableBenefits: Benefit[];
}

interface PortalDispatchToProps {
	changePassword: (oldPassword: string, newPassword: string, verifyPassword: string) => Promise<any>;
	clearAllMessages: () => void;
	doNotRenew: (ticketOrderId: string) => Promise<any>;
	fetchDonationHistory: () => Promise<any>;
	fetchPendingRenewal: (ticketOrderId: string) => Promise<any>;
	fetchPortalOrderDetails: (ticketOrder: string) => Promise<any>;
	fetchPortalOrders: () => Promise<any>;
	fetchRenewableBenefits: () => Promise<any>;
	pageView: (title: string, url: string) => void;
	showAlert: (alertOptions: AlertOptions) => void;
	showLoginForm: () => void;
	showModalConfirmation: (body: React.ReactNode, confirmationCallback: () => any, intl: InjectedIntl) => void;
	submitContactRequest: (whatId: string, request: string) => Promise<AnyAction>;
	updateUserProfile: (user: UserProfile) => Promise<ApiAction<OperationResult<UserProfile>>>;
}

///
/// Component
///

export class Portal extends Component<PortalProps, {}> {
	private readonly ContactRequestForm: Layout<typeof ContactRequestForm>;
	constructor(props: PortalProps) {
		super(props);
		this.ContactRequestForm = layout<typeof ContactRequestForm>({Main: ContactRequestForm});
	}
	
	public componentDidMount() {
		if (isPortalUser(this.props.config.userProfile)) {
			this.props.fetchDonationHistory();
			this.props.fetchPortalOrders();
			this.props.fetchRenewableBenefits();
		} else {
			this.props.showAlert({alertSeverity: Severities.ERROR, alertId: "msg_please_log_in"});
			this.props.showLoginForm();
		}
	}

	public render() {
		const {
			blockingActions,
			clearAllMessages,
			config,
			donations,
			doNotRenew,
			fetchPendingRenewal,
			fetchPortalOrderDetails,
			fetchPortalOrders,
			intl,
			location,
			orders,
			pendingRenewal,
			pendingRenewals,
			portalOrderCache,
			renewableBenefits,
			showAlert,
			showModalConfirmation,
			submitContactRequest,
			updateUserProfile
		} = this.props;
		const {defaultSubsChangeRequest, userProfile} = config;
		
		// show message and login link if it's not a portal user.
		if (!userProfile || !isPortalUser(userProfile)) {
			return (
				<a href="#" onClick={this.handleLoginClick}>
					<FormattedMessage id="msg_click_here_to_log_in"/>
				</a>
			);
		}
		
		return (
			<div>
				<Nav tabs={true} className="mb-3">
					<NavItem>
						<NavLink
							active={location.pathname === Paths.PORTAL__PROFILE}
							disabled={location.pathname === Paths.PORTAL__PROFILE}
							tag={Link}
							to={Paths.PORTAL__PROFILE}
						>
							<FormattedMessage id="lbl_nav_MyProfile" />
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							active={location.pathname === Paths.PORTAL__ORDERS}
							disabled={location.pathname === Paths.PORTAL__ORDERS}
							tag={Link}
							to={Paths.PORTAL__ORDERS}
						>
							<FormattedMessage id="lbl_Orders" />
						</NavLink>
					</NavItem>
					{donations.length > 0 &&
						<NavItem>
							<NavLink
								active={location.pathname === Paths.PORTAL__DONATIONS}
								disabled={location.pathname === Paths.PORTAL__DONATIONS}
								tag={Link}
								to={Paths.PORTAL__DONATIONS}
							>
								<FormattedMessage id="lbl_Donations" />
							</NavLink>
						</NavItem>
					}
					{pendingRenewals.length > 0 && !config.hideSubsRenewalTab && (
						<NavItem>
							<NavLink
								active={location.pathname === Paths.PORTAL__PENDING_RENEWALS}
								disabled={location.pathname === Paths.PORTAL__PENDING_RENEWALS}
								tag={Link}
								to={Paths.PORTAL__PENDING_RENEWALS}
							>
								<FormattedMessage id="lbl_SubscriptionRenewal" values={{subscription: intl.formatMessage({id: 'pmgr_term_Subscription'})}}/>
							</NavLink>
						</NavItem>
					)}
					{renewableBenefits.length > 0 && (
						<NavItem>
							<NavLink
								active={location.pathname === Paths.PORTAL__RENEWABLE_BENEFITS}
								disabled={location.pathname === Paths.PORTAL__RENEWABLE_BENEFITS}
								tag={Link}
								to={Paths.PORTAL__RENEWABLE_BENEFITS}
							>
								<FormattedMessage id="lbl_BenefitRenewal" />
							</NavLink>
						</NavItem>
					)}
				</Nav>
				
				<Switch>

					<Route
						path={Paths.PORTAL__ORDER}
						exact={true}
						render={(routeProps) =>
							<OrderDetail
								config={config}
								fetchPortalOrderDetails={fetchPortalOrderDetails}
								intl={intl}
								portalOrderCache={portalOrderCache}
								pageView={this.props.pageView}
								{...routeProps}
							/>
						}
					/>

					<Route path={Paths.PORTAL__ORDERS}
						   exact={true}
						   render={(routeProps) =>
							   <OrderHistoryList
								   blockingActions={blockingActions}
								   config={config}
								   fetchPortalOrderDetails={fetchPortalOrderDetails}
								   intl={intl}
								   orders={orders}
								   portalOrderCache={portalOrderCache}
								   pageView={this.props.pageView}
								   {...routeProps}
							   />
						   }
					/>

					<Route
						path={Paths.PORTAL__DONATIONS}
						exact={true}
						render={() =>
							<DonationList
								currencyCode={config.currencyCode}
								donations={donations}
								intl={intl}
								pageView={this.props.pageView}
							/>
						}
					/>

					<Route path={Paths.PORTAL__PROFILE}
						   render={(routeProps) =>
							   <Profile
								   clearAllMessages={clearAllMessages}
								   config={config}
								   intl={intl}
								   showAlert={showAlert}
								   updateUserProfile={updateUserProfile}
								   pageView={this.props.pageView}
								   user={userProfile}
								   {...routeProps}
							   />
						   }
					/>
					
					{/* The ordering of this route is important. It must come before PORTAL__PENDING_RENEWAL, otherwise PORTAL__PENDING_RENEWAL will be matched first */}
					<Route
						path={Paths.PORTAL__PENDING_RENEWAL__CONTACT_REQUEST}
						exact={true}
						render={(routeProps) =>
							<this.ContactRequestForm
								intl={intl}
								request={defaultSubsChangeRequest}
								showAlert={showAlert}
								pageView={this.props.pageView}
								prevPagePath={Paths.PORTAL__PENDING_RENEWALS}
								submitContactRequest={submitContactRequest}
								userProfile={userProfile}
								whatId={routeProps.match.params.ticketOrderId}
								{...routeProps}
							/>
						}
					/>

					<Route path={Paths.PORTAL__PENDING_RENEWAL}
						render={(routeProps) => <PortalPendingRenewalConnected  {...routeProps} />}
					/>

					<Route
						path={Paths.PORTAL__PENDING_RENEWALS}
						exact={true}
						render={(routeProps) =>
							<PendingRenewalOrders
								blockingActions={blockingActions}
								config={config}
								doNotRenew={doNotRenew}
								fetchPendingRenewal={fetchPendingRenewal}
								fetchPortalOrders={fetchPortalOrders}
								intl={intl}
								pageView={this.props.pageView}
								pendingRenewal={pendingRenewal}
								pendingRenewals={pendingRenewals}
								showModalConfirmation={showModalConfirmation}
								{...routeProps}
							/>
						}
					/>

					<Route
						path={Paths.PORTAL__RENEWABLE_BENEFIT__CONTACT_REQUEST}
						exact={true}
						render={(routeProps) => {
							const benefitId: string = routeProps.match.params.benefitId;
							const benefit: Benefit | undefined = renewableBenefits.find(b => b.id === benefitId);
							if (!!benefit) {
								const {benefitLevelName, expirationDate} = benefit;
								return (
									<this.ContactRequestForm
										intl={intl}
										pageView={this.props.pageView}
										prevPagePath={Paths.PORTAL__RENEWABLE_BENEFITS}
										request={intl.formatMessage({id: "msg_benefit_contact_request"}, {benefitLevelName, expirationDate})}
										showAlert={showAlert}
										submitContactRequest={submitContactRequest}
										userProfile={userProfile}
										whatId={benefitId}
										{...routeProps}
									/>
								);
							} else {
								return null;
							}
						}}
					/>
					
					<Route
						path={Paths.PORTAL__RENEWABLE_BENEFITS}
						exact={true}
						render={() =>
							<BenefitList
								intl={intl}
								renewableBenefits={renewableBenefits}
								pageView={this.props.pageView}
								config={config}
							/>
						}
					/>
					<Route
						path={Paths.PORTAL__CHANGE_PASSWORD}
						exact={true}
						render={() =>
							<ChangePassword
								blockingActions={blockingActions}
								changePassword={this.handleChangePassword}
								intl={intl}
								history={this.props.history}
								pageView={this.props.pageView}
							/>
						}
					/>
					
					{/* Redirect all non-matching routes */}
					<Redirect to={Paths.PORTAL__PROFILE} />
				</Switch>

			</div>
		);
	}
	
	private handleLoginClick = (evt: React.MouseEvent<HTMLElement>) => {
		evt.preventDefault();
		this.props.showLoginForm();
	}

	private handleChangePassword = (oldPassword: string, newPassword: string, verifyPassword: string) => {
		const {clearAllMessages, changePassword} = this.props;

		// Clear error messages at the start of the submit operation
		clearAllMessages();

		changePassword(oldPassword,newPassword,verifyPassword)
			.then((result) => {
				if (result.type === ActionTypes.API_SUCCESS) {
					this.props.history.push(Paths.PORTAL);
					this.props.showAlert({alertId: "msg_change_password_success", alertSeverity: Severities.SUCCESS});
				}
			});
	}
}	
///
/// Redux Connections
///

const mapStateToProps = (state: RootState): PortalStateToProps  => {
	const {blockingActions, config} = state.ptApp;
	const {donations, orders, pendingRenewal, pendingRenewals, portalOrderCache, renewableBenefits} = state.portal;
	return {
		blockingActions,
		config,
		donations,
		orders,
		pendingRenewal,
		pendingRenewals,
		portalOrderCache,
		renewableBenefits
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>): PortalDispatchToProps => {
	return {
		changePassword: (oldPassword, newPassword, verifyPassword): Promise<any> => {
			return dispatch(ApiActions.changePassword(oldPassword,newPassword,verifyPassword));
		},
		clearAllMessages: () => {
			dispatch(PublicTicketAppActions.clearAllMessages());
		},
		doNotRenew: (ticketOrderId: string): Promise<any> => {
			return dispatch(ApiActions.doNotRenew(ticketOrderId));
		},
		fetchDonationHistory: () => {
			return dispatch(ApiActions.fetchDonationHistory());
		},
		fetchPendingRenewal: (ticketOrderId: string) => {
			return dispatch(ApiActions.fetchPendingRenewal(ticketOrderId));
		},
		fetchPortalOrderDetails: (ticketOrderId: string) => {
			return dispatch(ApiActions.fetchPortalOrderDetails(ticketOrderId));
		},
		fetchPortalOrders: () => {
			return dispatch(ApiActions.fetchPortalOrders());
		},
		fetchRenewableBenefits: () => {
			return dispatch(ApiActions.fetchRenewableBenefits());
		},
		pageView: (title: string, url: string) => {
			dispatch(AnalyticsActions.pageView(title, url));
		},
		showAlert: (alertOptions: AlertOptions): void => {
			dispatch(PublicTicketAppActions.showAlert(alertOptions));
		},
		showModalConfirmation: (body: React.ReactNode, confirmationCallback: () => any, intl: InjectedIntl) => {
			dispatch(PublicTicketAppActions.showModalConfirmation(body, confirmationCallback, intl));
		},
		submitContactRequest: (whatId: string, request: string) => {
			return dispatch(ApiActions.submitContactRequest(whatId, request));
		},
		updateUserProfile: (user: UserProfile) : Promise<ApiAction<OperationResult<UserProfile>>> => {
			return dispatch(ApiActions.updateUserProfile(user));
		},
		showLoginForm: (): void => {
			dispatch(PublicTicketAppActions.showLoginForm());
		},
	};
};

/**
 * Portal component exported with redux injections
 */
export const PortalConnected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Portal));
