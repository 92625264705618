import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {getVenueRoute} from "../helpers/routing";

interface VenueLinkProps {
	venueName: string;
	eventInstanceId: string;
	showDetailsLink: boolean;
}

/**
 * VenueLink - A link to the venue detail screen to be used on event detail screens.
 * 
 * @param venueName Human readable name of the venue, E.g. Alice Busch Mainstage
 * @param eventInstanceId Used to form the link address.
 */
export const VenueLink: React.SFC<VenueLinkProps> = ({venueName, eventInstanceId, showDetailsLink}) => (
	<p className="m-0">
		<span className="mr-1">
			<FormattedMessage id={"lbl_VenueWithName"} values={{venueName}} />
		</span>
		{showDetailsLink && (
			<Link to={getVenueRoute(eventInstanceId)}>
				<small>
					<FormattedMessage id="lbl_SeeDetails" />
				</small>
			</Link>
		)}
	</p>
);