import React from "react";
import {Component, CSSProperties} from "react";
import {InjectedIntl} from "react-intl";
import {InventoryService} from "../../helpers/inventory-service";
import {BasicStringKeyedMap} from "../../models/basic-map";
import {Cart} from "../../models/cart";
import {CartItem} from "../../models/cart-item";
import {AllocationDescriptor} from "../../models/event-descriptor/allocation-descriptor";
import {EventDescriptor} from "../../models/event-descriptor/event-descriptor";
import {GroupDescriptor} from "../../models/event-descriptor/group-descriptor";
import {SeatDescriptor} from "../../models/event-descriptor/seat-descriptor";
import {SectionDescriptor} from "../../models/event-descriptor/section-descriptor";
import {SeatAttributes} from "./seat-attributes";
import "./seat-map.css";
import {Section} from "./section";
import {SectionGroup} from "./section-group";

declare var window: any;

interface SeatMapProps {
	allocMap: BasicStringKeyedMap<AllocationDescriptor>;
	cart: Cart;
	eventDescriptor: EventDescriptor;
	intl: InjectedIntl;
	pendingSeatRequests: BasicStringKeyedMap<string>;
	previewMode?: boolean;	// allows the seat map to be previewed with an overlay so nothing can be clicked
	pyosCartItemMap: BasicStringKeyedMap<CartItem>;
	stagedSeatRequests?: BasicStringKeyedMap<CartItem>;
	sectionOrGroupId: string;
	onSeatClick: (seat: SeatDescriptor) => void;
}

export class SeatMap extends Component<SeatMapProps> {
	public static defaultProps = {
		previewMode: false
	};
	
	// The following functions were extracted from boe.js and refactored to work with ES6/React syntax
	public static getStageLocationBoxStyle(sectionOrGroup: GroupDescriptor | SectionDescriptor) {
		let stageLocBoxStyle: CSSProperties;
		let height: number = 0;
		let width: number = 0;
		if ("height" in sectionOrGroup) {
			height = sectionOrGroup.height;
			width = sectionOrGroup.width;
		} else if ("sbh" in sectionOrGroup) {
			height = sectionOrGroup.sbh;
			width = sectionOrGroup.sbw;
		}
		const stageLoc = sectionOrGroup.stageLoc;
		switch (stageLoc) {
			case "tl":
			case "tr":
			case "bl":
			case "br":
				stageLocBoxStyle = {height: (height+36), width: (width+36)};
				break;
			case "tc":
			case "bc":
				stageLocBoxStyle = {height: (height+36), width};
				break;
			case "ml":
			case "mr":
				stageLocBoxStyle = {height, width: (width+36)};
				break;
			default:
				stageLocBoxStyle = {height, width};
		}
		return stageLocBoxStyle;
	}

	public static getStageLocationImageClass(sectionOrGroup: GroupDescriptor | SectionDescriptor) {
		const stageLocImageClassMap = {
			tl: "top-left",
			tc: "top-center",
			tr: "top-right",
			ml: "mid-left",
			mr: "mid-right",
			bl: "bot-left",
			bc: "bot-center",
			br: "bot-right"
		};
		if (!!sectionOrGroup.stageLoc && (sectionOrGroup.stageLoc in stageLocImageClassMap)) {
			return stageLocImageClassMap[sectionOrGroup.stageLoc];
		}
		return "";
	}

	public static getStageLocationImageStyle(sectionOrGroup: GroupDescriptor | SectionDescriptor) {
		let stageLocImageStyle: CSSProperties;
		let height: number = 0;
		let width: number = 0;
		if ("height" in sectionOrGroup) {
			height = sectionOrGroup.height;
			width = sectionOrGroup.width;
		} else if ("sbh" in sectionOrGroup) {
			height = sectionOrGroup.sbh;
			width = sectionOrGroup.sbw;
		}
		const stageLoc = sectionOrGroup.stageLoc;
		const imageDimensions = {height: 36, width: 36};
		switch (stageLoc) {
			case "tl":
				stageLocImageStyle = {...imageDimensions, left: 0, top: 0};
				break;
			case "tc": {
				const leftVal = Math.round((width / 2) - 18);
				stageLocImageStyle = {...imageDimensions, left: leftVal, top: 0};
				break;
			}
			case "tr":
				stageLocImageStyle = {...imageDimensions, left: width, top: 0};
				break;
			case "ml": {
				const topVal = Math.round((height / 2) - 18);
				stageLocImageStyle = {...imageDimensions, left: 0, top: topVal};
				break;
			}
			case "mr": {
				const topVal = Math.round((height / 2) - 18);
				stageLocImageStyle = {...imageDimensions, left: width, top: topVal};
				break;
			}
			case "bl":
				stageLocImageStyle = {...imageDimensions, left: 0, top: height};
				break;
			case "bc": {
				const leftVal = Math.round((width / 2) - 18);
				stageLocImageStyle = {...imageDimensions, left: leftVal, top: height};
				break;
			}
			case "br":
				stageLocImageStyle = {...imageDimensions, left: width, top: height};
				break;
			default:
				stageLocImageStyle = {};
		}
		return stageLocImageStyle;
	}

	public static getSeatLocationBoxStyle(sectionOrGroup: GroupDescriptor | SectionDescriptor) {
		const stageLocBoxStyleMap = {
			tl: {left: 36, top: 36},
			tc: {left: 0, top: 36},
			tr: {left: 0, top: 36},
			ml: {left: 36, top: 0},
			mr: {left: 0, top: 0},
			bl: {left: 36, top: 0},
			bc: {left: 0, top: 0},
			br: {left: 0, top: 0}
		};
		if (!!sectionOrGroup.stageLoc && (sectionOrGroup.stageLoc in stageLocBoxStyleMap)) {
			return stageLocBoxStyleMap[sectionOrGroup.stageLoc];
		}
		return {};
	}
	
	private seatAttributes: SeatAttributes;
	private seatMapRef: any;
	
	constructor(props: SeatMapProps) {
		super(props);
		this.seatMapRef = React.createRef();
		
		// Generate a hash of style properties that are common to all seats in the seat map
		const {eventDescriptor} = props;
		const {venue} = eventDescriptor;
		this.seatAttributes = new SeatAttributes();
		if (!!venue) {
			this.seatAttributes.seatSize = (venue.seatSize) ? {height: venue.seatSize, width: venue.seatSize} : {};
			this.seatAttributes.seatPendingImage = (venue.seatPendingImage) ? {backgroundImage: `url('${window.PublicTicketApp.sitePrefix}${eventDescriptor.layoutStylesUrl}/${venue.seatPendingImage}')`} : {};
			this.seatAttributes.seatSelectedImage = (venue.seatSelectedImage) ? {backgroundImage: `url('${window.PublicTicketApp.sitePrefix}${eventDescriptor.layoutStylesUrl}/${venue.seatSelectedImage}')`} : {};
			this.seatAttributes.seatUnavailableImage = (venue.seatUnavailableImage) ? {backgroundImage: `url('${window.PublicTicketApp.sitePrefix}${eventDescriptor.layoutStylesUrl}/${venue.seatUnavailableImage}')`} : {};
		}
	}
	
	componentDidMount() {
		// PMGR-10562 Automatically scroll to the center of the seat map
		const seatMap = this.seatMapRef.current;
		if(!!seatMap) {
			seatMap.scrollLeft = (seatMap.scrollWidth - seatMap.clientWidth) / 2;
		}
	}

	public render() {
		const {sectionOrGroupId, eventDescriptor, cart, previewMode, ...other} = this.props;
		let sectionOrGroup: GroupDescriptor | SectionDescriptor | undefined;
		let isSection: boolean = false;
		let sectionOrGroupMap: JSX.Element;
		
		sectionOrGroup = eventDescriptor.groupList.find((sectionGroup: GroupDescriptor): boolean => sectionGroup.id === sectionOrGroupId);
		if (!sectionOrGroup) {
			sectionOrGroup = eventDescriptor.sectionList.find((section: SectionDescriptor): boolean => section.id === sectionOrGroupId);
			isSection = true;
		}
		
		if (!sectionOrGroup) {
			return null;
		}
		
		if (isSection) {
			sectionOrGroupMap = (
				<Section
					section={sectionOrGroup as SectionDescriptor}
					eventDescriptor={eventDescriptor}
					cart={cart}
					previewMode={!!previewMode}
					seatAttributes={this.seatAttributes}
					{...other}
				/>
			);
		} else {
			sectionOrGroupMap = (
				<SectionGroup
					sectionGroup={sectionOrGroup as GroupDescriptor}
					sectionsInGroup={InventoryService.getSectionsByGroupId(eventDescriptor, sectionOrGroup.id)}
					eventDescriptor={eventDescriptor}
					cart={cart}
					previewMode={!!previewMode}
					seatAttributes={this.seatAttributes}
					{...other}
				/>
			);
		}
		
		return (
			<div ref={this.seatMapRef} id="SeatMap" className="bg-light d-flex p-5">
				<div className={`ml-auto mr-auto ${previewMode ? "seat-map-preview" : ""}`}>{sectionOrGroupMap}</div>
			</div>
		);
	}
}

