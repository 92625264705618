import * as React from 'react';
import {SFC} from "react";
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {NavItem} from 'reactstrap';
import {LinkItem, LinkStates} from "../../models/link-item";

interface CheckoutNavItemProps {
	link: LinkItem;
}
/**
 * A single link in the Checkout navigation
 */
export const CheckoutNavItem: SFC<CheckoutNavItemProps> = ({
	link: {linkState, label, path}
}) => {
	const baseLinkClassName = 'small text-uppercase pb-0 pts-checkout-nav-item';
	const currentLinkClassName = `${baseLinkClassName} ${linkState === LinkStates.CURRENT && 'nav-link pts-checkout-nav-item--current'}`;
	const activeLinkClassName = `${baseLinkClassName} ${linkState === LinkStates.ACTIVE && 'nav-link'}`;
	const disabledLinkClassName = `${baseLinkClassName} ${linkState === LinkStates.DISABLED && 'nav-link disabled pts-checkout-nav-item--disabled'}`;
	let itemContent: React.ReactNode;
	switch (linkState) {
		case LinkStates.CURRENT:
			itemContent = (
				<Link
					key={`link-${path}`}
					to={path}
					className={currentLinkClassName}>
					<FormattedMessage id={label}/>
				</Link>
			);
			break;
		case LinkStates.ACTIVE:
			itemContent = (
				<Link
					key={`link-${path}`}
					to={path}
					className={activeLinkClassName}>
					<FormattedMessage id={label}/>
				</Link>
			);
			break;
		default:
			itemContent = (
				<span className={disabledLinkClassName}>
					<FormattedMessage id={label}/>
				</span>
			);
	}
	return (
		<NavItem className="mx-2">
			{itemContent}
		</NavItem>
	);
};

export default CheckoutNavItem;