import {InjectedIntl} from "react-intl";
import {ApplicationMessage} from "../../models/application-message";
import {Cart} from "../../models/cart";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {ApplicationMessageInterceptor} from "./application-message-interceptor";
import {LocalizationReservedParams} from "./localization-reserved-params";

export class ContactUsMessageInterceptor implements ApplicationMessageInterceptor {
	constructor(
		private config: PublicTicketAppConfig,
		private cart: Cart,
		private intl: InjectedIntl,
	) {}
	
	public intercept(applicationMessages: Map<string, ApplicationMessage>): Map<string, ApplicationMessage> {
		return this.setContactUsArgument(this.config, this.cart, this.intl, applicationMessages);
	}
	
	/**
	 * Adds the "contact us" localized text as an application messages argument if it is determined that the application
	 * message requires it. No changes are made if the application messages does not require it.
	 *
	 * @param config
	 * @param cart
	 * @param intl
	 * @param applicationMessages
	 */
	private setContactUsArgument = (
		config: PublicTicketAppConfig,
		cart: Cart,
		intl: InjectedIntl,
		applicationMessages: Map<string, ApplicationMessage>
	): Map<string, ApplicationMessage> => {
		const modifiedApplicationMessages = new Map<string, ApplicationMessage>();
		applicationMessages.forEach((applicationMessage, key) => {
			modifiedApplicationMessages.set(key, applicationMessage);
			if (this.shouldAppendContactUs(applicationMessage, intl)) {
				const applicationMessageWithContactUsArgument = new ApplicationMessage(applicationMessage.severity,
					applicationMessage.msgId,
					{...applicationMessage.msgArgs},
					applicationMessage.msg);
				const message = this.getPleaseContactUsMessage(config, cart, intl);
				// covering both cases (pun intended). Sets standalone contact us message (first letter is upper cased)
				// and a contact us that can be used to continue a sentence ( first letter is lower cased )
				applicationMessageWithContactUsArgument.msgArgs[LocalizationReservedParams.CONTACT_US] = message;
				applicationMessageWithContactUsArgument.msgArgs[LocalizationReservedParams.LOWER_CASED_CONTACT_US] = message.charAt(0).toLowerCase() + message.substr(1);
				modifiedApplicationMessages.set(key, applicationMessageWithContactUsArgument);
			}
		});
		return modifiedApplicationMessages;
	}

	private shouldAppendContactUs = (msg: ApplicationMessage, intl: InjectedIntl) => {
		const msgId = msg.msgId || '';
		const rawLocalizeString = intl.messages[msgId];

		return !!rawLocalizeString
			&& ( rawLocalizeString.includes(LocalizationReservedParams.CONTACT_US)
				|| rawLocalizeString.includes(LocalizationReservedParams.LOWER_CASED_CONTACT_US));
	}

	private getPleaseContactUsMessage = (config: PublicTicketAppConfig, cart: Cart, intl: InjectedIntl): string => {

		// fall back to default if not configured
		const formattedContactName = !!config.errorContactName
			? config.errorContactName
			: intl.formatMessage({id: 'msg_contact_us_default_error_contact_name'});

		// msg_contact_us_at_contact_info
		const formattedContactInfo = !!config.errorContactInfo
			? intl.formatMessage({id: 'msg_contact_us_at_contact_info'}, {contactInfo: config.errorContactInfo})
			: '';

		// get reference as formated string
		const formattedReference = !!cart.name
			? intl.formatMessage({id: 'msg_contact_us_reference_order_name'}, {orderName: cart.name})
			: '';

		return intl.formatMessage({id: 'msg_contact_us_for_assistance'}, {
			contactName: formattedContactName,
			atContactInfo: formattedContactInfo,
			referenceOrderName: formattedReference
		}).replace(/\s+/g, ' ').trim();
	}
}

