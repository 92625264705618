import * as React from "react";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Alert} from "reactstrap";
import {AnyAction, Dispatch} from "redux";
import {PublicTicketAppActions} from "../../actions/public-ticket-app-actions";
import {ContactUsMessageInterceptor} from "../../components/application-messages/contact-us-message-interceptor";
import {setScrollPosition} from "../../helpers/utilities";
import {ApplicationMessage} from "../../models/application-message";
import {Cart} from "../../models/cart";
import {PublicTicketAppConfig} from "../../models/public-ticket-app/public-ticket-app-config";
import {RootState} from "../../reducers";

interface ApplicationMessagesProps extends ApplicationMessagesStateToProps, ApplicationMessagesDispatchToProps, InjectedIntlProps {}

interface ApplicationMessagesStateToProps {
	appMessages: Map<string, ApplicationMessage>;
	cart: Cart;
	config: PublicTicketAppConfig;
}

interface ApplicationMessagesDispatchToProps {
	clearMessage: (messageKey: string) => void;
}

export class ApplicationMessages extends React.Component<ApplicationMessagesProps> {
	
	public componentDidMount() {
		// Scroll to the top to bring the alerts into view
		setScrollPosition(0);
	}
	
	public render() {
		const {appMessages, cart, clearMessage, config, intl} = this.props;
		const interceptedMessages = new ContactUsMessageInterceptor(config, cart, intl).intercept(appMessages);
		const alertArray: JSX.Element[] = [];
		interceptedMessages.forEach((msg, msgKey) => {
			let severityColor: string = 'light';
			switch (msg.severity) {
				case 'SUCCESS': severityColor = 'success'; break;
				case 'INFO': severityColor = 'info'; break;
				case 'WARNING': severityColor = 'warning'; break;
				case 'ERROR': severityColor = 'danger'; break;
			}
			alertArray.push(
				<Alert key={`appMsg-${msgKey}`} color={severityColor} fade={false} toggle={() => clearMessage(msgKey)}>
					{!!msg.msgId ? (
						<div>
							<FormattedMessage id={msg.msgId} values={msg.msgArgs}/>
							{!!msg.msgArgs && 'delimitedString' in msg.msgArgs && (
								<ul>
									{msg.msgArgs.delimitedString.split('|').map((teName: string, index:number) => <li key={index}>{teName}</li>)}
								</ul>
							)}
						</div>
					) : (
						<div>{msg.msg}</div>
					)}
				</Alert>
			);
		});
		return alertArray;
	}
}

const mapStateToProps = (state: RootState): ApplicationMessagesStateToProps => {
	return {
		appMessages: state.ptApp.appMessages,
		cart: state.cart,
		config: state.ptApp.config,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ApplicationMessagesDispatchToProps => {
	return {
		clearMessage: (messageKey: string): void => {
			dispatch(PublicTicketAppActions.clearMessage(messageKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ApplicationMessages));
